import { Pipe, PipeTransform } from "@angular/core";
import { Decision } from "projects/shared-lib/src/public-api";

@Pipe({ name: "filterGuidelinesByCategory" })
export class filterGuidelinesByCategoryPipe implements PipeTransform {
  private filterByCategoryId(decisions: Decision[], categoryId: number) {
    const isValidLength = decisions.length > 0 ? true : false;
    const isValidCategory = categoryId === null ? false : true;
    if (isValidLength && isValidCategory) {
      return decisions.filter((decision) => decision.categoryId === categoryId);
    } else {
      return decisions;
    }
  }

  transform(decisions: Decision[], categoryId: number) {
    return this.filterByCategoryId(decisions, categoryId);
  }
}
