import { Component, OnInit, Inject } from "@angular/core";
import {
  Pathway,
  PathwayLangText,
  LanguageText,
} from "projects/shared-lib/src/public-api";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PathwayLang } from "../../shared/model/pathaway-lang.model";
import { CustomEditorConfig } from "../customEditorConfig";
import { AngularEditorConfig } from "../../../../../angular-editor/src/public-api";
import { ValidationControlUtil } from "../../shared/util/validation.util";
import { DialogComponent } from "../../core/dialog/dialog.component";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-pathway-lang",
  templateUrl: "./pathway-lang.component.html",
  styleUrls: ["./pathway-lang.component.css"],
})
export class PathwayLangComponent implements OnInit {
  public readablePathway: Pathway;
  public editablePathway: PathwayLangText;
  public languageDesc: string;
  public submitted = false;
  config: AngularEditorConfig = new CustomEditorConfig(true, true);

  constructor(
    private dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PathwayLang,
    public validationControlUtil: ValidationControlUtil
  ) {
    this.readablePathway = data.readable;
    this.editablePathway = data.editable;
    this.languageDesc = data.language;
  }

  onCancel() {
    this.dialogRef.close(undefined);
  }

  onSave(form: NgForm) {
    this.submitted = true;
    if (form.invalid) {
      alert(this.validationControlUtil.getErrorMsg(form.controls));
      return;
    } else {
      this.dialogRef.close(this.editablePathway);
    }
  }

  ngOnInit(): void {}
}
