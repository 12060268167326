import { Injectable } from '@angular/core';
import { Node, NodeDimension, NodePosition } from '@swimlane/ngx-graph';
import { QuestionGroup, ScoreToNextObject, Option, Treatment } 
        from "projects/shared-lib/src/public-api";
import { GraphNodeData } from './graphnodedata';

@Injectable({ providedIn: "root" })
export class GraphNode implements Node
{
    id: string;
    position?: NodePosition;
    dimension?: NodeDimension;
    transform?: string;
    label?: string;
    data: GraphNodeData;

    constructor()
    {
        this.data = new GraphNodeData();  
        this.dimension = {
            width: 200,
            height: 200
        }   
    }

    populateGroupNode(g: QuestionGroup){
        let h: number = (g.isStepBased) ? 250 : 200;
        this.id = "Group_" + g.id.toString();
        if(g.questions.length > 0)
            this.label = g.questions[0].text; 
        else
            this.label = "Fist question not found.";      
        this.data.group = g;
        let calcHeigh = h + (g.questions.length - 1) * 50;
        this.dimension.height = calcHeigh > 200 ? calcHeigh : 200 ;
    }

    populateOptionNode(o: Option){
        this.id = "Option_" + o.id.toString();
        this.label = o.text;
        this.data.option = o;
        this.dimension.height = 80;
    }

    populateTreatmentNode(t: Treatment){
        this.id = "Treatment_" + t.id.toString();
        this.label = t.text;
        this.data.treatment = t;
        this.dimension.height = 300;
    }
    
    populateScoreNode(s: ScoreToNextObject)
    {
        this. id = "Score_" + s.id.toString();
        this.label = s.score;
        this.data.score = s;
        this.dimension.height = 80;
    }
}