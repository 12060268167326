import { Component, OnInit } from '@angular/core';
import { RegisterUser } from '../../shared/model/register-user.model';
import { AuthService } from '../../shared/service/auth.service';
import { ToasterService, MessageType, SpinnerService } from 'projects/shared-lib/src/public-api';

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.css']
})
export class RegisterUserComponent implements OnInit {
  public user: RegisterUser = new RegisterUser();
  constructor(
    private service: AuthService,
    private toasterService: ToasterService,
    private spinnerService: SpinnerService
  ) { }

  ngOnInit(): void {
  }

  register(): void{
    this.spinnerService.show();
    this.service.register(this.user).subscribe((result)=>{
      this.spinnerService.hide();
      if(!result.operationSuccess)
        this.toasterService.show(MessageType.SYSTEM_ERROR, result.messages[0].text);
      else
      {
        this.toasterService.show(MessageType.INFORMATIONAL, "User registered Successfully.");
        this.user.firstName = "";
        this.user.lastName = "";
        this.user.userName = "";
        this.user.password = "";
        this.user.confirmPassword = "";
      }
    }
    );
  }
}
