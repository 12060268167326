<div class="text-primary">
  <br />
  <ng-template [ngIf]="isTemplate">
    <label>Treatments</label>
    <br />
  </ng-template>
  <div class="text-secondary">
    <br />
    <ng-template [ngIf]="isTemplate">
      <button
        id="add_new_treatment_template_btn"
        class="btn btn-secondary btn-block"
        type="button"
        (click)="addNewTreatment()"
      >
        Add Treatment
      </button>
    </ng-template>
    <hr />
    <div class="form-group has-search">
      <span class="form-control-feedback" aria-hidden="true">
        <img src="../../../assets/search-24px.svg" />
      </span>
      <input
        class="form-control flat-input no-outline"
        type="text"
        id="search-treatment"
        placeholder="Search Treatment"
        [(ngModel)]="treatmentFilterString"
        (input)="onTreatmentSearch()"
      />
      <br />
    </div>
  </div>
  <div
    *ngIf="filteredTreatments.length === 0"
    class="text-center text-secondary"
  >
    <label>No items found </label>
  </div>
  <div
    #treatmentList="cdkDropList"
    cdkDropList
    [cdkDropListData]="filteredTreatments"
    *ngFor="let treatment of filteredTreatments"
  >
    <div>
      <div
        cdkDrag
        [cdkDragData]="treatment"
        class="treatment bg-primary"
        (cdkDragStarted)="dragStarted($event)"
        (cdkDragEnded)="dragEnded($event)"
      >
      <div class="row ml-1">
        <div cdkDragHandle>
          <img
            class="box-handle"
            src="../../../assets/drag_indicator-24px.svg"
          />
        </div>
        <div class="col-sm-4 col-4"></div>
        <div class="btn-toolbar mt-2" *ngIf="isTemplate">
          <button class="btn btn-light btn-sm ml-2" type="button" (click)="onNewTreatmentAs(treatment)">
            <img id="copyTreatment" class="delete-icon" src="../../../assets/content_copy-black-24dp.svg" />
          </button>
          <button class="btn btn-light btn-sm ml-2" type="button" (click)="editTreatment(getTreatment(treatment))">
            <img id="editTreatment"class="delete-icon" src="../../../assets/edit-24px.svg" />
          </button>
          <button
          class="btn btn-light btn-sm ml-2 delete-icon-right"
          type="button"
          (click)="onDeleteTreatment(treatment)"
        >
          <img id="deleteTreatment" class="delete-icon" src="../../../assets/delete.svg" />
        </button>
        </div>
      </div>
        <div class="treatment-content" >
          <p
            class="questionText"
            [innerHTML]="getTreatment(treatment).text | sanitizer: 'html'"
          ></p>
        </div>
      </div>
      <br />
    </div>
  </div>
</div>
