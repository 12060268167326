<div class="text-left">
  <form (ngSubmit)="onSave(treatmentForm, treatment)" #treatmentForm="ngForm" name="form">
    <br />
    <div class="edit-treatment bg-primary">
      <br />
      <div class="toolbar-container">
        <div *ngIf="!(treatment.id |showLangToolbar)" class="lang-toolbar"></div>
        <app-lang-toolbar
          *ngIf="treatment.id |showLangToolbar"
          (afterLanguageWindowClosed)="saveLanguageInformation($event)"
          [selectedInstance]="treatment"
          [instanceType]="tableType"
          [isLoading]="isLoading"
        >
        </app-lang-toolbar>
        <div class="btns-section">
          <button
            *ngIf="showSaveButton()"
            id="save_new_treatment_btn"
            [disabled]="isLoading"
            class="btn btn-white section-item"
          >
            <span
              id="save_spinner_span"
              *ngIf="isLoading"
              class="spinner-border spinner-border-sm mr-1"
            ></span>
            Save
          </button>
          <button *ngIf="treatment.isSaveAs" id="save_new_treatment_btn" [disabled]="isLoading" class="btn btn-white section-item">
            <span id="save_spinner_span" *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
            Save As
          </button>
          <button
            id="cancel_treatment_btn"
            type="button"
            class="btn btn-primary section-item"
            (click)="onCancel()"
          >
            Cancel
          </button>
        </div>
      </div>
      <div class="editor color-white">
        <div class="row">
          <div class="col-sm-6">
            <label>Treatment Text *</label>
            <br />
          </div>
        </div>
        <div class="color-dark bg-white">
          <angular-editor
            id="new_treatment_text_input"
            [(ngModel)]="treatment.text"
            [config]="config"
            name="new_treatment_text_input"
            required
            maxlength="{{ validationControlUtil.largeTextLength }}"
            (errorMsg)="validationControlUtil.showError($event)"
          ></angular-editor>
        </div>
        <div *ngIf="treatment && treatment?.text" class="col-12 text-right pt-1">
          <label>Characters count: {{ treatment.text?.length }}</label>
        </div>
        <br />
        <div class="row">
          <div class="col-sm-6">
            <label>Tag *</label>
            <br />
            <input
              class="form-control"
              id="new_treatment_tag_input"
              name="new_treatment_tag_input"
              placeholder="Tag"
              [(ngModel)]="treatment.tag"
              #tag="ngModel"
              required
              maxlength="{{ validationControlUtil.tagMaxLength }}"
            />
          </div>
        </div>
        <br />
      </div>
      <br />
    </div>
  </form>
</div>
