<fieldset ngModelGroup="questionLanguagePanel">
<div class="col-12  question">
    <div *ngFor="let question of questionGroup.questions; let q = index">
      <ng-template [ngIf]="question.modelAction != 3">
        <div class="row col-sm-12">
          <div class="col-11  bg-light ">
            <div class="editor color-dark">
              <div class="row">
                <div class="col-sm-12">
                  <br />
                  <div *ngIf="!questionGroup.isStepBased">
                    <label>Question Text *</label>
                  </div>
                  <div *ngIf="questionGroup.isStepBased">
                    <label>Step Text *</label>
                  </div>
                  <br />
                  <div class="color-dark bg-white">
                    <angular-editor [ngClass]="getReadOnlyClass()"
                      id="question_{{ q }}"
                      [(ngModel)]="question.text"
                      [config]="config"
                      name="question_text_ + {{getQuestionGroup()}} {{ q }}"
                      required
                      maxlength="{{ validationControlUtil.largeTextLength }}"
                      (errorMsg)="validationControlUtil.showError($event)"
                    ></angular-editor>
                  </div>
                  <div *ngIf="question && question?.text" class="col-12 text-right pt-1">
                    <label>Characters count: {{ question.text?.length }}</label>
                  </div>
                </div>
              </div>
              <br />
              <div class="row col-10">
                <br />
                <label>Tag *</label>
              </div>
              <div class="row col-12">
                <input
                  class="col-sm-6 form-control"
                  name="question_tag_ + {{getQuestionGroup()}}{{ q }}"
                  placeholder="Tag"
                  [(ngModel)]="question.tag"
                  required readonly
                  maxlength="{{ validationControlUtil.tagMaxLength }}"
                />
              </div>
              <br />
              <div class="col-12  bg-white options">
                <br />
                <div *ngFor="let option of question.options; let i = index">
                  <div class="row col-12">
                    <ng-template [ngIf]="option.modelAction != 3">
                        <input
                            class="form-control"
                            name="question_option_text_q{{getQuestionGroup()}}{{ q }}{{ i }}"
                            placeholder="Option Text"
                            [(ngModel)]="option.text"
                            required [readonly]="readOnly"
                        />
                    </ng-template>
                  </div>
                  <br />
                </div>
                <br />
              </div>
              <br />
            </div>
          </div>
        </div>
      </ng-template>
      <br />
    </div>
  </div>
</fieldset>
