import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { ServiceUtil } from 'projects/AdminApp/src/app/shared/util/service-util';
import { Treatment, InquiryResponse, InquiryRequest } 
      from 'projects/shared-lib/src/public-api';


@Injectable({providedIn: 'root'})
export class TreatmentService{
  constructor(private httpClient: HttpClient,private serviceUtility: ServiceUtil){}

  saveTreatment(treatment: Treatment){
    return this.httpClient.post<any>(this.serviceUtility.rootUrl + '/Treatment/Save',
    treatment,this.serviceUtility.getCommonAdminHeadersWithAuth())
    .pipe(
      catchError(this.serviceUtility.handleError),
      tap(function(){})
    );
  }

  private handleResponse(response: InquiryResponse<Treatment>):Treatment[] {
    if (response.operationSuccess) {
      return response.returnValues;
    }
    return null;
  }

  retrieveTreatments() {
    const data = new InquiryRequest();
    return this.httpClient
    .post<InquiryResponse<Treatment>>(this.serviceUtility.rootUrl + '/Treatment/fetch', data, this.serviceUtility.getCommonAdminHeadersWithAuth())
    .pipe(
      catchError(this.serviceUtility.handleError),
      tap(this.handleResponse)
    );
  }

  retrieveTreatmentById(id: number) 
   {
      return this.httpClient
    .post<InquiryResponse<Treatment>>(this.serviceUtility.rootUrl + '/Treatment/FetchById?id=' + id, 
      null,this.serviceUtility.getCommonAdminHeadersWithAuth())
    .pipe(
      catchError(this.serviceUtility.handleError),
      tap(this.handleResponse)
    );
  }
}
