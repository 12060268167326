import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { ServiceUtil } from "projects/AdminApp/src/app/shared/util/service-util";
import {
  InquiryRequest,
  InquiryResponse,
  MessageType,
  ToasterService,
  UserAppText, Language, SpinnerService
} from "projects/shared-lib/src/public-api";

@Injectable({ providedIn: "root" })
export class UserAppTextService {
  private userAppTextUrl = `${this.serviceUtil.rootUrl}/userAppText`;
  public userAppTextArray: UserAppText[] = [];
  
  constructor(
    private serviceUtil: ServiceUtil,
    private http: HttpClient,
    private toasterService: ToasterService,
    private spinnerService: SpinnerService
  ) {
    this.serviceUtil = serviceUtil;
    this.http = http;
    this.onError = this.onError.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
  }

  onSuccess(response: InquiryResponse<any>)
  {
    this.spinnerService.hide();
    if (response.operationSuccess) {
      this.userAppTextArray = [...response.returnValues];
    }
  }

  private onError(error) {
    this.spinnerService.hide();
    this.toasterService.show(MessageType.SYSTEM_ERROR, error);
  }

  getUserAppText()
  {
    if(this.userAppTextArray.length === 0)
    {
      this.spinnerService.show();
      let request = new InquiryRequest();
      const url = `${this.userAppTextUrl}/fetch`;
      this.http
      .post<InquiryResponse<Language>>(
        url,
        request,
        this.serviceUtil.getCommonHeaders()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.serviceUtil.handleResponse)
      ).subscribe(this.onSuccess,this.onError);
    }
  }
}
