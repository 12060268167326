<form (ngSubmit)="isValid(form)" #form="ngForm" name="form">
<div class="lang-container">
    <div class="lang-header color-black">English(Read Only)</div>
    <div class="lang-header color-black">{{ data.language }}</div>
    <div class="lang-section text-left bg-primary">
      <mat-dialog-actions class="pt10">
        <div class="col-12">
          <div class="btn-toolbar justify-content-between" role="toolbar">
            <div class="btn-group" role="group"></div>
            <div class="btn-toolbar" role="group">
            </div>
          </div>
          <br />
        </div>
      </mat-dialog-actions>
      <div class="editor color-white">
        <app-group-lang-panel [questionGroup]="data.readable"
        [readOnly] = "true">
        </app-group-lang-panel></div>
    </div>
    <div class="lang-section text-left bg-primary">
      <mat-dialog-actions>
        <div class="col-12">
          <div class="btn-toolbar justify-content-between" role="toolbar">
            <div class="btn-group" role="group"></div>
            <div class="btn-toolbar" role="group">
              <button
                type="button"
                class="btn btn-primary"
                [mat-dialog-close]="undefined"
              >
                Cancel
              </button>
              <button
                *ngIf="data.readable.isSaveAs"
                class="btn btn-white"
                cdkFocusInitial
              >
                Save As
              </button>
              <button *ngIf="!data.readable.isSaveAs" id = "saveBtn" class="btn btn-white" cdkFocusInitial>
                Save
              </button>
            </div>
          </div>
          <br />
        </div>
      </mat-dialog-actions>
      <div class="editor color-white">
      <app-group-lang-panel [questionGroup]="data.editable"
      [readOnly] = "false" >
      </app-group-lang-panel>
    </div>

    </div>
  </div>
</form>
