import {
  Pathway,
  PathwayLangText,
} from "../../../../../shared-lib/src/public-api";

export class PathwayLang {
  constructor(
    public language: string,
    public readable: Pathway,
    public editable: PathwayLangText
  ) {}
}
