import { Component, OnInit, Inject, Input } from '@angular/core';import { FormGroup, NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MultipleLanguageGroups } from 'projects/AdminApp/src/app/shared/model/multipleLanguageGroups';
import { ToasterService, MessageType } from 'projects/shared-lib/src/public-api';
import { ValidationControlUtil } from '../../shared/util/validation.util';

@Component({
  selector: 'app-group-lang',
  templateUrl: './group-lang.component.html',
  styleUrls: ['./group-lang.component.css']
})
export class GroupLangComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<GroupLangComponent>,
    private validationUtility: ValidationControlUtil,
    @Inject(MAT_DIALOG_DATA) public data: MultipleLanguageGroups
  ) {}

  ngOnInit(): void {}

  isValid(form: NgForm) {
    if (!form.valid) {
      const qsForm = form.controls.questionLanguagePanel as FormGroup;
      alert(this.validationUtility.getErrorMsg(qsForm.controls));
      return;
    }
    this.dialogRef.close(this.data.editable);
  }
}
