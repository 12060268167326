import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Subscription, Observable } from "rxjs";
import { AuthService } from "./shared/service/auth.service";
import { AccountService } from "projects/AdminApp/src/app/shared/service/account.service";
import { environment } from "../environments/environment";
import { CategoryService } from "./shared/service/category.service";
import {
  InquiryRequest,
  ToasterService,
  InquiryResponse, Category, MessageType
} from "projects/shared-lib/src/public-api";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, OnDestroy {
  public isAuthenticated = false;
  public isOpenSidebar = false;
  public username = "";
  private accountSub: Subscription;
  public userAppUrl: string;
  public guidelinesUserAppUrl: string;
  public surveyUserAppUrl: string;
  public categories: Category[] = [];

  constructor(
    private accountService: AccountService,
    private authService: AuthService,
    private categoryService: CategoryService,
    private toasterService: ToasterService,
    private cdRef: ChangeDetectorRef
  ) {
    this.setInitValues = this.setInitValues.bind(this);
    this.onCategoriesSuccess = this.onCategoriesSuccess.bind(this);
    this.getCategoriesObs = this.getCategoriesObs.bind(this);
    this.userAppUrl = environment.userAppUrl;
    if (!this.userAppUrl.endsWith("/")) 
      this.userAppUrl += "/";
    this.guidelinesUserAppUrl =
      this.userAppUrl + "edu-family/guidelines?loggedIn=1";
    this.surveyUserAppUrl = this.userAppUrl + "survey";
  }

  ngOnInit() {
    this.accountSub = this.accountService
      .getAccount()
      .subscribe(this.setInitValues);
    this.authService.autoLogin();
    this.getCategoriesObs().subscribe(this.onCategoriesSuccess, (err) =>
      this.toasterService.show(MessageType.SYSTEM_ERROR, err)
    );
  }

  setInitValues(account) {
    this.isAuthenticated = !!account;
    this.username = account && account.username ? account.username : "";
  }

  onLogout() {
    this.authService.logout();
  }

  ngOnDestroy() {
    this.accountSub.unsubscribe();
  }

  private getCategoriesObs(): Observable<InquiryResponse<Category>> {
    let categoryObs: Observable<InquiryResponse<Category>>;
    categoryObs = this.categoryService.getCategories(new InquiryRequest());
    return categoryObs;
  }

  private onCategoriesSuccess(response: InquiryResponse<Category>) {
    if (response.operationSuccess) {
      this.categories = [...response.returnValues];
    } else if (response.messages.length > 0) {
      this.toasterService.show(
        response.messages[0].type,
        response.messages[0].text
      );
    }
  }
}
