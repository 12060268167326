import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { Category, LanguageText, PersistenceAction, QuestionOption, SurveyQuestion } from "projects/shared-lib/src/public-api";
import { LoginRequest } from "../model/login-request.model";
import { LanguageTextControlUtil } from "./language-text-control.util";

@Injectable({ providedIn: "root" })
export class CategoryUtil {
  constructor(private languageTextControlUtility: LanguageTextControlUtil) {}

  getCategoryLanguageText(
    selectedInstance: Category,
    langId: number
  ): Category {
    let questionGroup = this.copyQuestionGroup(selectedInstance);
    for(let i = 0; i < selectedInstance.surveyQuestions.length; i++)
    {
      let rQuestion = selectedInstance.surveyQuestions[i];
      let eQuestion = questionGroup.surveyQuestions[i];
      eQuestion.id = rQuestion.id;
      let questionText = this.languageTextControlUtility.findTranslation(langId,rQuestion.id,rQuestion.textList);
      this.populateQuestionGroupText(questionText,eQuestion);
      for(let j = 0; j < rQuestion.questionOptions.length; j++)
      {
        let rOption = rQuestion.questionOptions[j];
        let eOption = eQuestion.questionOptions[j];
        eOption.id = rOption.id;
        let optionText = this.languageTextControlUtility.findTranslation(langId,rOption.id,rOption.textList);
        this.populateQuestionGroupText(optionText,eOption);
      }
    }
    return questionGroup;
  }

  populateQuestionGroupText(text: LanguageText, q: SurveyQuestion | QuestionOption)
  {
    if(text != undefined && text != null)
    {
      q.description = text.value;
    }
    else
    {
      q.description = "";
    }
  }

  copyQuestionGroup(input: Category): Category
  {
    let category = this.createEmptyCategory();
    this.copyCategoryData(input,category);
    this.copyQuestions(input, category);
    return category;
  }

  private copyCategoryData(source: Category, destination: Category)
  {
    destination.createdDate = source.createdDate;
    destination.createdBy = source.createdBy;
    destination.updatedBy = source.updatedBy;
    destination.updatedDate = source.updatedDate;
    destination.displayOrder = source.displayOrder;
    destination.modelAction = source.modelAction;
    destination.rowStatus = source.rowStatus;
    destination.description = source.description;
    destination.preferredInstitution = source.preferredInstitution;
  }

  public createEmptyCategory(): Category{
    let questionGroup = new Category();
    questionGroup.id = 0;
    questionGroup.modelAction = PersistenceAction.INSERT;
    questionGroup.rowStatus = true;
    questionGroup.displayOrder = 0;
    questionGroup.surveyQuestions = new Array();
    return questionGroup;
  }

  private copyQuestions(source: Category, destination: Category)
  {
    source.surveyQuestions.forEach(question=>{
      let newQuestion = this.createEmptyQuestion(destination);
      this.copyQuestionData(question,newQuestion,true);
      question.questionOptions.forEach(option=>{
        let newOption = this.addOption(newQuestion);
        newOption.id = 0;
        this.copyOptionData(option,newOption,true);
      });
    });
  }


  private copyOptionData(source: QuestionOption, destination: QuestionOption,copyAudit: boolean)
  {
    destination.tag = source.tag;
    destination.description = source.description;
    destination.rowStatus = source.rowStatus;
    destination.id = source.id;
    destination.isTableOption = source.isTableOption;
    if(copyAudit)
    {
      destination.createdDate = source.createdDate;
      destination.createdBy = source.createdBy;
      destination.updatedBy = source.updatedBy;
      destination.updatedDate = source.updatedDate;
    }
    destination.displayOrder = +source.displayOrder;
    this.languageTextControlUtility.copyLanguageText(source.textList,destination.textList);
  }

  addOption(question: SurveyQuestion){
    let o = new QuestionOption();
    o.id = 0;
    o.modelAction = PersistenceAction.INSERT;
    o.displayOrder = 0;
    o.tag = '';
    o.description = '';
    o.isTableOption = false;
    o.textList = new Array();
    question.questionOptions.push(o);
    return o;
  }

  public createEmptyQuestion(category: Category): SurveyQuestion
  {
    let question = new SurveyQuestion();
    question.modelAction = PersistenceAction.INSERT;
    question.id = 0;
    question.rowStatus = true;
    question.displayOrder = 0;
    category.surveyQuestions.push(question);
    question.textList = new Array();
    question.questionOptions = new Array();
    return question;
  }

  private copyQuestionData(source: SurveyQuestion, destination: SurveyQuestion,copyAudit: boolean)
  {
    destination.tag = source.tag;
    destination.description = source.description;
    destination.rowStatus = source.rowStatus;
    destination.questionCtrlTypeId = source.questionCtrlTypeId;
    if(copyAudit)
    {
      destination.createdDate = source.createdDate;
      destination.createdBy = source.createdBy;
      destination.updatedBy = source.updatedBy;
      destination.updatedDate = source.updatedDate;
    }
    destination.displayOrder = source.displayOrder;
    this.languageTextControlUtility.copyLanguageText(source.textList,destination.textList);

  }

}

