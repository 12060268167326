import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  TreatmentLang,
  Treatment,
  LanguageText,
} from "projects/shared-lib/src/public-api";
import { ValidationControlUtil } from "projects/AdminApp/src/app/shared/util/validation.util";
import { AngularEditorConfig } from "../../../../../angular-editor/src/public-api";
import { CustomEditorConfig } from "../customEditorConfig";
import { DialogComponent } from "../../core/dialog/dialog.component";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-treatment-lang",
  templateUrl: "./treatment-lang.component.html",
  styleUrls: ["./treatment-lang.component.css"],
})
export class TreatmentLangComponent implements OnInit {
  public readableTreatment: Treatment;
  public editableTreatment: LanguageText;
  public languageDesc: string;
  public submitted = false;
  config: AngularEditorConfig = new CustomEditorConfig(true, true);

  constructor(
    private dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TreatmentLang,
    public validationControlUtil: ValidationControlUtil  ) {
    this.readableTreatment = data.readable;
    this.editableTreatment = data.editable;
    this.languageDesc = data.language;
  }

  ngOnInit() {}

  onCancel() {
    this.dialogRef.close(undefined);
  }

  onSave(form: NgForm) {
    this.submitted = true;
    if (!form.valid) {
      alert(this.validationControlUtil.getErrorMsg(form.controls));
      return;
    }
    this.dialogRef.close(this.editableTreatment);
  }
}
