import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PanelComponent } from "./admin/panel/panel.component";
import { AuthGuard } from "./shared/guard/auth.guard";
import { PathwayTreePanelComponent } from "./admin/panel/pathway-tree-panel/pathway-tree-panel.component";
import { LanguageComponent } from "./admin/language/language.component";
import { NotificationsComponent } from "./admin/notifications/notifications.component";
import { DownloadStudentsComponent } from './utilities/export-data/export-data.component';
import { UserImportComponent } from "./admin/user-import/user-import.component";
import { RegisterUserComponent } from "./admin/register-user/register-user.component";

const appRoutes: Routes = [
  {
    path: "",
    redirectTo: "admin/guidelines",
    pathMatch: "full",
  },
  {
    path: "admin/question-tree",
    component: PathwayTreePanelComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "admin/templates",
    component: PanelComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "admin/users-import",
    component: UserImportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "admin/institution",
    loadChildren: () => import("./admin/institution/institution.module").then(
      (m) => m.InstitutionModule
    ),
    canActivate: [AuthGuard]
  },
  {
    path: "admin/language",
    component: LanguageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "admin/user-types",
    loadChildren: () => import("./admin/user-types/user-types.module").then(
      (m) => m.UserTypesModule
    ),
    canActivate: [AuthGuard]
  },
  {
    path: "admin/guidelines",
    loadChildren: () =>
      import("./admin/guidelines/guidelines.module").then(
        (m) => m.GuidelinesModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "admin/notifications",
    component: NotificationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "utilities/export",
    component: DownloadStudentsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "utilities/import",
    loadChildren: () => import("./utilities/import-data/import-data.module").then(
      (m) => m.ImportDataModule
    ),
    canActivate: [AuthGuard]
  },
  {
    path: "admin/facilities",
    loadChildren: () =>
      import("./admin/facility/facility.module").then(
        (m) => m.FacilityModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "utilities/register-users",
    component: RegisterUserComponent,
    canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
