import { Component, OnInit, Inject, Input } from '@angular/core';import { FormGroup, NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CategoryLanguageGroups } from '../../../shared/model/category-lang.model';
import { ValidationControlUtil } from '../../../shared/util/validation.util';

@Component({
  selector: 'user-types-lang',
  templateUrl: './user-types-lang.component.html',
  styleUrls: ['./user-types-lang.component.css']
})
export class UserTypesLangComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<UserTypesLangComponent>,
    private validationUtility: ValidationControlUtil,
    @Inject(MAT_DIALOG_DATA) public data: CategoryLanguageGroups
  ) {}

  ngOnInit(): void {}

  isValid(form: NgForm) {
    if (!form.valid) {
      const qsForm = form.controls.questionLanguagePanel as FormGroup;
      alert(this.validationUtility.getErrorMsg(qsForm.controls));
      return;
    }
    this.dialogRef.close(this.data.editable);
  }
}
