import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[numberOnly]"
})
export class NumberOnlyDirective {
  private navigationKeys = [
    "Backspace",
    "Clear",
    "Tab",
    "Delete",
    "Escape",
    "Enter",
    "End",
    "Home",
    "ArrowLeft",
    "ArrowRight"
  ];

  public inputElement: HTMLInputElement;

  constructor(public elRef: ElementRef) {
    this.inputElement = elRef.nativeElement;
  }

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    const navKeyIndex = this.navigationKeys.indexOf(event.key);
    const isBlankSpace = event.key === " " ? true : false;
    const value = Number(event.key);
    if (navKeyIndex > -1) {
      return;
    }

    if (isBlankSpace || isNaN(value)) {
      event.preventDefault();
    }
  }

  @HostListener("keyup", ["$event"])
  onKeyUp(event: KeyboardEvent) {
    return;
  }
}
