import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { LoginRequest } from "../model/login-request.model";

@Injectable({ providedIn: "root" })
export class AuthControlUtil {
  constructor() {}

  toLoginFormGroup() {
    return {
      username: [
        "",
        [Validators.required, Validators.minLength(4), Validators.email]
      ],
      password: ["", [Validators.required, Validators.minLength(6)]]
    };
  }
 toLoginRequest(username: string, password: string) {
    return new LoginRequest(username.trim(), password.trim());
  }
}

