import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "filterByDescription" })
export class FilterByDescriptionPipe implements PipeTransform {
  private containValue(obj: any, key: string, value: string): boolean {
    if (obj[key].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
      return true;
    }
    return false;
  }

  private filterByProps(firstKey: string, value: string) {
    return element => {
      return (
        this.containValue(element, firstKey, value)
      );
    };
  }

  private filterByDescription(elements: any[], value: string) {
    const nameKey = "description";
    if (value == undefined || value === null || value.length === 0) {
      return [...elements];
    } else {
      return elements.filter(this.filterByProps(nameKey, value));
    }
  }

  transform(elements: any[], value: string) {
    if(value != undefined && value != null)
        value = value.toLowerCase();
    
    return this.filterByDescription(elements, value);
  }
}