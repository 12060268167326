import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ValidationControlUtil } from '../../../shared/util/validation.util';
import { LanguageService } from '../../../shared/service/language.service';
import { ToasterService , Language, MessageType, PersistenceAction} from 'projects/shared-lib/src/public-api';

@Component({
  selector: 'app-language-edit',
  templateUrl: './language-edit.component.html',
  styleUrls: ['./language-edit.component.css']
})
export class LanguageEditComponent implements OnInit {
  @Input() currentItem: Language = null;
  @Output() onWindowClose = new EventEmitter();
  textObjectPrefix: string = "language_text_";

  constructor(public validationControlUtil: ValidationControlUtil,
              private languageService: LanguageService,
              private toasterService: ToasterService) { }

  ngOnInit(): void {
  }

  onSave(form: NgForm)
  {
    if(!form.dirty)
    {
      this.toasterService.show(MessageType.INFORMATIONAL,"No changes detected");
      return;
    }
    if(!form.valid)
    {
      let errors = this.validationControlUtil.getErrorMsg(form.controls);
      this.toasterService.show(MessageType.VALIDATION,errors);
      return;
    }
    if(this.currentItem.modelAction == PersistenceAction.NONE)
      this.currentItem.modelAction = PersistenceAction.UPDATE;
    this.currentItem.userAppTextItems.forEach(item=>{
      if(form.controls[this.textObjectPrefix+item.id+item.userAppTextId].dirty)
        if(item.modelAction == PersistenceAction.NONE)
          item.modelAction = PersistenceAction.UPDATE;
        if(item.id <= 0) {
          item.modelAction = PersistenceAction.INSERT;
        }
    });
    this.languageService.saveLanguageItem(this.currentItem);
    this.onWindowClose.emit();
  }

  onCancel()
  {
    this.onWindowClose.emit();
  }
}
