import { Injectable } from "@angular/core";
import { Treatment, PersistenceAction, LanguageText } from "projects/shared-lib/src/public-api";
import { LanguageTextControlUtil } from './language-text-control.util';

@Injectable({ providedIn: "root" })
export class TreatmentUtil {
  nextId: number = -1;

  constructor(private languageUtility: LanguageTextControlUtil)
  {

  }
  createTreatment(): Treatment {
    let treatment = new Treatment();
    treatment.id = this.nextId--;
    treatment.modelAction = PersistenceAction.INSERT;
    treatment.rowStatus = true;
    treatment.textList = new Array();
    return treatment;
  }

  copyTreatmentData(source: Treatment, destination: Treatment) {
    destination.tag = source.tag;
    destination.text = source.text;
    this.languageUtility.copyLanguageText(source.textList,destination.textList);
  }

  copyTreatment(source: Treatment): Treatment {
    let treatment = this.createTreatment();
    this.copyTreatmentData(source, treatment);
    if(source.templateTreatmentId != 0)
      treatment.templateTreatmentId = source.templateTreatmentId;
    else
      treatment.templateTreatmentId = source.id;
    return treatment;
  }

  treatmentDeepCopy(source: Treatment, destination: Treatment) {
    destination.id = source.id;
    destination.modelAction = source.modelAction;
    destination.parentPathwayId = source.parentPathwayId;
    destination.rowStatus = source.rowStatus;
    destination.tag = source.tag;
    destination.templateTreatmentId = source.templateTreatmentId;
    destination.text = source.text;
    destination.textList = new Array();
    this.languageUtility.copyLanguageText(source.textList,destination.textList);
  }

  getDropdownText(treatment: Treatment): string {
    return treatment.text.replace(/<[^>]*>/g, "");
  }

  editTreatment(treatment: Treatment): Treatment {
    const updateTreatment = { ...treatment };
    updateTreatment.modelAction = PersistenceAction.UPDATE;
    return updateTreatment;
  }

  setTreatmentForInsert(treatment: Treatment)
  {
    treatment.modelAction = PersistenceAction.INSERT;
    treatment.textList.forEach(t=>{
      t.modelAction = PersistenceAction.INSERT;
      t.id = this.nextId--;
    });
  }

  newTreatmentAs(treatment: Treatment): Treatment {
    const newTreatmentAs = { ...treatment };
    newTreatmentAs.isSaveAs = true;
    return newTreatmentAs;
  }

  setForSaveAs(treatment: Treatment): Treatment {
    const saveTreatmentAs = { ...treatment };
    saveTreatmentAs.id = this.nextId--;
    saveTreatmentAs.modelAction = PersistenceAction.INSERT;
    saveTreatmentAs.createdBy = "Anonymous";
    saveTreatmentAs.createdDate = new Date();
    saveTreatmentAs.updatedBy = "Anonymous";
    saveTreatmentAs.updatedDate = new Date();
    saveTreatmentAs.textList.forEach(t => {
      t.id = this.nextId--;
      t.tableId = this.nextId--;
      t.modelAction = PersistenceAction.INSERT;
      t.createdBy = "Anonymous";
      t.createdDate = new Date();
      t.updatedBy = "Anonymous";
      t.updatedDate = new Date();
    });
    return saveTreatmentAs;
  }
}
