export class globalLengthLimit
{
    public static tagLength = 64;
    public static textLength = 2048;
    public static additionalInfoTextLength=8000;
    public static largeTextLength = 32000;
    public static scoreLength = 32;
    public static nameLength = 32;
    public static pathwayName = 64;
    public static institutionDescriptionLength = 120;
    public static languageCodeLength = 2;
    public static languageDescriptionLength = 256;
    public static languageAppTextLength = 128;
}
