import { AngularEditorConfig } from "../../../../angular-editor/src/public-api";
import { Font } from "../../../../angular-editor/src/lib/config";
import { environment } from "../../environments/environment";

export class CustomEditorConfig implements AngularEditorConfig {
  editable?: boolean;
  spellcheck?: boolean;
  height?: "auto" | string;
  minHeight?: "0" | string;
  maxHeight?: "auto" | string;
  width?: "auto" | string;
  minWidth?: "0" | string;
  translate?: "yes" | "now" | string;
  enableToolbar?: boolean;
  showToolbar?: boolean;
  placeholder?: string;
  defaultParagraphSeparator?: string;
  defaultFontName?: string;
  defaultFontSize?:
    | "1"
    | "2"
    | "3"
    | "4"
    | "5"
    | "6"
    | "7"
    | "8"
    | "9"
    | "10"
    | string;
  uploadUrl?: string;
  searchImageByNameUrl?: string;
  uploadWithCredentials: false;
  fonts?: Font[];
  toolbarHiddenButtons?: string[][];
  toolbarPosition?: "top" | "bottom";
  outline?: boolean;
  sanitize: boolean;

  constructor(
    showToolbar: boolean,
    allowImage: boolean,
    height: string = "auto",
    minHeight: string = "0"
  ) {
    this.showToolbar = showToolbar;
    if (!showToolbar) {
      this.editable = false;
      this.enableToolbar = false;
    } else {
      this.editable = true;
      this.enableToolbar = true;
    }
    this.sanitize = false;
    this.spellcheck = true;
    //this.height = height;
    this.minHeight = minHeight;
    this.translate = "no";
    this.defaultFontName = "Arial";
    this.uploadUrl = `${environment.baseUrl}/Image/Upload`;
    this.searchImageByNameUrl = `${environment.baseUrl}/Image/SearchImageByName`;
    if (allowImage) {
      this.toolbarHiddenButtons = [
        [],
        ["heading", "color"],
        ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
        [
          "paragraph",
          "blockquote",
          "removeBlockquote",
          "horizontalLine",
          "orderedList",
          "unorderedList"
        ],
        ["link", "unlink", "insertVideo"]
      ];
    } else {
      this.toolbarHiddenButtons = [
        [],
        ["heading", "color"],
        ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
        [
          "paragraph",
          "blockquote",
          "removeBlockquote",
          "horizontalLine",
          "orderedList",
          "unorderedList"
        ],
        ["link", "unlink", "insertImage", "insertVideo"]
      ];
    }
  }

  setReadOnly() {
    this.editable = false;
  }
}
