import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatTabGroup } from "@angular/material/tabs";
import { NgxMaterialTimepickerComponent, NgxTimepickerFieldComponent } from "ngx-material-timepicker";
import { Observable, Subscription } from "rxjs";
import { finalize, last } from "rxjs/operators";
import { Language, PersistenceAction } from "shared-lib";
import {
  Category,
  InquiryRequest,
  InquiryResponse,
  Institution,
  MessageType,
  ServiceConstant,
  SpinnerService,
  ToasterService,
} from "../../../../../shared-lib/src/public-api";
import { CategoryService } from "../../shared/service/category.service";
import { InstitutionService } from "../../shared/service/institution.service";
import { LanguageService } from "../../shared/service/language.service";
import { NotifSubscriptionService } from "../../shared/service/notif-subscription.service";
import { ScheduledNotificationService } from "../../shared/service/scheduled-notification.service";
import { NotificationsDeleteUtility } from './notifications.delete.utility';

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.css"],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public institutions: Institution[] = [];
  public categories: Category[] = [];
  public scheduledNot = [];
  public currentTabIndex = 0;
  public currentNotification = null;
  public isLoading = false;
  public isSubmitted = false;
  public isScheduled = false;
  public isCreating = true;
  public notificationForm: FormGroup = null;
  public messageLangs: FormArray = null;
  public scheduledTypeControl: FormControl = null;
  public dateControl: FormControl = null;
  public timeValue = "12:00";
  public daysForm: FormGroup = null;
  public monControl: FormControl = null;
  public tueControl: FormControl = null;
  public wedControl: FormControl = null;
  public thuControl: FormControl = null;
  public friControl: FormControl = null;
  public satControl: FormControl = null;
  public sunControl: FormControl = null;

  public daysControl: FormControl = null;

  timepicker: NgxTimepickerFieldComponent;
  @ViewChild("timepicker") set content(content: NgxTimepickerFieldComponent) {
    if(content) {
      var lastTime = (this.timepicker) ? this.timepicker.timepickerTime : null;
        this.timepicker = content;
        if(this.currentNotification) {
          this.timepicker.writeValue(this.getHour());
        } else {
          this.timepicker.writeValue(lastTime);
        }
    }
  }

  
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;


  constructor(
    private toasterService: ToasterService,
    private spinnerService: SpinnerService,
    private institutionService: InstitutionService,
    private categoryService: CategoryService,
    private scheduledNotService: ScheduledNotificationService,
    private notifSubscriptionService: NotifSubscriptionService,
    private notificationDeleteUtility: NotificationsDeleteUtility,
    public languageService: LanguageService
  ) {
    this.onSendNotificationSuccess = this.onSendNotificationSuccess.bind(this);
    this.onInstitutionsSuccess = this.onInstitutionsSuccess.bind(this);
    this.onCategoriesSuccess = this.onCategoriesSuccess.bind(this);
    this.setFormGroup = this.setFormGroup.bind(this);
    this.setDefaultOptions();
    this.setFormGroup(0, 0, "");
  }

  private onInstitutionsSuccess(response: InquiryResponse<Institution>) {
    if (response.operationSuccess) {
      this.institutions.push(...response.returnValues);
    } else if (response.messages.length > 0) {
      this.toasterService.show(
        response.messages[0].type,
        response.messages[0].text
      );
    } else {
      this.onError(ServiceConstant.somethingWentWrongMessage);
    }
  }

  private onCategoriesSuccess(response: InquiryResponse<Category>) {
    if (response.operationSuccess) {
      this.categories.push(...response.returnValues);
    } else if (response.messages.length > 0) {
      this.toasterService.show(
        response.messages[0].type,
        response.messages[0].text
      );
    } else {
      this.onError(ServiceConstant.somethingWentWrongMessage);
    }
  }

  private getHour() {
    var values = this.currentNotification.value.split(',');
    var hour = values[values.length - 1];
    return this.toLocalTime(hour);
  }

  private onSendNotificationSuccess(response: InquiryResponse<any>) {
    this.isLoading = false;
    this.isSubmitted = false;
    const infoMsg = "The notification was sent successfully";
    this.setFormGroup(0, 0, "");
    if (response.operationSuccess) {
      this.toasterService.show(MessageType.INFORMATIONAL, infoMsg);
    } else if (response.messages.length > 0) {
      this.toasterService.show(
        response.messages[0].type,
        response.messages[0].text
      );
    } else {
      this.onError(ServiceConstant.somethingWentWrongMessage);
    }
  }

  private onError(error: string) {
    this.isLoading = false;
    this.toasterService.show(MessageType.SYSTEM_ERROR, error);
  }

  loadLanguages()
  {
    this.languageService.languages.length = 0;
    this.languageService.initLanguages();
  }

  toUtcHour(time) {
    const date = new Date();
    
    var hourMins = time.split(':');

    date.setHours(parseInt(hourMins[0]));
    date.setMinutes(parseInt(hourMins[1]));

    return date.getUTCHours() + ":" + date.getUTCMinutes();
  }

  toLocalTime(time) {
    const date = new Date();
    
    var hourMins = time.split(':');

    date.setHours(parseInt(hourMins[0]));
    date.setMinutes(parseInt(hourMins[1]));
    var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate.getHours() + ":" + newDate.getMinutes();
  }

  deleteNotification(notification) {
    let dialogReg = this.notificationDeleteUtility.canDeleteNotification();
    dialogReg.afterClosed().subscribe((result) => {
     if (result) {
      this.spinnerService.show();
      const _notification = {
        ...notification,
        rowStatus: false,
        modelAction: PersistenceAction.DELETE,
      };

      this.subscriptions.push(
        this.scheduledNotService
          .save(_notification)
          .subscribe(response => {
            this.spinnerService.hide();

            if (response.operationSuccess) {
              var infoMsg = "Notification removed successfully.";
              this.toasterService.show(MessageType.INFORMATIONAL, infoMsg);
              this.getData();
            } else if (response.messages.length > 0) {
              this.toasterService.show(
                response.messages[0].type,
                response.messages[0].text
              );
            } else {
              this.onError(ServiceConstant.somethingWentWrongMessage);
            }
          }, this.onError)
      )
     }
    });
  }

  editNotification(notification){
    this.currentNotification = notification;
    this.isScheduled = true;
    this.isCreating = false;
    this.currentTabIndex = 0;
    this.resetForms();
    this.assignLanguages(notification);
    if(this.notificationForm.controls['message']) this.notificationForm.controls['message'].setValue(notification.message);
    var values = this.currentNotification.value.split(',');
    if(this.timepicker && this.currentNotification)  this.timepicker.writeValue(this.getHour());

    if(this.currentNotification.valueDataType == 'date') {
      this.notificationForm.controls["scheduledTypeControl"];
      this.scheduledTypeControl.setValue("2");
      var date = new Date(values[0]);
      this.dateControl.setValue(date);

    } else if(this.currentNotification.valueDataType == 'days-based') {
      this.scheduledTypeControl.setValue("3");
      this.daysControl.setValue(parseInt(values[0]));
    } else {
      this.scheduledTypeControl.setValue("1");
      this.setDays(values);
      
    }
    
    if(this.notificationForm.controls['institutionId']) this.notificationForm.controls['institutionId'].setValue(notification.institutionId);
    if(this.notificationForm.controls['categoryId']) this.notificationForm.controls['categoryId'].setValue(notification.userTypeId);
  }

  assignLanguages(notification = null) {
    this.languageService.languages.forEach(l => {
      var langText = (notification) ? notification.textList.find(t => t.languageId == l.id) : null;
      var text = (langText) ? langText.value : '';
      this.messageLangs.push(new FormControl(text));
    });
  }

  onTabChanged(event) {
    if(event) this.currentTabIndex = event.index;
  }

  setDays(values: []) {
    values.forEach(d => {
      switch(d) {
        case 'Mo': 
        this.monControl.setValue(true);
        break;
        case 'Tu': 
        this.tueControl.setValue(true);
        break;
        case 'Th': 
        this.thuControl.setValue(true);
        break;
        case 'We': 
        this.wedControl.setValue(true);
        break;
        case 'Fr': 
        this.friControl.setValue(true);
        break;
        case 'Su': 
        this.sunControl.setValue(true);
        break;
        case 'Sa': 
        this.satControl.setValue(true);
        break;
        default:
          break;
      }
    });
  }

  private getData() {
    this.spinnerService.show();
    const instObs = this.institutionService.getInstitutions(
      new InquiryRequest()
    );
    const catObs = this.categoryService.getCategories(new InquiryRequest());
    this.subscriptions.push(
      instObs
        .pipe(finalize(() => this.spinnerService.hide()))
        .subscribe(this.onInstitutionsSuccess, this.onError)
    );
    this.spinnerService.show();
    this.subscriptions.push(
      catObs
        .pipe(finalize(() => this.spinnerService.hide()))
        .subscribe(this.onCategoriesSuccess, this.onError)
    );
    this.spinnerService.show();
    this.scheduledNotService.fetch().subscribe(res => {
      this.scheduledNot = res.returnValues;
      this.spinnerService.hide();
    }, err => {
      this.spinnerService.hide();
      console.log(err);
    })
  }

  private setDefaultOptions() {
    const defaultCategory = {
      id: 0,
      description: "All categories",
    } as Category;
    const defaultInstitution = {
      id: 0,
      description: "All institutions",
    } as Institution;
    this.categories.push(defaultCategory);
    this.institutions.push(defaultInstitution);
  }

  private setFormGroup(
    categoryId: number,
    institutionId: number,
    message: string
  ) {
    const scheduledTypeValidators = (this.isScheduled) ? [Validators.required] : [];
    this.scheduledTypeControl = new FormControl("1", scheduledTypeValidators);
    this.dateControl = new FormControl(new Date());
    this.daysControl = new FormControl(1);
    this.messageLangs = new FormArray([]);
    this.daysForm = this.createDaysForm();
    this.notificationForm = new FormGroup({
      categoryId: new FormControl(categoryId, Validators.required),
      institutionId: new FormControl(institutionId, Validators.required),
      message: new FormControl(message, [
        Validators.required,
        Validators.maxLength(300),
      ]),
      scheduledTypeControl: this.scheduledTypeControl,
      dateControl: this.dateControl,
      messageLangs: this.messageLangs
    });
  }

  private createDaysForm(): FormGroup {
    this.monControl = new FormControl(false);
    this.tueControl = new FormControl(false);
    this.wedControl = new FormControl(false);
    this.thuControl = new FormControl(false);
    this.friControl = new FormControl(false);
    this.satControl = new FormControl(false);
    this.sunControl = new FormControl(false);
    return new FormGroup({
      monControl: this.monControl,
      tueControl: this.tueControl,
      wedControl: this.wedControl,
      thuControl: this.thuControl,
      friControl: this.friControl,
      satControl: this.satControl,
      sunControl: this.sunControl
    });
  }

  get f() {
    return this.notificationForm.controls;
  }

  onSubmit(languageId = 1) {
    const errorMsg = "Please, complete all fields";
    this.isSubmitted = true;
    if (this.notificationForm.valid) {
      this.isLoading = true;
      const data = {
        categoryId: this.notificationForm.value.categoryId,
        institutionId: this.notificationForm.value.institutionId,
        payload: JSON.stringify({
          notification: {
            title: "Student Symptom Checker",
            body: this.notificationForm.value.message,
          },
        }),
        languageId: languageId,
      };
      this.subscriptions.push(
        this.notifSubscriptionService
          .sendNotification(data)
          .subscribe(this.onSendNotificationSuccess, this.onError)
      );
    } else {
      this.toasterService.show(MessageType.VALIDATION, errorMsg);
      return;
    }
  }

  onSave(languageId = 1) {
    const errorMsg = "Please, complete all fields";
    this.isSubmitted = true;
    console.log(this.notificationForm);

    if (this.notificationForm.valid) {
      this.isLoading = true;
      const data = {
        id: (this.currentNotification) ? this.currentNotification.id : -1,
        userTypeId: this.notificationForm.value.categoryId,
        institutionId: this.notificationForm.value.institutionId,
        message: this.notificationForm.value.message,
        tableName: 'survey_user',
        columnName: 'created_date',
        comparerId: 1,
        valueDataType: (this.scheduledTypeControl.value == "1") ? 'weekly' : (this.scheduledTypeControl.value == "2") ? 'date' : 'days-based',
        value: this.buildValue(),
        notificationSubscriptions: [],
        textList: this.updateTextList(languageId),
        createdBy: (this.currentNotification) ? this.currentNotification.createdBy : 'ADMIN',
        createdDate:  (this.currentNotification) ? this.currentNotification.createdDate : new Date(),
        rowStatus: true,
        modelAction: (this.currentNotification) ? PersistenceAction.UPDATE : PersistenceAction.INSERT,
        updatedBy: "",
        updatedDate: new Date(),
        displayOrder: 1,
        tag: null
      };
      this.subscriptions.push(
        this.scheduledNotService
          .save(data)
          .subscribe(response => {
            
            this.isLoading = false;
            
            if (response.operationSuccess) {
              var infoMsg = "Notification saved successfully.";
              this.toasterService.show(MessageType.INFORMATIONAL, infoMsg);
              this.getData();
            } else if (response.messages.length > 0) {
              this.toasterService.show(
                response.messages[0].type,
                response.messages[0].text
              );
            } else {
              this.onError(ServiceConstant.somethingWentWrongMessage);
            }
          }, this.onError)
      );
    } else {
      this.toasterService.show(MessageType.VALIDATION, errorMsg);
      return;
    }

  }

  updateTextList(languageId) {
    var languages = [];
    console.log(this.messageLangs)
    this.messageLangs.controls.forEach((c,i) => {
      var lang = this.languageService.languages[i];
      var existingNot = (this.currentNotification) ? this.currentNotification.textList.find(t => t.languageId == lang.id) : null;
      if(existingNot) {
        existingNot.value = c.value;
        existingNot.modelAction = PersistenceAction.UPDATE;
      } else {
        existingNot = {
          tableId: -1,
          tableTypeId: 9,
          languageId: lang.id,
          key: 'notification',
          value: c.value,
          createdBy: 'ADMIN',
          createdDate: new Date(),
          rowStatus: true,
          modelAction: PersistenceAction.INSERT,
          updatedBy: "",
          updatedDate: new Date(),
          displayOrder: 1,
          tag: null
        }
      }
      languages.push(existingNot);
    });
    return languages;
  }

  buildValue() {
    if(this.scheduledTypeControl.value == "1"){
      return this.createDaysValue() + ',' + this.toUtcHour(this.timepicker.timepickerTime);
    } else if(this.scheduledTypeControl.value == "2"){
      var dateToSend = new Date(this.dateControl.value);
      return dateToSend.toLocaleDateString() + ',' + this.toUtcHour(this.timepicker.timepickerTime);
    } else {
      return this.daysControl.value.toString() + ',' + this.toUtcHour(this.timepicker.timepickerTime);
    }
  }

  createDaysValue(): string {
      var days = "";
      if(this.monControl.value) days += "Mo,";
      if(this.tueControl.value) days += "Tu,";
      if(this.wedControl.value) days += "We,";
      if(this.thuControl.value) days += "Th,";
      if(this.friControl.value) days += "Fr,";
      if(this.satControl.value) days += "Sa,";
      if(this.sunControl.value) days += "Su,";
    return days;
  }

  toRealTime() {
    this.resetForms();
    this.isScheduled = false;
  }

  toNewScheduled() {
    this.resetForms();
    this.assignLanguages();
    this.isScheduled = true;
    this.isCreating = true;
    this.currentNotification = null;
    this.currentTabIndex = 0;
  }

  resetForms() {
    this.notificationForm.reset();
    this.scheduledTypeControl.reset();
    this.scheduledTypeControl.setValue("1");
    this.messageLangs.clear();
    this.dateControl.reset();
    this.daysForm.reset();
    this.monControl.reset();
    this.tueControl.reset();
    this.wedControl.reset();
    this.thuControl.reset();
    this.friControl.reset();
    this.satControl.reset();
    this.sunControl.reset();
    if(this.notificationForm.controls['institutionId']) this.notificationForm.controls['institutionId'].setValue(0);
    if(this.notificationForm.controls['categoryId']) this.notificationForm.controls['categoryId'].setValue(0);
  }

  ngOnInit(): void {
    this.loadLanguages();
    this.getData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
