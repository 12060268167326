import { Component, OnInit, Input, OnChanges, SimpleChanges } from "@angular/core";
import {
  QuestionGroup,
  Option,
  Question,
  RelatedObject
} from "projects/shared-lib/src/public-api";

import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { ControlContainer, NgForm } from "@angular/forms";
import { QuestionGroupUtil } from "projects/AdminApp/src/app/shared/util/question-group.util";
import { RelatedObjectUtility } from "projects/AdminApp/src/app/shared/util/related-object-utility";
import { AngularEditorConfig } from "../../../../../../../angular-editor/src/public-api";
import { CustomEditorConfig } from "projects/AdminApp/src/app/admin/customEditorConfig";
import { ValidationControlUtil } from "projects/AdminApp/src/app/shared/util/validation.util";
import { TagHelper } from '../tagHelper';
import { DialogComponent } from "projects/AdminApp/src/app/core/dialog/dialog.component";
import { Dialog } from "projects/AdminApp/src/app/core/dialog/dialog.model";
import { MatDialog } from "@angular/material/dialog";
import { OptionVariableUtility } from "projects/AdminApp/src/app/shared/util/option-variable.util";

@Component({
  selector: "app-question-panel",
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: NgForm
    }
  ],
  templateUrl: "./question-panel.component.html",
  styleUrls: ["./question-panel.component.css"]
})
export class QuestionPanelComponent implements OnInit, OnChanges {
  @Input() questionGroup: QuestionGroup;
  @Input() canAssociateObjects: RelatedObject[];
  @Input() relatedObjects: RelatedObject[];
  @Input() selectedObject: RelatedObject;
  @Input() isObjectRemoved: boolean;
  @Input() isUsedByTree: boolean;
  @Input() surveyVariables: string[];

  config: AngularEditorConfig = new CustomEditorConfig(true, true);
  public tagHelper: TagHelper;
  constructor(
    private questionGroupUtil: QuestionGroupUtil,
    private relatedObjectUtility: RelatedObjectUtility,
    public validationControlUtil: ValidationControlUtil,
    public dialog: MatDialog,
    private optionVariableUtil: OptionVariableUtility
  ) {
    this.tagHelper = new TagHelper(this.relatedObjectUtility);
  }

  ngOnChanges(): void {
    this.optionVariableUtil.setOptionVariables(this.questionGroup);
  }

  ngOnInit() {
    this.updateQsGroupItems();
  }


  public updateQsGroupItems() {
    this.tagHelper.loadMaps(this.questionGroup, this.relatedObjects);
  }

  canShowMultipleQuestions(index: number) {
    if (this.questionGroup.isScoreBased || this.questionGroup.isStepBased)
      return true;
    if (index >= 1) {
      return false;
    }
    return true;
  }

  getOptionText(option: Option): string {
    if (
      option.text == null ||
      option.text == undefined ||
      option.text.length == 0
    ) {
      if (this.questionGroup.isStepBased) return "Enter step text";
      return "Enter option text";
    }
    return option.text;
  }

  addQuestion() {
    this.questionGroupUtil.addQuestion(this.questionGroup);
    this.updateQsGroupItems();
  }

  removeQuestion(index: number) {
    this.questionGroupUtil.removeQuestion(this.questionGroup, index);
    this.isObjectRemoved = true;
    this.updateQsGroupItems();
  }

  addOption(question: Question) {
    this.questionGroupUtil.addOption(question);
     this.tagHelper.loadMaps(this.questionGroup,this.relatedObjects);
    this.updateQsGroupItems();
  }

  removeOption(question: Question, i: number) {
    this.questionGroupUtil.removeOption(question, i);
    this.isObjectRemoved = true;
    this.updateQsGroupItems();
  }

  onDrop(event: CdkDragDrop<string[]>, containerObject: Option) {
    let selectedObject = event.item.data as RelatedObject;
    this.setNextIdValue(selectedObject, containerObject);
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.previousIndex
    );
  }

  setNextIdValue(selectedObject: RelatedObject, containerObject: Option) {
    if (selectedObject != null) {
      if (selectedObject.isQuestionGroup) {
        containerObject.nextQuestionGroupId = selectedObject.id;
        containerObject.treatmentId = 0;
      } else {
        containerObject.treatmentId = selectedObject.id;
        containerObject.nextQuestionGroupId = 0;
      }
      containerObject.relatedObject = selectedObject;
    }
  }

  onRelatedObjectChange(item, containerObject: Option) {
    let selectedObject: RelatedObject = item.value as RelatedObject;
    this.setNextIdValue(selectedObject, containerObject);
  }

  getRelatedObject(isOpen: boolean){
    // if(isOpen)
    //   this.filteredList = this.optionMap[option.id].slice();
  }

  shouldShowAddAndRemoveQuestion() {
    return this.questionGroup.isScoreBased || this.questionGroup.isStepBased;
  }

  getLabelForOption() {
    if (this.questionGroup.isStepBased) return "step";
    return "option";
  }

  getDisplayText(question: Question): string {
    if (question && question.text) {
      return this.questionGroupUtil.getDropdownText(question);
    }
    return "";
  }

  displayVariableField(): boolean{
    return !this.questionGroup.isScoreBased && this.isUsedByTree;
  }

  private createNewDialog(): Dialog {
    const title =  "Propagate change";
    const content =  "Cancel to cancel<br/> Propagate - Propagate to same card instances <br/>";
    const option_1 = "Cancel";
    const option_2 =  "Continue";
    return new Dialog(title, content, option_1, option_2, null);
  }


  variableListChanged(option: Option) {
    if(!this.isUsedByTree)
      return;
    const dialog = this.createNewDialog();
    const dialogConf = { data: dialog };
    const dialogRef = this.dialog.open(DialogComponent, dialogConf);
  
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        option.copyVariables = true;
      }
    });
  }
}
