import { Component, OnInit, Inject } from "@angular/core";
import { NgForm } from "@angular/forms";
import {
  Decision,
  GuidelineLangText,
} from "projects/shared-lib/src/public-api";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { GuidelineLang } from "../../shared/model/guideline-lang.model";
import { CustomEditorConfig } from "../customEditorConfig";
import { AngularEditorConfig } from "../../../../../angular-editor/src/public-api";
import { ValidationControlUtil } from "../../shared/util/validation.util";
import { DialogComponent } from "../../core/dialog/dialog.component";

@Component({
  selector: "app-guidelines-lang",
  templateUrl: "./guidelines-lang.component.html",
  styleUrls: ["./guidelines-lang.component.css"],
})
export class GuidelinesLangComponent implements OnInit {
  public readableGuideline: Decision;
  public editableGuideline: GuidelineLangText;
  public languageDesc: string;
  public submitted = false;
  config: AngularEditorConfig = new CustomEditorConfig(true, true);

  constructor(
    private dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GuidelineLang,
    public validationControlUtil: ValidationControlUtil
  ) {
    this.readableGuideline = data.readable;
    this.editableGuideline = data.editable;
    this.languageDesc = data.language;
  }

  onCancel() {
    this.dialogRef.close(undefined);
  }

  onSave(form: NgForm) {
    this.submitted = true;
    if (form.invalid) {
      alert(this.validationControlUtil.getErrorMsg(form.controls));
      return;
    } else {
      this.dialogRef.close(this.editableGuideline);
    }
  }

  ngOnInit(): void {}
}
