import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CdkDragStart, CdkDragEnd } from '@angular/cdk/drag-drop';
import { MessageType, PersistenceAction, RelatedObject, ToasterService, Treatment } from 'projects/shared-lib/src/public-api';
import { AngularEditorConfig } from '../../../../../../angular-editor/src/public-api';
import { CustomEditorConfig } from 'projects/AdminApp/src/app/admin/customEditorConfig';
import { TreatmentUtil } from 'projects/AdminApp/src/app/shared/util/treatment.util';
import { RelatedObjectUtility } from 'projects/AdminApp/src/app/shared/util/related-object-utility';
import { TreatmentService } from '../../../shared/service/treatment.service';
import { TemplateDeleteUtility } from '../../../shared/util/template.delete.utility';

@Component({
  selector: 'app-treatment-template-panel',
  templateUrl: './treatment-template-panel.component.html',
  styleUrls: ['./treatment-template-panel.component.css']
})
export class TreatmentTemplatePanelComponent implements OnInit {
  @Input() isTemplate: boolean;
  @Input() treatments: Treatment[];
  @Input() filteredTreatments: RelatedObject[] = [];
  @Input() canAssociateObjects: RelatedObject[] = [];
  @Output() afterTreatmentSearch = new EventEmitter();
  @Output() afterSelectedObjectChange: EventEmitter<Treatment> = new EventEmitter<Treatment>();
  @Output() afterDeleteComplete = new EventEmitter();

  selectedObject: Treatment;

  treatmentFilterString: string = "";
  config: AngularEditorConfig = new CustomEditorConfig(false, true);

  constructor(private treatmentUtility: TreatmentUtil,
              private relatedObjectUtilty: RelatedObjectUtility,
              private treatmentService: TreatmentService,
              private templateDeleteUtility: TemplateDeleteUtility,
              private toasterService: ToasterService) { }

  ngOnInit() {
  }

  addNewTreatment() {
    this.selectedObject = this.treatmentUtility.createTreatment();
    this.afterSelectedObjectChange.emit(this.selectedObject );
  }


  getTreatment(item: RelatedObject): Treatment {
    return this.relatedObjectUtilty.getTreatmentFromRelatedObject(item,this.treatments);
  }

  editTreatment(treatment: Treatment) {
    const editTreatment = this.treatmentUtility.editTreatment(treatment);
    this.updateSelectedObject(editTreatment);
  }

  onTreatmentSearch() {
    this.filteredTreatments.length = 0;
    if(this.treatments == undefined || this.treatments == null)
      return;
    if (this.treatmentFilterString.length === 0) {
      this.treatments.forEach(treatment => {
        if(treatment.templateTreatmentId == 0) {
          this.filteredTreatments.push(
            this.relatedObjectUtilty.convertTreatmentToRelatedObject(treatment,true));
        }
      });
    } else {
      this.treatments.forEach(treatment => {
        if (
          (treatment.text
            .toLowerCase()
            .indexOf(this.treatmentFilterString.toLowerCase()) !== -1 ||
          treatment.tag
            .toLowerCase()
            .indexOf(this.treatmentFilterString.toLowerCase()) !== -1) 
            && treatment.templateTreatmentId == 0
        ) {
          this.filteredTreatments.push(
            this.relatedObjectUtilty.convertTreatmentToRelatedObject(treatment,true)
          );
        }
      });
    }
    this.afterTreatmentSearch.emit();
  }

  dragStarted(event: CdkDragStart)
  {
    let htmlElement = event.source.element.nativeElement;
    htmlElement.style.width = "5%";
    htmlElement.style.height = "50px";
  }

  dragEnded(event: CdkDragEnd){
    let htmlElement = event.source.element.nativeElement;
    htmlElement.style.width = "100%";
    htmlElement.style.height = "100%";
    htmlElement.style.overflow = "hidden";
  }

  onDeleteTreatment(delObject: RelatedObject){
    let treatment = this.getTreatment(delObject);
    let dialogRef = this.templateDeleteUtility.canDeleteTemplate();
    dialogRef.afterClosed().subscribe((result) => {
        if (result !== undefined) {
          treatment.modelAction = PersistenceAction.DELETE;
          let inst = this.treatmentService.saveTreatment(treatment);
          inst.subscribe((response) => {
            if (response.operationSuccess) {
              this.toasterService.show(MessageType.INFORMATIONAL,"Treatment successfully deleted.");
              this.afterDeleteComplete.emit();
            }
            else{
              this.toasterService.show(response.messages[0].type, response.messages[0].text);
            }
          });
        }
      });

  }

  updateSelectedObject(treatment: Treatment): void {
    this.selectedObject = null;
    this.selectedObject = treatment;
    this.afterSelectedObjectChange.emit(this.selectedObject);
  }

  onNewTreatmentAs(item: RelatedObject): void {
    const treatment = this.getTreatment(item);
    const newTreatmentAs = this.treatmentUtility.newTreatmentAs(treatment);
    this.updateSelectedObject(newTreatmentAs);
  }



}
