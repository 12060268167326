import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "filterByNameOrTag" })
export class FilterByNameOrTagPipe implements PipeTransform {
  private containValue(obj: any, key: string, value: string): boolean {
    if (obj[key].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
      return true;
    }
    return false;
  }

  private filterByProps(firstKey: string, secondKey: string, value: string) {
    return element => {
      return (
        this.containValue(element, firstKey, value) ||
        this.containValue(element, secondKey, value)
      );
    };
  }

  private filterByNameOrTag(elements: any[], value: string) {
    const nameKey = "name";
    const tagKey = "tag";

    if (value.length === 0) {
      return [...elements];
    } else {
      return elements.filter(this.filterByProps(nameKey, tagKey, value));
    }
  }

  transform(elements: any[], value: string) {
    return this.filterByNameOrTag(elements, value);
  }
}
