import { Component, OnInit, ViewChild } from '@angular/core';
import { RelatedObject, Treatment, QuestionGroup, 
        SpinnerService, ToasterService, Pathway } 
        from 'projects/shared-lib/src/public-api';
import { RelatedObjectUtility } from 'projects/AdminApp/src/app/shared/util/related-object-utility';
import { PathwayTreeComponent } from './pathway-tree/pathway-tree.component';
import { TreatmentTemplatePanelComponent } from '../treatment-template-panel/treatment-template-panel.component';
import { QuestionTemplatePanelComponent } from '../question-template-panel/question-template-panel.component';
import { QuestionGroupService } from 'projects/AdminApp/src/app/shared/service/questiongroup.service';
import { ServiceUtil } from 'projects/AdminApp/src/app/shared/util/service-util';
import { TreatmentService } from 'projects/AdminApp/src/app/shared/service/treatment.service';
import { LanguageService } from '../../../shared/service/language.service';


@Component({
  selector: 'app-pathway-tree-panel',
  templateUrl: './pathway-tree-panel.component.html',
  styleUrls: ['./pathway-tree-panel.component.css']
})
export class PathwayTreePanelComponent implements OnInit {
  @ViewChild(PathwayTreeComponent, { static: false }) pathwayTreeComponent: PathwayTreeComponent;
  @ViewChild(TreatmentTemplatePanelComponent, { static: false }) treatmentChild: TreatmentTemplatePanelComponent;
  @ViewChild(QuestionTemplatePanelComponent, { static: false }) questionGroupChild: QuestionTemplatePanelComponent;

  groups: QuestionGroup[];
  treatments: Treatment[];
  leftPanelVisible: boolean = true;
  rightPanelVisible: boolean = false;
  selectedObject: QuestionGroup | Treatment;
  relatedObjects: RelatedObject[];
  filteredGroups: RelatedObject[] = [];
  filteredTreatments: RelatedObject[] = [];
  selectedPathway: Pathway;

  constructor( private toasterService: ToasterService, 
    private questionGroupService: QuestionGroupService,
    private treatmentService: TreatmentService,
    private serviceUtility: ServiceUtil,
    private spinnerService : SpinnerService,
    private relatedObjectUtility: RelatedObjectUtility,
    private languageService: LanguageService  ) { 
    }

  ngOnInit() {
    this.getQuestionGroups();
    this.getTreatments();
    this.languageService.getLanguages();
  }

  getTreatments(){
    this.spinnerService.show();
    let instTreatments = this.treatmentService.retrieveTreatments();
    instTreatments.subscribe(response=>{
      if (response.operationSuccess) {
        this.treatments = response.returnValues;
        this.treatmentChild.treatments = this.treatments;
        this.treatmentChild.onTreatmentSearch();
        this.treatmentChild.canAssociateObjects = 
              this.relatedObjectUtility.combineRelatedObjectArrays(this.filteredGroups,this.filteredTreatments);
        this.relatedObjects = this.treatmentChild.canAssociateObjects;
        this.treatmentChild.filteredTreatments = this.filteredTreatments; 
      }
      else
      {
        this.toasterService.show("Error",this.serviceUtility.getErrorMessage<Treatment>(response));
        this.treatments = null;
      }
      this.spinnerService.hide();
    });
  }


  getQuestionGroups() {
    this.spinnerService.show();
    let instObs = this.questionGroupService.retrieveQuestionGroups(); 
    instObs.subscribe(response=>{
      if(response.operationSuccess)
      {
        this.groups = response.returnValues;
        this.questionGroupChild.groups = this.groups;
        this.questionGroupChild.onQuestionSearch();
        this.questionGroupChild.canAssociateObjects = 
        this.relatedObjectUtility.combineRelatedObjectArrays(this.filteredGroups,this.filteredTreatments);
        this.relatedObjects = this.questionGroupChild.canAssociateObjects;
        this.relatedObjectUtility.populateRelatedObjectsOnGroups(this.groups,this.relatedObjects);
        this.questionGroupChild.filteredGroups = this.filteredGroups;
      }
      else
      {
        this.toasterService.show("Error",this.serviceUtility.getErrorMessage<QuestionGroup>(response));
        this.groups = null;
      }
      this.spinnerService.hide();
    });
  }

  onPathwayItemEditCancelled()
  {
    this.leftPanelVisible = true;
    this.rightPanelVisible = false;
    this.selectedObject = null;
  }

  onPathwayItemEditComplete()
  {
    this.pathwayTreeComponent.updateSelectedObjectAndTree(this.selectedObject);
    this.onPathwayItemEditCancelled();
  }
  
  updateSelectedObject(event)
  {
    this.selectedObject = event; // (isTreatment) ? this.relatedObjectUtility.getTreatmentFromRelatedObject(event, this.treatments) : event;
    this.leftPanelVisible = false;
    this.rightPanelVisible = true;
    this.relatedObjectUtility.populateRelatedObjectsOnPathwayGroups(this.pathwayTreeComponent.selectedPathway);
  }

  populateAssociateObjectsArray()
  {
    this.relatedObjectUtility.combineRelatedObjectArrays(this.getFilteredGroups(),this.getFilteredTreatments());
  }

  getFilteredTreatments()
  {
    return this.treatmentChild.filteredTreatments;
  }

  getFilteredGroups()
  {
    return this.questionGroupChild.filteredGroups;
  }

  isSelectedObjectQuestionGroup(): boolean {
    if (this.selectedObject) {
      return "questions" in this.selectedObject;
    }
    return false;
  }

  isSelectedObjectTreatment(): boolean{
    if (this.selectedObject != null) {
      return !("questions" in this.selectedObject);
    }
    return false;
  }

  getSurveyVariables(): string[]{
    return this.pathwayTreeComponent.surveyVariables;
  }

  afterPathwayTreeSaved(event){
    this.getQuestionGroups();
    this.getTreatments();
  }
}
