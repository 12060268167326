<div cdkDropListGroup>
  <div class="d-flex flex-row">
    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 scroll-panel">
      <div class="text-left">
        <app-question-template-panel
          [groups]="groups"
          [canAssociateObjects]="canAssociateObjects"
          [filteredGroups]="filteredGroups"
          [isTemplate]="isTemplate"
          (afterQuestionGroupSearch)="populateAssociateObjectsArray()"
          (afterSelectedObjectChange)="updateSelectedObject($event)"
          (afterDeleteComplete)="retieveQuestionGroups()"
        >
        </app-question-template-panel>
      </div>
    </div>
    <div  id="center-template-panel" class="col-xs-8 col-md-8 col-lg-8 col-xl-8 scroll-panel">
      <div *ngIf="isSelectedObjectQuestionGroup()">
        <app-newquestion
          [isUsedByTree]="false"
          [canAssociateObjects]="canAssociateObjects"
          [questionGroup]="selectedObject"
          (afterSaveComplete)="retieveQuestionGroups($event)"
          (afterCancelClicked)="retieveQuestionGroups()"
        ></app-newquestion>
      </div>
      <div *ngIf="isSelectedObjectTreatment()">
        <app-treatment
          [isUsedByTree]="false"
          [treatment]="selectedObject"
          (afterSaveComplete)="retieveQuestionGroups($event)"
          (afterCancelClicked)="retieveQuestionGroups()"
        ></app-treatment>
      </div>
    </div>
    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 scroll-panel">
      <div class="text-left">
        <app-treatment-template-panel
          [treatments]="treatments"
          [canAssociateObjects]="canAssociateObjects"
          [filteredTreatments]="filteredTreatments"
          [isTemplate]="isTemplate"
          (afterTreatmentSearch)="populateAssociateObjectsArray()"
          (afterSelectedObjectChange)="updateSelectedObject($event)"
          (afterDeleteComplete)="retieveQuestionGroups()"
        >
        </app-treatment-template-panel>
      </div>
    </div>
  </div>
</div>
