
import { Pipe, PipeTransform } from "@angular/core";
import { TableFilterGroup } from "projects/shared-lib/src/public-api";

@Pipe({ name: "filterTableFGroupByQuestion" })
export class filterTableFGroupByQuestionPipe implements PipeTransform {

  private filterByQuestionId(id: number) {
    return (filterGroup: TableFilterGroup) => {
      return filterGroup.surveyQuestionId === id;

    };
  }
  //we're returin the first array element prior to having only one table filter group saved
  transform(filterGroups: TableFilterGroup[], id: number): TableFilterGroup {
    const foundGroup = filterGroups.find(this.filterByQuestionId(id));
    return foundGroup ? foundGroup : null;
  }
}
