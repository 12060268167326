<div class="treatment-lang-container">
  <div class="treatment-header color-black">English(Read Only)</div>
  <div class="treatment-header color-black">{{ languageDesc }}</div>
  <div class="treatment-section text-left bg-primary">
    <label class="color-white">Treatment Text</label>
    <mat-card class="treatment-content">
      <mat-card-content>
        <div [innerHTML]="readableTreatment.text | sanitizer: 'html'"></div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="treatment-section text-left bg-primary">
    <form (ngSubmit)="onSave(form)" #form="ngForm" name="form">
      <mat-dialog-actions>
        <div class="col-12">
          <div class="btn-toolbar justify-content-between" role="toolbar">
            <div class="btn-group" role="group"></div>
            <div class="btn-toolbar" role="group">
              <button
                type="button"
                class="btn btn-primary"
                (click)="onCancel()"
              >
                Cancel
              </button>
              &nbsp;&nbsp;
              <button *ngIf="!readableTreatment.isSaveAs" class="btn btn-white" cdkFocusInitial type="submit">
                Save
              </button>
              <button *ngIf="readableTreatment.isSaveAs"class="btn btn-white" cdkFocusInitial type="submit">
                Save As
              </button>
            </div>
          </div>
          <br />
        </div>
      </mat-dialog-actions>
      <div class="editor color-white">
        <div class="row">
          <div class="col-sm-12">
            <label>Treatment Text *</label>
          </div>
        </div>
        <div class="color-dark bg-white">
          <angular-editor
            id="editable_treat_text_ + {{ editableTreatment.id }}"
            [(ngModel)]="editableTreatment.value"
            [config]="config"
            name="editable_treat_text_ + {{ editableTreatment.id }}"
            required
            maxlength="{{ validationControlUtil.largeTextLength }}"
            #text="ngModel"
            (errorMsg)="validationControlUtil.showError($event)"
          >
          </angular-editor>
        </div>
        <div *ngIf="editableTreatment && editableTreatment?.value" class="col-12 text-right pt-1">
          <label>Characters count: {{ editableTreatment.value?.length }}</label>
        </div>
        <div class="row">
          <div class="col-sm-12" *ngIf="submitted && form.invalid">
            <p *ngIf="text.errors.required">
              This field is required
            </p>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-sm-12">
            <label>Display Text</label>
            <br />
            <mat-card class="treatment-content">
              <mat-card-content>
                <div
                  [innerHTML]="editableTreatment.value | sanitizer: 'html'"
                ></div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
