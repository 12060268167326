import { Component, OnInit, Input } from "@angular/core";
import {
  QuestionGroup,
  RelatedObject,
  ScoreToNextObject,
} from "projects/shared-lib/src/public-api";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { ControlContainer, NgForm } from "@angular/forms";
import { QuestionGroupUtil } from "../../../../shared/util/question-group.util";
import { RelatedObjectUtility } from "../../../../shared/util/related-object-utility";
import { ValidationControlUtil } from "../../../../shared/util/validation.util";
import { TagHelper } from '../tagHelper';

@Component({
  selector: "app-score-panel",
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: NgForm,
    },
  ],
  templateUrl: "./score-panel.component.html",
  styleUrls: ["./score-panel.component.css"],
})
export class ScorePanelComponent implements OnInit {
  @Input() questionGroup: QuestionGroup;
  @Input() canAssociateObjects: RelatedObject[];
  @Input() relatedObjects: RelatedObject[];
  @Input() selectedObject: RelatedObject;
  @Input() isObjectRemoved: boolean;
  public tagHelper: TagHelper;
  constructor(
    private service: QuestionGroupUtil,
    private relatedObjectUtility: RelatedObjectUtility,
    public validationControlUtil: ValidationControlUtil
  ) {
    this.tagHelper = new TagHelper(this.relatedObjectUtility);
  }

  ngOnInit() {
    this.tagHelper.loadMaps(this.questionGroup,this.relatedObjects);
  }

  removeScoreObject(i: number) {
    this.service.removeScoreObject(this.questionGroup, i);
    this.isObjectRemoved = true;
  }

  addScoreObject() {
    this.service.addScoreObject(this.questionGroup);
  }

  getScoreText(scoreObject: ScoreToNextObject): string {
    if (
      scoreObject.score == null ||
      scoreObject.score == undefined ||
      scoreObject.score.length == 0
    )
      return "Enter score range";
    return scoreObject.score;
  }

  onDrop(event: CdkDragDrop<string[]>, containerObject: ScoreToNextObject) {
    let selectedObject = event.item.data as RelatedObject;
    this.setNextIdValue(selectedObject, containerObject);
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.previousIndex
    );
  }

  setNextIdValue(
    selectedObject: RelatedObject,
    containerObject: ScoreToNextObject
  ) {
    if (selectedObject != null) {
      if (selectedObject.isQuestionGroup) {
        containerObject.nextQuestionGroupId = selectedObject.id;
        containerObject.treatmentId = 0;
      } else {
        containerObject.treatmentId = selectedObject.id;
        containerObject.nextQuestionGroupId = 0;
      }
      containerObject.relatedObject = selectedObject;
    }
  }

  onRelatedObjectChange(item, containerObject: ScoreToNextObject) {
    let selectedObject: RelatedObject = item.value as RelatedObject;
    this.setNextIdValue(selectedObject, containerObject);
  }
}
