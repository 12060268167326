<h2 mat-dialog-title>Import Pathway Tree</h2>
<div *ngIf="decisions">
    <div>
      <label>Guidelines</label>
      <ng-select
        name="decisionId"
        [multiple]="false"
        [(ngModel)]="selectedDecision"
        placeholder="Select an option"
        (change)="onDecisionChanged()"
      >
        <ng-option
          *ngFor="let decision of decisions;"
          [value]="decision"
          required>
          {{ decision.name }}
        </ng-option>
      </ng-select>
    </div>
    <br/>
    <div>
        <label>Institution</label>
        <ng-select
          name="instOwnerId"
          [multiple]="false"
          [(ngModel)]="selectedInstitution"
          placeholder="Select an option" (change)="onInstitutionChanged()"
        >
          <ng-option
            *ngFor="let inst of institutions;"
            [value]="inst"
            required
          >
            {{ inst.description }}
          </ng-option>
        </ng-select>
      </div>
    <br/>
      <div>
        <label>Pathway</label>
        <ng-select
          name="pathway"
          [multiple]="false"
          [(ngModel)]="selectedPathway"
          placeholder="Select a option">
          <ng-option
            *ngFor="let pathway of pathwaysForInstitution"
            [value]="pathway"
            required
          >
            {{ pathway.name }}
          </ng-option>
        </ng-select>
      </div>
      <br/>
      <mat-dialog-actions>
        <button class="btn btn-secondary mr-4"(click)="close()">Cancel</button>
        <button id="confirmImport" class="btn btn-primary"(click)="import()">Import</button>
    </mat-dialog-actions>
</div>
