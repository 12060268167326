import { Pipe, PipeTransform } from "@angular/core";
import { Treatment } from "projects/shared-lib/src/public-api";

@Pipe({ name: "parseTreamentText" })
export class ParseTreatmentTextPipe implements PipeTransform {
  private parseText(treatment: Treatment) {
    if (treatment && treatment.text) {
      const { text } = treatment;
      return text.replace(/<[^>]*>/g, "");
    } else {
      return "";
    }
  }

  transform(treatment: Treatment): string {
    return this.parseText(treatment);
  }
}
