import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { CdkDragStart, CdkDragEnd } from '@angular/cdk/drag-drop';
import { MessageType, PersistenceAction, Question, QuestionGroup, RelatedObject, ToasterService } from "projects/shared-lib/src/public-api";
import { QuestionGroupUtil } from 'projects/AdminApp/src/app/shared/util/question-group.util';
import { RelatedObjectUtility } from 'projects/AdminApp/src/app/shared/util/related-object-utility';
import { QuestionGroupService } from '../../../shared/service/questiongroup.service';
import { TemplateDeleteUtility } from '../../../shared/util/template.delete.utility'
import { LocalStorageUtil } from 'projects/shared-lib/src/lib/util/local-storage.util';
@Component({
  selector: 'app-question-template-panel',
  templateUrl: './question-template-panel.component.html',
  styleUrls: ['./question-template-panel.component.css']
})
export class QuestionTemplatePanelComponent implements OnInit {
  @Input() isTemplate: boolean;
  @Input() groups: QuestionGroup[];
  selectedObject: QuestionGroup;
  @Input() canAssociateObjects: RelatedObject[] = [];
  @Input() filteredGroups: RelatedObject[] = [];
  groupFilterString: string = "";

  @Output() afterQuestionGroupSearch = new EventEmitter();
  @Output() afterSelectedObjectChange: EventEmitter<QuestionGroup> = new EventEmitter<QuestionGroup>();
  @Output() afterDeleteComplete = new EventEmitter();

  constructor(private relatedObjectUtility: RelatedObjectUtility,
              private questionGroupUtility: QuestionGroupUtil,
              private questionGroupService: QuestionGroupService,
              private templateDeleteUtility: TemplateDeleteUtility,
              private localStorageUtil: LocalStorageUtil,
              private toasterService: ToasterService) { }

  ngOnInit() {
    console.log(this.filteredGroups)
  }

  addNewQuestionGroup() {
    let group = this.questionGroupUtility.createQuestionGroup();
    this.selectedObject = {...group};
    this.selectedObject.id = 0;
    this.afterSelectedObjectChange.emit(this.selectedObject);
  }

  editQuestionGroup(group: QuestionGroup): void {
    const editQsGroup = this.questionGroupUtility.editQuestionGroup(group);
    this.updateSelectedObject(editQsGroup);
  }

  onQuestionSearch() {
    this.filteredGroups.length = 0;
    if(this.groups == undefined)
      return;
    if (this.groupFilterString.length === 0) {
      this.groups.forEach(group => {
        if(group && group.questions.length && group.templateQuestionGroupId == 0){
          this.filteredGroups.push(
            this.relatedObjectUtility.convertQuestionGroupToRelatedObject(group,true)
          );
        }
      });
    } else {
      this.groups.forEach(group => {
        for(let i: number = 0; i < group.questions.length; i++)
        {
          let question = group.questions[i];
          if (
            (question.text
              .toLowerCase()
              .indexOf(this.groupFilterString.toLowerCase()) !== -1 ||
            question.tag
              .toLowerCase()
              .indexOf(this.groupFilterString.toLowerCase()) !== -1)
              && group.templateQuestionGroupId == 0
          ) {
            this.filteredGroups.push(
              this.relatedObjectUtility.convertQuestionGroupToRelatedObject(group,true)
            );
            break;
          }
        }
      });
    }
    this.afterQuestionGroupSearch.emit();
  }

  getGroup(group: RelatedObject): QuestionGroup {
    return this.relatedObjectUtility.getGroupFromRelatedObject(group, this.groups);
  }

  dragStarted(event: CdkDragStart)
  {
    let htmlElement = event.source.element.nativeElement;
    htmlElement.style.width = "30px";
    htmlElement.style.height = "50px";
  }

  dragEnded(event: CdkDragEnd){
    let htmlElement = event.source.element.nativeElement;
    htmlElement.style.width = "100%";
    htmlElement.style.height = "100%";
    htmlElement.style.overflow = "hidden";
  }

  onDeleteGroup(delObject: RelatedObject){
    let group = this.relatedObjectUtility.getGroupFromRelatedObject(delObject,this.groups);
    let dialogRef = this.templateDeleteUtility.canDeleteTemplate();
    dialogRef.afterClosed().subscribe((result) => {
        if (result !== undefined) {
          group.modelAction = PersistenceAction.DELETE;
    let inst = this.questionGroupService.saveQuestionGroup(group);
    inst.subscribe((response) => {
      if (response.operationSuccess) {
        this.toasterService.show(MessageType.INFORMATIONAL,"Question successfully deleted.");
        this.afterDeleteComplete.emit();
      }
      else{
        this.toasterService.show(response.messages[0].type, response.messages[0].text);
      }
        });
        }
      });
  }

  public updateSelectedObject(qsGroup: QuestionGroup): void {
    this.selectedObject = null;
    this.selectedObject = {...qsGroup};
    this.afterSelectedObjectChange.emit(this.selectedObject);
  }

  public onNewQsGroupAs(item: RelatedObject): void {
    const qsGroup = this.relatedObjectUtility.getGroupFromRelatedObject(item, this.groups);
    const newQsGroupAs = this.questionGroupUtility.newQsGroupAs(qsGroup);
    this.localStorageUtil.saveQsGroup(newQsGroupAs);
    this.updateSelectedObject(newQsGroupAs);
  }
}
