import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "filterOptionTextByText" })
export class FilterOptionTextByTextPipe implements PipeTransform {

  private hasOptionText(value: string) {
    const validations = [
      value === null? false : true,
      value === undefined? false : true,
      value.length === 0? false : true
    ]
    return validations.every( validation => validation === true);
  }

  transform(value: string) {
    const isValidText = this.hasOptionText(value);
    if (isValidText) {
      return value;
    }
    return "Enter option text";
  }
}
