import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../core/dialog/dialog.component';
import { Dialog } from '../../core/dialog/dialog.model';

@Injectable({ providedIn: "root" })
export class TemplateDeleteUtility
{
    constructor(
        public dialog: MatDialog
      ) {}
    public canDeleteTemplate()
    {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: new Dialog(
            "Do you want to delete template?",
            "- Click No to cancel<br/> - Click Yes to delete template <br/>",
            "No",
            "Yes"
            ),
        });
        return dialogRef;
    }
}