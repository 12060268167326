<fieldset ngModelGroup="scorePanel">
  <div
    class="col-12 bg-light scoresPanel options color-dark"
    *ngIf="questionGroup.isScoreBased"
  >
    <br />
    <label>Score Configuration</label>
    <hr />
    <div
      *ngFor="
        let scoreObject of questionGroup.scoreToNextObjectList;
        let s = index
      "
    >
      <div class="row col-sm-12">
        <ng-template [ngIf]="scoreObject.modelAction != 3">
          <mat-accordion class="col-sm-10">
            <mat-expansion-panel class="bg-secondary">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ getScoreText(scoreObject) }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">
                <div class="col-sm-12">
                  <label>Score *</label>
                  <br />
                  <input
                    class="form-control"
                    id = "question_score_text_{{ s }}"
                    name="question_score_text_{{ s }}"
                    placeholder="Score"
                    [(ngModel)]="scoreObject.score"
                    required
                    scoreTextValidator
                    maxlength="{{ validationControlUtil.scoreMaxLength }}"
                  />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-sm-6 ">
                  <label>Tag *</label>
                  <br />
                  <input
                    class="form-control"
                    name="question_score_tag_{{ s }}"
                    placeholder="Tag"
                    [(ngModel)]="scoreObject.tag"
                    required
                    maxlength="{{ validationControlUtil.tagMaxLength }}"
                  />
                </div>
              </div>
              <br />
              <div
                class="row"
                #itemsList="cdkDropList"
                cdkDropList
                [cdkDropListData]="relatedObjects"
                (cdkDropListDropped)="onDrop($event, scoreObject)"
              >
                <div class="col-sm-12 ">
                  <label>Next Step:</label>
                  <br />
                  <mat-select (openedChange)="tagHelper.onOpenClose($event,scoreObject.id)"
                    class=" form-control bg-white"
                    (selectionChange)="
                      onRelatedObjectChange($event, scoreObject)
                    "
                    [(ngModel)]="scoreObject.relatedObject"
                    name="question_score_nextid_{{ s }}"
                  >
                  <mat-select-filter [displayMember]="'tag'"
                                  [array]="tagHelper.optionMap.get(scoreObject.id)" 
                                  (filteredReturn)="tagHelper.cloneOptionMap.set(scoreObject.id,$event)"
                                ></mat-select-filter>
                    <mat-option
                      *ngFor="let object of tagHelper.cloneOptionMap.get(scoreObject.id)"
                      [value]="object"
                    >
                      {{ object.tag }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <div class="col-sm-2">
            <button
              *ngIf="questionGroup.scoreToNextObjectList.length > 1"
              type="button"
              class="btn btn-secondary"
              (click)="removeScoreObject(s)"
            >
              -
            </button>
          </div>
        </ng-template>
      </div>
      <br />
    </div>
    <div class="button-bar">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="addScoreObject()"
      >
        +
      </button>
      <label class="color-dark ml-2"> Add Score </label>
    </div>
  </div>
</fieldset>
