<div class="questionGroup" id="scoreBtn" (click)="showAllDetails(group)">
  <div *ngIf="group.isScoreBased || group.isStepBased">
    <div class="score-based">
      <ng-template [ngIf]="group.isScoreBased">
        <img class="score-based-img" src="../../../assets/done-24px.svg" />
      </ng-template>
      <ng-template [ngIf]="group.isStepBased">
        <img
          class="score-based-img"
          src="../../../assets/icons/step_24dp.png"
        />
      </ng-template>
      <label  class="form-check-label color-white" for="materialCheckedDisabled2"
        >{{ getLabelForCheckBox() }}
      </label>
    </div>
  </div>
  <div *ngFor="let question of group.questions" class="question">
    <ng-template [ngIf]="!group.isStepBased">
      <p class="questionText" [innerHTML]="question.text"></p>
    </ng-template>
    <ng-template [ngIf]="group.isStepBased">
      <p class="questionText" [innerHTML]="question.text"></p>
    </ng-template>
  </div>
</div>
