import { Component, SimpleChanges, OnInit, OnChanges, Input, Output, EventEmitter, OnDestroy } from "@angular/core";
import { NgForm } from "@angular/forms";
import { TreatmentService } from "projects/AdminApp/src/app/shared/service/treatment.service";
import { AngularEditorConfig } from "../../../../../angular-editor/src/public-api";
import { CustomEditorConfig } from "../customEditorConfig";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "projects/AdminApp/src/app/core/dialog/dialog.component";
import { Dialog } from "projects/AdminApp/src/app/core/dialog/dialog.model";
import { ValidationControlUtil } from "projects/AdminApp/src/app/shared/util/validation.util";
import { LanguageTextControlUtil } from '../../shared/util/language-text-control.util';
import {
  LanguageText,
  Treatment,
  MessageType,
  PersistenceAction,
  ToasterService,
  TableType, Pathway
} from "projects/shared-lib/src/public-api";
import { Subscription } from "rxjs";
import { TreatmentUtil } from "../../shared/util/treatment.util";


@Component({
  selector: "app-treatment",
  templateUrl: "./treatment.component.html",
  styleUrls: ["./treatment.component.css"],
})
export class TreatmentComponent implements OnChanges, OnDestroy {
  @Input() isUsedByTree: boolean;
  @Input() treatment: Treatment;
  @Input() pathwaySelected: Pathway;
  @Output() afterSaveComplete = new EventEmitter();
  @Output() afterCancelClicked = new EventEmitter();
  public isLoading = false;
  public selectedLanguageId: number = -1;
  public textUpdated: LanguageText = null;
  public tableType: TableType = TableType.TREATMENT;
  public config: AngularEditorConfig = new CustomEditorConfig(true, true);
  private initialTreatment: Treatment = null;
  private subscriptions: Subscription[] = [];

  constructor(
    private toaster: ToasterService,
    public dialog: MatDialog,
    private treatmentService: TreatmentService,
    public validationControlUtil: ValidationControlUtil,
    private treatmentUtil: TreatmentUtil,
    private textUtil: LanguageTextControlUtil
  ) {
    this.saveTreatmentSuccess = this.saveTreatmentSuccess.bind(this);
    this.saveTreatmentError = this.saveTreatmentError.bind(this);
  }

  public saveLanguageInformation(result: LanguageText) {
    const infoMsg = "Save this treatment to persist the changes"
    if (result !== undefined) {
      this.treatment.modelAction = PersistenceAction.UPDATE;
      const matchingText = this.textUtil.findTranslation(result.languageId, result.tableId, this.treatment.textList);
      if (matchingText == null) {
        result.modelAction = PersistenceAction.INSERT;
        this.treatment.textList.push(result);
      } else {
        matchingText.modelAction = PersistenceAction.UPDATE;
        matchingText.value = result.value;
      }
      this.textUpdated = result;
      const isNotSaveAs = !this.treatment.isSaveAs;
      if (isNotSaveAs) {
        this.showDialog(this.treatment, this.isUsedByTree);
      } else {
        this.toaster.show(MessageType.INFORMATIONAL, infoMsg);
      }
    }
    this.selectedLanguageId = -1;
  }

  private createNewDialog(isUsedByTree: boolean): Dialog {
    const title = "Updating template will update instances created by the template in the decision trees";
    const content =  "- Cancel to cancel<br/> - Continue to update template and instances<br/>";
    const option_1 = "Cancel";
    const option_2 =  "Continue";
    const option_3 = "";
    return new Dialog(title, content, option_1, option_2, option_3);
  }

  private showDialog(treatment: Treatment, isUsedByTree: boolean) {
    const isNotUsedByTree = !isUsedByTree;
    const dialog = this.createNewDialog(isUsedByTree);
    const dialogConf = { data: dialog };
    const dialogRef = this.dialog.open(DialogComponent, dialogConf);

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        if (result === false) {
          this.toaster.show(MessageType.SYSTEM_ERROR, "Not implemented.");
          return;
        }
        if (this.isUsedByTree) {
           this.saveForm(treatment);
        } else {
          this.saveForm(treatment);
        }
      }
    });
  }

  private reloadTreatment(infoMsg: string) {
    if (this.isUsedByTree && this.textUpdated != null) {
      this.isLoading = true;
      this.treatmentService.retrieveTreatmentById(this.treatment.id).subscribe((idResponse) => {
        this.isLoading = false;
        if (idResponse.operationSuccess) {
          this.treatment.updatedDate = idResponse.returnValues[0].updatedDate;
          this.treatment.textList = [...idResponse.returnValues[0].textList];
          this.afterSaveComplete.emit(true);
          this.toaster.show(MessageType.INFORMATIONAL, infoMsg);
        } else {
          this.toaster.show(idResponse.messages[0].type, idResponse.messages[0].text);
        }
      });
      this.textUpdated = null;
    }
  }

  private saveTreatmentSuccess(response: any): void {
    const infoMsg = "Treatment saved successfully";
    const errorMsg = "Something was wrong";
    this.isLoading = false;
    if (response.operationSuccess) {
      if (this.isUsedByTree && this.textUpdated !== null) {
          this.reloadTreatment(infoMsg);
      } else {
        this.afterSaveComplete.emit(true);
        this.toaster.show(MessageType.INFORMATIONAL, infoMsg);
      }
    } else if (response.messages.length > 0) {
      this.toaster.show(response.messages[0].type, response.messages[0].text);
    } else {
      this.toaster.show(MessageType.SYSTEM_ERROR, errorMsg);
    }
  }

  private saveTreatmentError(error: any): void {
    this.toaster.show(MessageType.SYSTEM_ERROR, error);
  }

  private saveForm(treatment: Treatment) {
    this.isLoading = true;
    if( treatment.modelAction === PersistenceAction.NONE)
      treatment.modelAction = PersistenceAction.UPDATE;
    this.subscriptions.push(this.treatmentService.saveTreatment(treatment).subscribe(this.saveTreatmentSuccess, this.saveTreatmentError));
  }

  private saveTreatment(treatment: Treatment, isUsedByTree: boolean): void {
    const templateTreatment = this.isTemplateTreatment(treatment?.templateTreatmentId);
    const notInsertActionFlag = this.isNotInsertActionFlag(treatment.modelAction);
    const greaterThanZero = this.isGreaterThanZero(treatment.id);
    const isNotUsedByTree = !isUsedByTree;
    if (templateTreatment && notInsertActionFlag) {
      treatment.modelAction = PersistenceAction.UPDATE;
      this.showDialog(treatment, isUsedByTree);
    } else if (isNotUsedByTree && greaterThanZero) {
      this.showDialog(treatment, isUsedByTree);
    } else {
      this.saveForm(treatment);
    }
  }

  private saveTreatmentAs(treatment: Treatment): void {
    const saveAsTreatment = this.treatmentUtil.setForSaveAs(treatment);
    this.saveForm(saveAsTreatment);
  }

  private showValidationMsg(message: string): void {
    this.toaster.show(MessageType.VALIDATION, message);
  }

  private showWarningMsg(message: string): void {
    this.toaster.show(MessageType.WARNING, message);
  }

  private isNotUndefined(prop: any): boolean {
    return prop !== undefined ? true : false;
  }

  private isDifferentFromZero(value: number): boolean {
    return value !== 0 ? true : false;
  }

  private isNotInsertActionFlag(modelAction: PersistenceAction): boolean {
    return modelAction !== PersistenceAction.INSERT;
  }

  private isTemplateTreatment(treatmentId: number): boolean {
    const notUndefined = this.isNotUndefined(treatmentId);
    const differentFromZero = this.isDifferentFromZero(treatmentId);
    return notUndefined && differentFromZero ? true : false;
  }

  private isGreaterThanZero(value: number): boolean {
    return value > 0 ? true : false;
  }

  private isSaveAsNotValid(treatmentForm: NgForm, treatment: Treatment, initialTreatment: Treatment): boolean {
    const tagMsg = "Tag must be unique";
    const isNotValid = this.isSaveNotValid(treatmentForm);
    if (isNotValid) { return true; }
    if (treatment.tag === initialTreatment.tag) {
      this.showValidationMsg(tagMsg);
      return true;
    }
    return false;
  }

  private isSaveNotValid(treatmentForm: NgForm): boolean {
    const noChangesMsg = "No changes detected";
    if (treatmentForm.invalid) {
      const message = this.validationControlUtil.getErrorMsg(treatmentForm.controls);
      this.showValidationMsg(message);
      return true;
    }
    if (!treatmentForm.dirty) {
      this.showWarningMsg(noChangesMsg);
      return true;
    }
    return false;
  }

  private isNotValidTreatment(treatmentForm: NgForm, treatment: Treatment, initialTreatment: Treatment): boolean {
   if (treatment.isSaveAs) {
     return this.isSaveAsNotValid(treatmentForm, treatment, initialTreatment);
   } else {
     return this.isSaveNotValid(treatmentForm);
   }
  }

  public onSave(treatmentForm: NgForm, treatment: Treatment) {
    const isNotValid = this.isNotValidTreatment(treatmentForm, treatment, this.initialTreatment);
    if (isNotValid) { return; }
    if (treatment.isSaveAs) {
      this.saveTreatmentAs(treatment);
    } else {
      this.saveTreatment(treatment, this.isUsedByTree);
    }
  }

  public onCancel() {
    this.afterCancelClicked.emit();
  }

  private getInitialTreatment(changes: SimpleChanges): Treatment {
    return { ...changes["treatment"].currentValue };
  }

  // update treatment obj after updating component with new data
  ngOnChanges(changes: SimpleChanges): void {
    this.initialTreatment = this.getInitialTreatment(changes);
  }

  ngOnDestroy() {
    if (this.subscriptions.length) {
      this.subscriptions.forEach((s) => {
        s.unsubscribe();
      });
    }
  }

  showSaveButton():boolean{
    if(this.treatment.isSaveAs)
      return false;
    if(this.pathwaySelected === undefined)//Template screen
      return true;
    if(this.pathwaySelected !== undefined && this.pathwaySelected.pathwayStatusId !== 4)
      return true;
    return false;
  }
}
