import { Injectable } from "@angular/core";
import {
  LanguageText,
  PersistenceAction,
  QuestionGroup,
  TableType,
  Question,
  Treatment,
  Option,
  Decision,
  Pathway,
  GuidelineLangText,
  PathwayLangText,
  Category,
  SurveyQuestion,
  QuestionOption,
} from "projects/shared-lib/src/public-api";
import { GuidelineKey } from "../constant/guideline-key.constant";
import { PathwayKey } from "../constant/pathway-key.constant";

@Injectable({ providedIn: "root" })
export class LanguageTextControlUtil {
  cloneItem(sourceItem: LanguageText): LanguageText {
    let clone = new LanguageText();
    clone.id = sourceItem.id;
    clone.key = sourceItem.key;
    clone.languageId = sourceItem.languageId;
    clone.modelAction = sourceItem.modelAction;
    clone.rowStatus = sourceItem.rowStatus;
    clone.tableId = sourceItem.tableId;
    clone.tableTypeId = sourceItem.tableTypeId;
    clone.value = sourceItem.value;
    clone.createdBy = sourceItem.createdBy;
    clone.createdDate = sourceItem.createdDate;
    clone.updatedBy = sourceItem.updatedBy;
    clone.updatedDate = sourceItem.updatedDate;
    return clone;
  }

  toNewLanguageText(
    tableTypeId: number,
    tableId: number,
    langId: number,
    key: string
  ) {
    const newLangText = new LanguageText();
    newLangText.id = 0;
    newLangText.tableTypeId = tableTypeId;
    newLangText.tableId = tableId;
    newLangText.key = key;
    newLangText.value = "";
    newLangText.languageId = langId;
    newLangText.modelAction = PersistenceAction.INSERT;
    return newLangText;
  }

  toUpdateLanguageText(langText: LanguageText) {
    langText.modelAction = PersistenceAction.UPDATE;
    return langText;
  }

  public findTranslation(
    langId: number,
    tableId: number,
    translations: LanguageText[]
  ): LanguageText {
    if (translations.length === 0) {
      return null;
    }
    const translationFound: LanguageText = translations.find(
      (translation) =>
        translation.languageId === +langId && translation.tableId === tableId
    );
    return translationFound;
  }

  public findTranslationKey(
    langId: number,
    tableId: number,
    key: string,
    translations: LanguageText[]
  ): LanguageText {
    if (translations.length === 0) {
      return null;
    }
    const translationFound: LanguageText = translations.find(
      (translation) =>
        translation.languageId === +langId &&
        translation.tableId === tableId &&
        translation.key === key
    );
    return translationFound;
  }

  getTreatmentLanguageText(
    selectedInstance: Treatment,
    langId: number
  ): LanguageText {
    let editableTreatment = this.getTextObjectBasedOnParameters(
      langId,selectedInstance.id,selectedInstance.textList,
      "text",TableType.TREATMENT
    );
    return editableTreatment;
  }

  getTextObjectBasedOnParameters(langId: number, id: number,textList: LanguageText[],
              key: string, tableTypeId: number)
  {
    let item = this.findTranslationKey(
      langId,
      id,
      key,
      textList
    );
    if(item === undefined || item == null)
    {
      return this.toNewLanguageText(
        tableTypeId,
        id,
        langId,
        key
      );
    }
    return this.toUpdateLanguageText({ ...item });
  }

  getDecisionLangText(decision: Decision, langId: number): GuidelineLangText 
  {
    const tableTypeId = TableType.DECISION;
    let nameLangText = this.getTextObjectBasedOnParameters(
      langId,decision.id,decision.textList,
      GuidelineKey.NAME,tableTypeId
    );
    let descLangText = this.getTextObjectBasedOnParameters(
      langId,decision.id,decision.textList,
      GuidelineKey.DESCRIPTION,tableTypeId
    );
    let discLangText = this.getTextObjectBasedOnParameters(
      langId,decision.id,decision.textList,
      GuidelineKey.DISCLAIMER,tableTypeId
    );
    let addInfoLangText = this.getTextObjectBasedOnParameters(
      langId,decision.id,decision.textList,
      GuidelineKey.ADD_INFO,tableTypeId
    );
    return new GuidelineLangText(
      nameLangText,
      descLangText,
      discLangText,
      addInfoLangText
    );
  }

  getPathwayLangText(pathway: Pathway, langId: number): PathwayLangText {
    const tableTypeId = TableType.PATHWAY;
    let nameLangText = this.getTextObjectBasedOnParameters(
      langId,pathway.id,pathway.textList,
      PathwayKey.NAME,tableTypeId
    );
    let descLangText = this.getTextObjectBasedOnParameters(
      langId,pathway.id,pathway.textList,
      PathwayKey.DESCRIPTION,tableTypeId
    );
    let generalLangText = this.getTextObjectBasedOnParameters(
      langId,pathway.id,pathway.textList,
      PathwayKey.GENERAL_CONSIDER,tableTypeId
    );
    let addInfoLangText = this.getTextObjectBasedOnParameters(
      langId,pathway.id,pathway.textList,
      PathwayKey.ADD_INFO,tableTypeId
    );
    return new PathwayLangText(
      nameLangText,
      descLangText,
      generalLangText,
      addInfoLangText
    );
  }

  saveLanguageTextToGroup(
    source: QuestionGroup,
    destination: QuestionGroup,
    langId: number
  ) {
    for (let i = 0; i < destination.questions.length; i++) {
      let rQuestion = destination.questions[i];
      let eQuestion = source.questions[i];
      this.populateLanguageText(
        langId,
        eQuestion,
        rQuestion.textList,
        TableType.QUESTION,
        "text"
      );
      for (let j = 0; j < rQuestion.options.length; j++) {
        let rOption = rQuestion.options[j];
        let eOption = eQuestion.options[j];
        this.populateLanguageText(
          langId,
          eOption,
          rOption.textList,
          TableType.OPTION,
          "text"
        );
      }
    }
    return destination;
  }

  saveLanguageTextToCategory(
    source: Category,
    destination: Category,
    langId: number
  ) {
    for (let i = 0; i < destination.surveyQuestions.length; i++) {
      let rQuestion = destination.surveyQuestions[i];
      let eQuestion = source.surveyQuestions[i];
      this.populateLanguageTextCategory(
        langId,
        eQuestion,
        rQuestion.textList,
        TableType.SURVEY_QUESTION,
        "text"
      );
      for (let j = 0; j < rQuestion.questionOptions.length; j++) {
        let rOption = rQuestion.questionOptions[j];
        let eOption = eQuestion.questionOptions[j];
        this.populateLanguageTextCategory(
          langId,
          eOption,
          rOption.textList,
          TableType.SURVEY_QUESTION_OPTION,
          "text"
        );
      }
    }
    return destination;
  }

  createNewGroupText(
    langId: number,
    editObject: Question | Option,
    textList: LanguageText[],
    tableTypeId: number,
    key: string
  ) {
    let textObject = new LanguageText();
    textObject.tableId = editObject.id;
    textObject.value = editObject.text;
    textObject.languageId = langId;
    textObject.key = key;
    textObject.tableTypeId = tableTypeId;
    textObject.modelAction = PersistenceAction.INSERT;
    textList.push(textObject);
  }

  createNewGroupTextCategory(
    langId: number,
    editObject: SurveyQuestion | QuestionOption,
    textList: LanguageText[],
    tableTypeId: number,
    key: string
  ) {
    let textObject = new LanguageText();
    textObject.tableId = editObject.id;
    textObject.value = editObject.description;
    textObject.languageId = langId;
    textObject.key = key;
    textObject.tableTypeId = tableTypeId;
    textObject.modelAction = PersistenceAction.INSERT;
    textList.push(textObject);
  }

  populateLanguageTextCategory(
    langId: number,
    editObject: SurveyQuestion | QuestionOption,
    textList: LanguageText[],
    tableTypeId: number,
    key: string
  ) {
    if (textList.length === 0) {
      this.createNewGroupTextCategory(langId, editObject, textList, tableTypeId, key);
      return;
    }
    const translationFound: LanguageText = textList.find(
      (translation) =>
        translation.languageId === +langId &&
        translation.tableId === editObject.id
    );
    if (translationFound != undefined && translationFound != null) {
      translationFound.value = editObject.description;
      translationFound.modelAction = PersistenceAction.UPDATE;
    } else {
      this.createNewGroupTextCategory(langId, editObject, textList, tableTypeId, key);
    }
  }

  populateLanguageText(
    langId: number,
    editObject: Question | Option,
    textList: LanguageText[],
    tableTypeId: number,
    key: string
  ) {
    if (textList.length === 0) {
      this.createNewGroupText(langId, editObject, textList, tableTypeId, key);
      return;
    }
    const translationFound: LanguageText = textList.find(
      (translation) =>
        translation.languageId === +langId &&
        translation.tableId === editObject.id
    );
    if (translationFound != undefined && translationFound != null) {
      translationFound.value = editObject.text;
      translationFound.modelAction = PersistenceAction.UPDATE;
    } else {
      this.createNewGroupText(langId, editObject, textList, tableTypeId, key);
    }
  }

  copyLanguageText(source: LanguageText[], destination: LanguageText[]) {
    if (source.length > 0) {
      source.forEach((sourceItem) => {
        let cloned = this.cloneItem(sourceItem);
        destination.push(cloned);
      });
    }
  }

  markTextItemToDelete(items: LanguageText[]) {
    items.forEach((item) => {
      item.modelAction = PersistenceAction.DELETE;
    });
  }

  guidelineLangToTextList(guidelineLang: GuidelineLangText): LanguageText[] {
    const langTextList: LanguageText[] = [];
    for (const [key, value] of Object.entries(guidelineLang)) {
      langTextList.push(value);
    }
    return langTextList;
  }

  pathwayLangToTextList(pathwayLang: PathwayLangText): LanguageText[] {
    const langTextList: LanguageText[] = [];
    for (const [key, value] of Object.entries(pathwayLang)) {
      langTextList.push(value);
    }
    return langTextList;
  }
}
