import { Injectable } from "@angular/core";
import { BaseClass } from "shared-lib";


@Injectable({ providedIn: "root" })
export class BaseClassUtil {
    copyAllAttributes(source: BaseClass, destination: BaseClass){
        destination.rowStatus = source.rowStatus;
        destination.displayOrder = +source.displayOrder;
        destination.createdBy = source.createdBy;
        destination.createdDate = source.createdDate;
        destination.updatedBy = source.updatedBy;
        destination.updatedDate = source.updatedDate;
        destination.tag = source.tag;
    }
}
