<div class="d-flex flex-row">
    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 scroll-panel">
      <div class="scroll-panel">
        <br />
        <div class="btn-toolbar justify-content-between" role="toolbar">
          <div class="btn-group color-dark" role="group">
            <label>Languages</label>
          </div>
          <div class="btn-toolbar" role="group">
            <button
              id="add_new_language_btn"
              class="btn btn-dark btn-sm"
              type="button"
              (click)="onNewLanguage()"
            >
              <img src="../../../assets/add-24px.svg" />
            </button>
          </div>
        </div>
        <br/>
        <div class="text-left text-secondary">
          <ng-container
            *ngIf="languageService.languages.length === 0;
              then emptyList;
              else languageList"
          ></ng-container>
          <ng-template #emptyList>
            <div class="text-center text-secondary">
              <label id="detail_no_languages">No items found</label>
            </div>
          </ng-template>
          <ng-template #languageList>
            <div
              *ngFor="
                let l of languageService.languages;
                let di = index
              "
            >
              <div class="language-box bg-dark">
                <div>
                  <div class="language-content color-white">
                    <div class="btn-toolbar justify-content-between" role="toolbar">
                      <div class="btn-group color-white" role="group"></div>
                      <div class="btn-toolbar" role="group">
                        <button
                          class="btn btn-light btn-sm"
                          id="{{ l.languageDesc }}"
                          type="button"
                          (click)="onUpdate(l)"
                        >
                          <img src="../../../assets/edit-24px.svg" />
                        </button>
                      </div>
                    </div>
                    <label
                      class="label font-weight-bold text-uppercase"
                      >{{ l.languageDesc }}</label
                    >
                  </div>
                  <br />
                </div>
              </div>
            </div>
            <br />
          </ng-template>
        </div>
      </div>
    </div>
    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
      <ng-template [ngIf]="currentItem != null">
        <app-language-edit [currentItem] = "currentItem"
          (onWindowClose) = "loadLanguages()">

        </app-language-edit>
      </ng-template>
    </div>
  </div>
