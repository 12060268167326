import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { ServiceUtil } from "projects/AdminApp/src/app/shared/util/service-util";
import { InquiryResponse, Pathway } from "projects/shared-lib/src/public-api";
import { PathwayStatusEnum } from '../constant/pathway-status-enum.constant';
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class PathwayService {
  private pathwayUrl = `${this.serviceUtil.rootUrl}/pathway`;

  constructor(private serviceUtil: ServiceUtil, 
              private http: HttpClient) {
    this.serviceUtil = serviceUtil;
    this.http = http;
  }

  savePathway(data: Pathway,isSaveTree: boolean = false) {
    let url = `${this.pathwayUrl}/save`;
    if(isSaveTree)
      url = `${this.pathwayUrl}/SaveQuestionTree`;
    return this.http
      .post<InquiryResponse<any>>(
        url,
        data,
        this.serviceUtil.getCommonAdminHeadersWithAuth()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.serviceUtil.handleResponse)
      );
  }

  public copyPathway(data: Pathway) {
    let url = `${this.pathwayUrl}/CopyPublishedPathway?id=`;
    url += data.id;
    return this.http
      .post<InquiryResponse<Pathway>>(
        url,
        null,
        this.serviceUtil.getCommonAdminHeadersWithAuth()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.serviceUtil.handleResponse)
      );
  }

  getPathwayStatus(p: Pathway)
  {
      if(p.pathwayStatusId === 1)
        return PathwayStatusEnum.IN_DRAFT;
      else if(p.pathwayStatusId === 2)
        return PathwayStatusEnum.READY_FOR_REVIEW;
      else if(p.pathwayStatusId === 3)
        return PathwayStatusEnum.REVIEWED_UNPUBLISHED;
      return PathwayStatusEnum.PUBLISHED;
  }

  fetchTreeShareWithPathways(startingQuestionGroupId: number){
    let url = `${this.pathwayUrl}/fetchTreeShareWithPathways`;
    return this.http
      .post<InquiryResponse<Pathway>>(
        url,
        startingQuestionGroupId,
        this.serviceUtil.getCommonAdminHeadersWithAuth()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.serviceUtil.handleResponse)
      );
  }

  fetchCanShareWithPathways(){
    let url = `${this.pathwayUrl}/fetchCanShareWithPathways`;
    return this.http
      .post<InquiryResponse<Pathway>>(
        url,
        null,
        this.serviceUtil.getCommonAdminHeadersWithAuth()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.serviceUtil.handleResponse)
      );
  }

  fetchWithNoRelatedObjects(){
    let url = `${this.pathwayUrl}/FetchWithNoRelatedObjects`;
    return this.http
      .post<InquiryResponse<Pathway>>(
        url,
        null,
        this.serviceUtil.getCommonAdminHeadersWithAuth()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.serviceUtil.handleResponse)
      );
  }

  fetchAll(): Observable<InquiryResponse<Pathway>> {
    let url = `${this.pathwayUrl}/fetch`;
    return this.http
      .post<InquiryResponse<Pathway>>(
        url,
        null,
        this.serviceUtil.getCommonAdminHeadersWithAuth()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.serviceUtil.handleResponse)
      );
  }
}
