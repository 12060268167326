import { Injectable } from '@angular/core';
import { Edge } from '@swimlane/ngx-graph';
import { QuestionGroup, ScoreToNextObject, Option, Treatment } 
        from 'projects/shared-lib/src/public-api';
import { GraphNode } from './graphnode';


@Injectable({ providedIn: "root" })
export class GraphNodeData
{
    show: boolean;
    childNodes: Map<string,GraphNode>;
    childEdges: Map<string,Map<string,Edge>>;
    parentNode: GraphNode;
    treatment?: Treatment;
    option?: Option;
    group?: QuestionGroup;
    score?: ScoreToNextObject;
    
    constructor()
    {
        this.show = true;
        this.childNodes = new Map<string,GraphNode>();
        this.childEdges =new Map<string,Map<string,Edge>>();
        this.parentNode =  null;
        this.treatment = null;
        this.option = null;
        this.group = null;
        this.score = null;
    }

    updateShowFlag()
    {
        if(this.isGroupNode())
            this.group.show = this.show;
        else if(this.isTreatmentNode())
            this.treatment.show = this.show;
    }

    toggleShow()
    {
        this.show = !this.show;
        this.updateShowFlag();
        this.childNodes.forEach(childNode => {
            if(!this.show)
                childNode.data.setChildrenInvisible();
        });
    }

    setChildrenInvisible(){
        this.show = false;
        this.childNodes.forEach(childNode => {
            childNode.data.setChildrenInvisible();
            childNode.data.updateShowFlag();
        });
    }

    get showText()
    {
        if(this.show)
            return "-";
        return "+";
    }

    isGroupNode(): boolean {
        return this.group != null && this.group != undefined;
    }

    isOptionNode(): boolean {
        return this.option != null && this.option != undefined;
    }

    isScoreNode(): boolean {
        return this.score != null && this.score != undefined;
    }

    isTreatmentNode(): boolean {
        return this.treatment != null && this.treatment != undefined;
    }

    getQuestionGroup(): QuestionGroup
    {
        if(this.isGroupNode())
            return this.group;
        if(this.isTreatmentNode())
            return null;
        return this.parentNode.data.group;
    }
}