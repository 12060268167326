import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "showLangToolbar" })
export class ShowLangToolbarPipe implements PipeTransform {
  constructor() {}
  private showLangToolbar(id: number): boolean {
    return id > 0 ? true : false;
  }

  transform(id: number): boolean {
    return this.showLangToolbar(id);
  }
}
