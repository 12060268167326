import {
  Decision,
  GuidelineLangText,
} from "../../../../../shared-lib/src/public-api";

export class GuidelineLang {
  constructor(
    public language: string,
    public readable: Decision,
    public editable: GuidelineLangText
  ) {}
}
