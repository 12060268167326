<div class="color-dark">
<div class="col-12">
    <div class="decision-content color-white">
      <br />
    <div class="row">
        <div class="col-7">
            <label>First Name *</label>
            <br />
            <input
            id="register_user_first_name_input"
            class="form-control"
            name="firstname"
            placeholder="First Name"
            [(ngModel)]="user.firstName"
            required
            />
        </div>
    </div>
    <div class="row">
        <div class="col-7">
            <label>Last Name *</label>
            <br />
            <input
            id="register_user_last_name_input"
            class="form-control"
            name="lastname"
            placeholder="Last Name"
            [(ngModel)]="user.lastName"
            required
            />
        </div>
    </div>
    <div class="row">
        <div class="col-7">
            <label>Username*</label>
            <br />
            <input
            id="register_user_user_name_input"
            class="form-control"
            name="userName"
            placeholder="User Name"
            [(ngModel)]="user.userName"
            required
            />
        </div>
    </div>
    <div class="row">
        <div class="col-7">
            <label>Password *</label>
            <br />
            <input type="password"
            id="register_user_password_input"
            class="form-control"
            name="password"
            placeholder="Password"
            [(ngModel)]="user.password"
            required
            />
        </div>
    </div>
    <div class="row">
        <div class="col-7">
            <label>Confirm Password *</label>
            <br />
            <input type="password"
            id="register_user_confirm_password_input"
            class="form-control"
            name="confirmPassword"
            placeholder="Confirm Password"
            [(ngModel)]="user.confirmPassword"
            required
            />
        </div>
    </div>
    <div  class="row">
        <button
              id="submit_btn"
              type="submit"
              class="btn btn-dark"
              (click)="register()">
              Save
        </button>
    </div>
</div>
</div>
</div>
