import { ControlContainer, NgForm } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { QuestionGroup, Question } from 'projects/shared-lib/src/public-api';
import { ValidationControlUtil } from 'projects/AdminApp/src/app/shared/util/validation.util';
import { AngularEditorConfig } from '../../../../../../angular-editor/src/public-api';
import { CustomEditorConfig } from '../../customEditorConfig';
import { QuestionGroupUtil } from '../../../shared/util/question-group.util';

@Component({
  selector: 'app-group-lang-panel',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: NgForm
    }
  ],
  templateUrl: './group-lang-panel.component.html',
  styleUrls: ['./group-lang-panel.component.css']
})
export class GroupLangPanelComponent implements OnInit {
  @Input() questionGroup: QuestionGroup;
  @Input() readOnly: boolean;

  //For create unique name for Panels.
  getQuestionGroup()
  {
    return this.readOnly ? 1 : 2;
  }

  config: AngularEditorConfig = new CustomEditorConfig(true, true);
  constructor(public validationControlUtil: ValidationControlUtil,
              private questionGroupUtility: QuestionGroupUtil) { }

  ngOnInit(): void {
  }

  getReadOnlyClass(): string{
    if(this.readOnly)
      return "noEvents";
    return "";
  }

  getDisplayText(question: Question): string {
    if (question && question.text) {
      return this.questionGroupUtility.getDropdownText(question);
    }
    return "";
  }
}
