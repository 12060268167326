import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../shared/service/language.service';
import { Language } from 'projects/shared-lib/src/public-api';
import { LanguageUtility } from '../../shared/util/language.utility';
import { UserAppTextService } from '../../shared/service/user.app.text.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']
})
export class LanguageComponent implements OnInit {
  currentItem: Language = null;
  constructor(public languageService: LanguageService,
    private languageUtility: LanguageUtility,
    private userAppTextService: UserAppTextService) { }

  ngOnInit(): void {
    this.loadLanguages();
    this.userAppTextService.getUserAppText();
  }

  loadLanguages()
  {
    this.currentItem = null;
    this.languageService.languages.length = 0;
    this.languageService.initLanguages();
  }

  onNewLanguage()
  {
    this.currentItem = this.languageUtility.createNewItem();
  }

  onUpdate(l: Language)
  {
    this.languageUtility.updateText(l);
    this.currentItem = l;
  }
}
