import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "filterByColumnType" })
export class FilterByColumnTypePipe implements PipeTransform {

  private filterByColumnType(elements: any[], value: string) {
    return elements;
  }

  transform(elements: any[], value: string) {
    return this.filterByColumnType(elements, value);
  }
}
