import { Injectable } from '@angular/core';
import { Language, PersistenceAction } from 'projects/shared-lib/src/public-api';
import { UserAppTextToLanguageMap } from 'projects/shared-lib/src/lib/model/user-app-text.model';
import { UserAppTextService } from '../service/user.app.text.service';

@Injectable({ providedIn: "root" })
export class LanguageUtility {
    constructor(private service: UserAppTextService)
    {

    }
    createNewItem(): Language
    {
        let nextId : number = -1;
        let language = new Language();
        language.modelAction = PersistenceAction.INSERT;
        language.id = nextId--;
        this.copyAllTextElements(language);
        return language;
    }

    updateText(l: Language){
        if(l.userAppTextItems.length === 0)
        {
            this.copyAllTextElements(l);
            return;
        }
    }

    copyAllTextElements(l: Language){
        let nextId: number = -1;
        this.service.userAppTextArray.forEach(item=>{
            let instance = new UserAppTextToLanguageMap();
            instance.id = nextId--;
            instance.modelAction = PersistenceAction.INSERT;
            instance.languageId = l.id;
            instance.userAppTextId = item.id;
            instance.appText = item.text;
            instance.text = "";
            l.userAppTextItems.push(instance);
        });
    }
}