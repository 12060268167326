import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";

import { NotFoundRoutingModule } from "./not-found.routing.module";

@NgModule({
  imports: [SharedModule],
  exports: [NotFoundRoutingModule],
  declarations: [NotFoundRoutingModule.components],
  providers: []
})
export class NotFoundModule {}
