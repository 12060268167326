<div class="pathway-lang-container">
  <div class="pathway-header color-black">English(Read Only)</div>
  <div class="pathway-header color-black">{{ languageDesc }}</div>
  <div class="pathway-section text-left bg-primary">
    <label class="color-white">Name</label>
    <mat-card class="pathway-content">
      <mat-card-content>
        <div [innerHTML]="readablePathway.name | sanitizer: 'html'"></div>
      </mat-card-content>
    </mat-card>
    <br />
    <label class="color-white">Description</label>
    <mat-card class="pathway-content">
      <mat-card-content>
        <div
          [innerHTML]="readablePathway.description | sanitizer: 'html'"
        ></div>
      </mat-card-content>
    </mat-card>
    <br />
    <label class="color-white">General consideration</label>
    <mat-card class="pathway-content">
      <mat-card-content>
        <div
          [innerHTML]="readablePathway.generalConsideration | sanitizer: 'html'"
        ></div>
      </mat-card-content>
    </mat-card>
    <br />
    <label class="color-white">Additional information</label>
    <mat-card class="pathway-content">
      <mat-card-content>
        <div
          [innerHTML]="
            readablePathway.additionalInformation | sanitizer: 'html'
          "
        ></div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="pathway-section text-left bg-primary">
    <form (ngSubmit)="onSave(form)" #form="ngForm" name="form">
      <mat-dialog-actions>
        <div class="col-12">
          <div class="btn-toolbar justify-content-between" role="toolbar">
            <div class="btn-group" role="group"></div>
            <div class="btn-toolbar" role="group">
              <button
                type="button"
                class="btn btn-primary"
                (click)="onCancel()"
              >
                Cancel
              </button>
              &nbsp;&nbsp;
              <button class="btn btn-white" cdkFocusInitial type="submit">
                Save
              </button>
            </div>
          </div>
          <br />
        </div>
      </mat-dialog-actions>
      <div class="editor color-white">
        <div class="row">
          <div class="col-sm-12">
            <label>Name *</label>
            <br />
            <input
              class="form-control"
              id="editable_lang_pathway_name"
              name="editable_lang_pathway_name"
              placeholder="Name"
              [(ngModel)]="editablePathway.name.value"
              maxlength="{{ validationControlUtil.pathwayNameLength }}"
              required
              #name="ngModel"
            />
          </div>
          <div class="col-sm-12" *ngIf="submitted && form.invalid">
            <p *ngIf="name.errors.required">
              This field is required
            </p>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-sm-12">
            <label>Description *</label>
          </div>
        </div>
        <div class="color-dark bg-white">
          <angular-editor
            id="editable_lang_pathway_desc"
            name="editable_lang_pathway_desc"
            [(ngModel)]="editablePathway.description.value"
            [config]="config"
            required
            #description="ngModel"
            (errorMsg)="validationControlUtil.showError($event)"
          >
          </angular-editor>
        </div>
        <div *ngIf="editablePathway && editablePathway?.description && editablePathway.description.value" class="col-12 text-right pt-1">
          <label>Characters count: {{ editablePathway.description.value?.length }}</label>
        </div>
        <div class="row">
          <div class="col-sm-12" *ngIf="submitted && form.invalid">
            <div *ngIf="description.errors.required">
              This field is required
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-sm-12">
            <label>General consideration *</label>
          </div>
        </div>
        <div class="color-dark bg-white">
          <angular-editor
            id="editable_lang_pathway_general"
            name="editable_lang_pathway_general"
            [(ngModel)]="editablePathway.generalConsideration.value"
            [config]="config"
            required
            #generalConsider="ngModel"
            (errorMsg)="validationControlUtil.showError($event)"
          >
          </angular-editor>
        </div>
        <div *ngIf="editablePathway && editablePathway?.generalConsideration && editablePathway.generalConsideration.value" class="col-12 text-right pt-1">
          <label>Characters count: {{ editablePathway.generalConsideration.value?.length }}</label>
        </div>
        <div class="row">
          <div class="col-sm-12" *ngIf="submitted && form.invalid">
            <div *ngIf="generalConsider.errors.required">
              This field is required
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-sm-12">
            <label>Additional information</label>
          </div>
        </div>
        <div class="color-dark bg-white">
          <angular-editor
            id="editable_lang_pathway_add_info"
            name="editable_lang_pathway_add_info"
            [(ngModel)]="editablePathway.addInformation.value"
            [config]="config"
            maxlength="{{ validationControlUtil.additionalInfoTextLength }}"
            (errorMsg)="validationControlUtil.showError($event)"
          >
          </angular-editor>
        </div>
        <div *ngIf="editablePathway && editablePathway?.addInformation && editablePathway.addInformation.value" class="col-12 text-right pt-1">
          <label>Characters count: {{ editablePathway.addInformation.value?.length }}</label>
        </div>
        <br />
      </div>
    </form>
  </div>
</div>
