<div class="text-dark" *ngIf="isTemplate">
    <br />
    <label>Questions</label>
  </div>
  <div class="text-secondary">
    <br />
    <ng-template [ngIf]="isTemplate">
    <button
      id="add_new_qs_group_template_btn"
      class="btn btn-secondary btn-block"
      type="button"
      (click)="addNewQuestionGroup()"
    >
      Add Question
    </button>

  </ng-template>
  <hr />
    <div class="form-group has-search">
      <span class="form-control-feedback" aria-hidden="true">
        <img src="../../../assets/search-24px.svg" />
      </span>
      <input
        class="form-control flat-input no-outline"
        type="text"
        id="search-question"
        placeholder="Search Question"
        [(ngModel)]="groupFilterString"
        (input)="onQuestionSearch()"
      />
      <br />
    </div>
  </div>
  <div
    *ngIf="filteredGroups.length === 0"
    class="text-center text-secondary"
  >
    <label>No items found </label>
  </div>
  <div
    #questionGroupList="cdkDropList"
    cdkDropList
    [cdkDropListData]="filteredGroups"
    *ngFor="let group of filteredGroups"
  >
    <div cdkDrag class="question-group bg-dark" [cdkDragData]="group" (cdkDragStarted)="dragStarted($event)"
    (cdkDragEnded)="dragEnded($event)">
    <div class="row ml-1">
      <div cdkDragHandle>
        <img
          class="box-handle"
          src="../../../assets/drag_indicator-24px.svg"
        />
      </div>
      <div class="col-sm-4 col-4"></div>
      <div class="btn-toolbar mt-2" *ngIf="isTemplate">
        <button class="btn btn-light btn-sm ml-2" type="button" (click)="onNewQsGroupAs(group)">
          <img id="copyQuestion" class="delete-icon" src="../../../assets/content_copy-black-24dp.svg" />
        </button>
        <button idclass="btn btn-light btn-sm ml-2" type="button" (click)="editQuestionGroup(getGroup(group))">
          <img id="editQuestion" class="delete-icon" src="../../../assets/edit-24px.svg" />
        </button>
        <button class="btn btn-light btn-sm ml-2 delete-icon-right" type="button" (click)="onDeleteGroup(group)">
          <img id="deleteQuestion" class="delete-icon" src="../../../assets/delete.svg" />
        </button>
      </div>
    </div>
      <app-questiongroup
        [group]="getGroup(group)"
      ></app-questiongroup>
    </div>
    <br />
  </div>
