import { Component, OnInit, ViewChild } from "@angular/core";
import {
  QuestionGroup, InquiryResponse,
  Treatment,
  RelatedObject, SpinnerService, PersistenceAction
} from "projects/shared-lib/src/public-api";
import { QuestionGroupService } from "projects/AdminApp/src/app/shared/service/questiongroup.service";
import { TreatmentService } from "projects/AdminApp/src/app/shared/service/treatment.service";
import { TreatmentTemplatePanelComponent } from "./treatment-template-panel/treatment-template-panel.component";
import { QuestionTemplatePanelComponent } from "./question-template-panel/question-template-panel.component";
import { RelatedObjectUtility } from "projects/AdminApp/src/app/shared/util/related-object-utility";
import { LanguageService } from "projects/AdminApp/src/app/shared/service/language.service";

@Component({
  selector: "app-panel",
  templateUrl: "./panel.component.html",
  styleUrls: ["./panel.component.css"],
})
export class PanelComponent implements OnInit {
  groups: QuestionGroup[];
  treatments: Treatment[];
  selectedObject: QuestionGroup | Treatment;
  canAssociateObjects: RelatedObject[] = [];
  filteredGroups: RelatedObject[] = [];
  filteredTreatments: RelatedObject[] = [];
  groupFilterString: string = "";
  isTemplate: boolean = true;

  @ViewChild(TreatmentTemplatePanelComponent, { static: false })
  treatmentChild: TreatmentTemplatePanelComponent;
  @ViewChild(QuestionTemplatePanelComponent, { static: false })
  questionGroupChild: QuestionTemplatePanelComponent;

  constructor(
    private questionGroupService: QuestionGroupService,
    private treatmentService: TreatmentService,
    public languageService: LanguageService,
    private relatedObjectUtility: RelatedObjectUtility,
    private spinnerService: SpinnerService
  ) {
    this.selectedObject = null;
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
    this.onTreatmentSuccess = this.onTreatmentSuccess.bind(this);
  }

  private onSuccess(response: InquiryResponse<QuestionGroup>) {
    // type
    this.spinnerService.hide();
    if (response.operationSuccess) {
      this.groups = response.returnValues;
      this.questionGroupChild.groups = this.groups;
      this.questionGroupChild.onQuestionSearch();
      this.questionGroupChild.canAssociateObjects = this.relatedObjectUtility.combineRelatedObjectArrays(
        this.filteredGroups,
        this.filteredTreatments
      );
      this.canAssociateObjects = this.questionGroupChild.canAssociateObjects;
      this.relatedObjectUtility.populateRelatedObjectsOnGroups(
        this.groups,
        this.canAssociateObjects
      );
      this.questionGroupChild.filteredGroups = this.filteredGroups;
    }
  }

  private onTreatmentSuccess(response: InquiryResponse<Treatment>) {
    // type
    if (response.operationSuccess) {
      this.treatments = response.returnValues;
      this.treatmentChild.treatments = this.treatments;
      this.treatmentChild.onTreatmentSearch();
      this.treatmentChild.canAssociateObjects = this.relatedObjectUtility.combineRelatedObjectArrays(
        this.filteredGroups,
        this.filteredTreatments
      );
      this.canAssociateObjects = this.treatmentChild.canAssociateObjects;
      this.treatmentChild.filteredTreatments = this.filteredTreatments;
      
    }
    this.spinnerService.hide();
  }

  private onError(error) {
    this.spinnerService.hide();
  }

  ngOnInit() {
    this.retieveQuestionGroups();
    this.languageService.getLanguages();
  }

  retieveQuestionGroups(keepPanel = false) {
    // Get treatments
    this.retieveTreatments(keepPanel);

    // Get questions groups
    this.spinnerService.show();
    let instObs = this.questionGroupService.retrieveQuestionGroups();
    instObs.subscribe(response => {
      this.spinnerService.hide();
    if (response.operationSuccess) {
      this.groups = response.returnValues;
      this.questionGroupChild.groups = this.groups;
      this.questionGroupChild.onQuestionSearch();
      this.questionGroupChild.canAssociateObjects = this.relatedObjectUtility.combineRelatedObjectArrays(
        this.filteredGroups,
        this.filteredTreatments
      );
      this.canAssociateObjects = this.questionGroupChild.canAssociateObjects;
      this.relatedObjectUtility.populateRelatedObjectsOnGroups(
        this.groups,
        this.canAssociateObjects
      );
      this.questionGroupChild.filteredGroups = this.filteredGroups;
      if (this.selectedObject != undefined && this.selectedObject != null && keepPanel && this.isSelectedObjectQuestionGroup()){
        this.selectedObject = this.groups.find(g => g.id == this.selectedObject.id);
        if(this.selectedObject) this.selectedObject.modelAction = PersistenceAction.UPDATE
      }
    }
    }, this.onError);
    if (this.selectedObject != undefined && this.selectedObject != null && !keepPanel)
      this.selectedObject = null;
  }

  retieveTreatments(keepPanel = false){
    this.spinnerService.show();
    let instTreatments = this.treatmentService.retrieveTreatments();
    instTreatments.subscribe(response => {
    if (response.operationSuccess) {
      this.treatments = response.returnValues;
      this.treatmentChild.treatments = this.treatments;
      this.treatmentChild.onTreatmentSearch();
      this.treatmentChild.canAssociateObjects = this.relatedObjectUtility.combineRelatedObjectArrays(
        this.filteredGroups,
        this.filteredTreatments
      );
      this.canAssociateObjects = this.treatmentChild.canAssociateObjects;
      this.treatmentChild.filteredTreatments = this.filteredTreatments;
      
      if (this.selectedObject != undefined && this.selectedObject != null && keepPanel && this.isSelectedObjectTreatment()){
        this.selectedObject = this.treatments.find(t => t.id == this.selectedObject.id);
        if(this.selectedObject) this.selectedObject.modelAction = PersistenceAction.UPDATE
      }
      else {
        this.selectedObject=null;   
      }  
    }
    this.spinnerService.hide();
    }, this.onError);
  }

  isSelectedObjectQuestionGroup(): boolean {
    if (this.selectedObject != null) {
      return "questions" in this.selectedObject;
    }
    return false;
  }

  isSelectedObjectTreatment(): boolean {
    if (this.selectedObject != null) {
      return !("questions" in this.selectedObject);
    }
    return false;
  }

  updateSelectedObject(event) {
    this.selectedObject = {...event};
  }

  populateAssociateObjectsArray() {
    this.relatedObjectUtility.combineRelatedObjectArrays(
      this.filteredGroups,
      this.filteredTreatments
    );
  }
}
