import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InquiryResponse } from 'projects/shared-lib/src/public-api';
import { tap, catchError } from 'rxjs/operators';
import { ServiceUtil } from '../util/service-util';

@Injectable({ providedIn: "root" })
export class ExportDataService {
    private exportStudentUrlByDates = `${this.serviceUtil.rootUrl}/exportSurveyData/ExportStudentData`;
    private exportSurveyUrlByDates = `${this.serviceUtil.rootUrl}/exportSurveyData/ExportSurveyData`;
    private usageUrlByDates = `${this.serviceUtil.rootUrl}/exportUsageData/ExportDataByDates`;
    private usageUrl = `${this.serviceUtil.rootUrl}/exportUsageData/ExportData`;
    private exportStudentUrl = `${this.serviceUtil.rootUrl}/exportSurveyData/ExportEduFamilyData`;
    private exportSurveysUrl = `${this.serviceUtil.rootUrl}/exportSurveyData/ExportData`;
    private exportUrl = `${this.serviceUtil.rootUrl}/export/GetDataByUserType`;
    private importUrl = `${this.serviceUtil.rootUrl}/import/ImportUserTypeGuidelines`;

    constructor(
        private serviceUtil: ServiceUtil,
        private http: HttpClient
      ) {}

      downloadData(startDate: string,endDate: string, fileFormat: string,entityType: string, daysOld: number)
      {
        let url: string = this.exportStudentUrlByDates;
        let dateParamaters = "?fromDate=" + startDate + "&toDate=" + endDate + "&outputFormat=" + fileFormat;
        let daysParameters = "?lastNDays=" + daysOld.toString() + "&outputFormat=" + fileFormat;
        let selectedParams = dateParamaters;
        if(entityType == "usage")
        {
          if(+daysOld !== 0)
          {
            url = this.usageUrl;
            selectedParams = daysParameters;
          }
          else
            url = this.usageUrlByDates;
        }
        else if(daysOld != 0)
        {
          url = this.exportStudentUrl;
          selectedParams = daysParameters;
        }
        return this.http
        .get<InquiryResponse<string>>(
          url + selectedParams,
          this.serviceUtil.getCommonAdminHeadersWithAuth()
        )
        .pipe(
          catchError(this.serviceUtil.handleError),
          tap(this.serviceUtil.handleResponse)
        );
      }

      downloadSurveyData(categoryId: number, startDate: string,endDate: string, fileFormat: string, daysOld: number) {
        
        let url: string = this.exportSurveyUrlByDates;
        let dateParamaters = "?fromDate=" + startDate + "&toDate=" + endDate + "&outputFormat=" + fileFormat + "&userType=" + categoryId;
        let daysParameters = "?userType=" + categoryId + "&lastNDays=" + daysOld.toString() + "&outputFormat=" + fileFormat;
        let selectedParams = dateParamaters;
        if(daysOld != 0)
        {
          url = this.exportSurveysUrl;
          selectedParams = daysParameters;
        }
        return this.http
        .get<InquiryResponse<string>>(
          url + selectedParams,
          this.serviceUtil.getCommonAdminHeadersWithAuth()
        )
        .pipe(
          catchError(this.serviceUtil.handleError),
          tap(this.serviceUtil.handleResponse)
        );
      }

      downloadUserType(data: any) {
        return this.http
        .post<InquiryResponse<any>>(
          this.exportUrl,
          data,
          this.serviceUtil.getCommonAdminHeadersWithAuth()
        )
        .pipe(
          catchError(this.serviceUtil.handleError),
          tap(this.serviceUtil.handleResponse)
        );
      }

      importUserType(data: any) {
        return this.http
        .post<InquiryResponse<any>>(
          this.importUrl,
          data,
          this.serviceUtil.getCommonAdminHeadersWithAuth()
        )
        .pipe(
          catchError(this.serviceUtil.handleError),
          tap(this.serviceUtil.handleResponse)
        );
      }


}