<div class="text-left">
    <div class="color-dark">
      <br />
      <label>Language</label>
      <br />
      <form (ngSubmit)="onSave(form)" #form="ngForm" name="form">
        <div class="decision-box bg-dark">
          <br />
          <div class="toolbar-container">
            <div class="col-12">
              <div class="btn-toolbar justify-content-between" role="toolbar">
                  <div class="btn-group color-white" role="group"></div>
                  <div class="btn-toolbar " role="group">
                  <button
                      id="cancel_btn" type="button"
                      class="btn btn-dark" (click)="onCancel()">
                      Cancel</button>
                  <button
                      id="save_btn"
                      type="submit" class="btn btn-white">Save</button>
                  </div>
              </div>
              </div>
          </div>
          <br/>
          <div class="col-12">
            <div class="decision-content color-white">
              <div class="row">
                <div class="col-3">
                  <label>Code *</label>
                  <br />
                  <input
                    id="language_code_input"
                    class="form-control"
                    name="code"
                    placeholder="Code"
                    [(ngModel)]="currentItem.languageCode"
                    required
                    maxlength="{{ validationControlUtil.languageCodeLength }}"
                  />
                </div>
                <div class="col-9">
                  <label>Description *</label>
                  <br/>
                  <input
                    id="language_desc_input"
                    class="form-control"
                    name="description"
                    placeholder="description"
                    [(ngModel)]="currentItem.languageDesc"
                    required
                    maxlength="{{ validationControlUtil.languageDescriptionLength }}"
                  />
                </div>
              </div>
              <br />
            </div>
          </div>
          <hr/>
          <ng-template [ngIf]="currentItem.userAppTextItems.length !=0">
          <div *ngFor="let textItem of currentItem.userAppTextItems">
            <div class="row color-white app-text-row">
              <div class="col-3">
                <label class="app-text-label-right">{{textItem.appText}} *</label>
              </div>
              <div class="col-9 app-text-input">
                <input
                    class="form-control"
                    name="{{textObjectPrefix}}{{textItem.id}}{{textItem.userAppTextId}}"
                    id="{{textObjectPrefix}}{{textItem.id}}{{textItem.userAppTextId}}"
                    placeholder="Text"
                    [(ngModel)]="textItem.text"
                    required
                    maxlength="{{ validationControlUtil.appTextLength }}"
                  />
              </div>
              <hr/>
            </div>
          </div>
          </ng-template>
        </div>
      </form>
    </div>
  </div>
