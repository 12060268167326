import { NgModule } from "@angular/core";

import { DialogComponent } from "./dialog.component";
import { SharedModule } from "../../shared/shared.module";
import { SharedLibraryModule } from 'projects/shared-lib/src/shared-lib.module'
@NgModule({
  imports: [SharedLibraryModule,SharedModule],
  exports: [DialogComponent],
  declarations: [DialogComponent],
  entryComponents: [DialogComponent],
  providers: []
})
export class DialogModule {}
