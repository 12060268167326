<div class="col-12">
  <h2 mat-dialog-title>{{ data.title }}</h2>
</div>
<mat-dialog-content class="mat-typography">
  <div class="col-12">
    <h3 [innerHTML]="data.content | sanitizer: 'html'"></h3>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="col-12">
    <div class="btn-toolbar justify-content-between" role="toolbar">
      <div class="btn-group" role="group"></div>
      <div class="btn-toolbar " role="group">
        <button class="btn btn-white" [mat-dialog-close]="undefined">
          {{ data.cancelBtnText }}
        </button>
        &nbsp;&nbsp;
        <button id="okConfirmBtn" class="btn btn-dark" [mat-dialog-close]="true" cdkFocusInitial>
          {{ data.okBtnText }}
        </button>
        &nbsp;&nbsp;
        <button id="rejectConfirmBtn" class="btn btn-white" mat-dialog-close>
          {{ data.rejectBtnText }}
        </button>
      </div>
    </div>
    <br />
  </div>
</mat-dialog-actions>
