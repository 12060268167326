import { Component, Inject, OnChanges, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataType } from 'projects/shared-lib/src/lib/model/data-type.model';
import { CombinerAction, ComparerOperator, InquiryRequest, InquiryResponse, Institution, MessageType, PersistenceAction, QueryableColumn, QueryableTable, TableFilter, TableFilterGroup } from 'projects/shared-lib/src/public-api';
import { InstitutionService } from '../../shared/service/institution.service';

@Component({
  selector: 'app-import-catalog-modal',
  templateUrl: './import-catalog-modal.component.html',
  styleUrls: ['./import-catalog-modal.component.css']
})
export class ImportCatalogModalComponent implements OnInit, OnChanges {
  public institutions: Institution[] = [];
  public dataTypes: DataType[] = [];
  public filterGroup: TableFilterGroup = null;
  public queryableTables: QueryableTable[] = [];
  public columns: QueryableColumn[] = [];
  public comparers: ComparerOperator[] = [];
  public combiners: CombinerAction[] = [];
  public surveyQuestionId = 0;
  public form: FormGroup = null;
  public submitted: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ImportCatalogModalComponent>,
    public institutionService: InstitutionService,
    @Inject(MAT_DIALOG_DATA) data
    ) {
      this.surveyQuestionId = data.surveyQuestionId;
      this.queryableTables = data.queryableTables;
      this.comparers = data.comparers;
      this.combiners = data.combiners;
      this.filterGroup = data.filterGroup;
  }

  private buildTableFilterGroup(tFilterGroup: TableFilterGroup, surveyQuestionId: number): TableFilterGroup {
    let filterGroup: TableFilterGroup = null;
    if (!tFilterGroup) {
      filterGroup = new TableFilterGroup();
      filterGroup.id = 0;
      filterGroup.surveyQuestionId = surveyQuestionId;
      filterGroup.modelAction = PersistenceAction.INSERT;
      filterGroup.tableFilters = [];
    } else {
      filterGroup = Object.assign({}, tFilterGroup);
      filterGroup.modelAction =  filterGroup.id === 0 ? PersistenceAction.INSERT : PersistenceAction.UPDATE;
    }
    return filterGroup;
  }

  private buildTableFilter(tableFilter: TableFilter, tableFilterGroupId: number): TableFilter {
    let filter: TableFilter = null;
    if (!tableFilter) {
      filter = new TableFilter();
      filter.id = 0;
      filter.tableFilterGroupId = tableFilterGroupId;
      filter.modelAction = PersistenceAction.INSERT;
    } else {
      filter = Object.assign({}, tableFilter);
      filter.modelAction = filter.id === 0 ? PersistenceAction.INSERT : PersistenceAction.UPDATE;
    }
    return filter;
  }

  fetchIntitutions() {
    this.institutions = this.institutionService.institutions;
  }

  ngOnInit(): void {
    this.fetchIntitutions();
    this.InitForm(this.filterGroup);
  }

  ngOnChanges(): void {
    if (this.filterGroup) {
      this.InitForm(this.filterGroup);
    }
  }

  // convenience getters for easy access to form fields
  get f() { return this.form.controls; }

  public InitForm(filterGroup: TableFilterGroup): void {
    const tableFilter = filterGroup?.tableFilters.length ? Object.assign(filterGroup.tableFilters[0]) : null;
    const qTable = filterGroup?.tableName ? this.queryableTables.find(qt => qt.name === filterGroup.tableName) : null;
    this.columns = qTable?.queryableColumns ? qTable.queryableColumns : [];
    this.form = new FormGroup({
      id: new FormControl(tableFilter?.id ? tableFilter.id : 0, Validators.required),
      queryableTableId: new FormControl(qTable?.id ? qTable.id : null, Validators.required),
      columnId: new FormControl(tableFilter?.columnId ? tableFilter.columnId : null, Validators.required),
      comparerId: new FormControl(tableFilter?.comparerId ? tableFilter.comparerId : null, Validators.required),
      value: new FormControl(tableFilter?.value ? tableFilter.value : null, Validators.required),
      combinerId: new FormControl(tableFilter?.combinerId ? 1 : 1, Validators.required), //tableFilter.combinerId we're supporting only NONE
    });
  }

  public onChangeTable(selectedTableId: number): void {
    const foundTable = this.queryableTables.find(table => table.id === selectedTableId);
    this.columns = foundTable.queryableColumns;
    this.f['columnId'].setValue(null);
  }

  public onConfirm() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    const queryableTableId = this.form.controls['queryableTableId'].value;
    const queryableTable = this.queryableTables.find(qt => qt.id === queryableTableId);
    let dataToBeSaved: TableFilterGroup = this.buildTableFilterGroup(this.filterGroup, this.surveyQuestionId);
    const firstFilter = dataToBeSaved.tableFilters.length ?  dataToBeSaved.tableFilters[0] : null;
    let tableFilter: TableFilter = this.buildTableFilter(firstFilter, dataToBeSaved.id);
    tableFilter.columnId = this.form.controls['columnId'].value;
    tableFilter.comparerId = this.form.controls['comparerId'].value;
    tableFilter.combinerId = this.form.controls['combinerId'].value;
    const value: string = this.form.controls['value'].value;
    tableFilter.value = value.toLowerCase();
    dataToBeSaved.tableName =  queryableTable.name;
    dataToBeSaved.tableFilters = [];
    dataToBeSaved.tableFilters.push(tableFilter);
    this.dialogRef.close(dataToBeSaved);
  }

  public onCancel() {
      this.dialogRef.close();
  }

}
