import { Pipe, PipeTransform } from "@angular/core";
import { Decision } from "projects/shared-lib/src/public-api";

@Pipe({ name: "filterGuidelineByCategory" })
export class FilterGuidelineByCategoryPipe implements PipeTransform {

  transform(decisions: Decision[], categoryId: number) {
    return decisions.filter(decision => decision.categoryId === categoryId);
  }
}
