import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import { Node } from '@swimlane/ngx-graph'
import { QuestionGroup, Question } from 'projects/shared-lib/src/public-api';
import { Subscription } from 'rxjs';
import { QuestionGroupUtil } from '../../../shared/util/question-group.util';


@Component({
  selector: 'app-questiongroup',
  templateUrl: './questiongroup.component.html',
  styleUrls: ['./questiongroup.component.css']
})
export class QuestiongroupComponent implements OnInit {
  @Input() node: Node;
  @Input() group: QuestionGroup;
  showInTreeLength: number = 75;

  constructor(private questionGroupUtil: QuestionGroupUtil) { }
  isInTree() {
    if (this.node != undefined || this.node != null ) {
      return true;
    } else {
      return false;
    }
  }

  showAllDetails(group: QuestionGroup) {
    if (!group.isScoreBased) { return; }
  }

  getLabelForCheckBox(): string {
    if (this.group.isScoreBased) {
      return "Score Based";
    } else if (this.group.isStepBased) {
      return "Step Based";
    }
  }

  getDisplayText(question: Question): string {
    const text =  this.questionGroupUtil.getDropdownText(question);
    if (this.isInTree() ) {
      return text.substring(0, this.showInTreeLength) + " [...]";
    } else {
      return text;
    }
  }

  private updateQsGroup() {
    if (this.isInTree()) {
      this.group = this.node.data.group;
    }
  }

  ngOnInit() {
    this.updateQsGroup();
  }


}
