import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { ServiceUtil } from "projects/AdminApp/src/app/shared/util/service-util";
import { InquiryResponse } from "projects/shared-lib/src/public-api";

@Injectable({ providedIn: "root" })
export class NotifSubscriptionService {
  private subscriptionUrl = `${this.serviceUtil.rootUrl}/subscription`;

  constructor(private serviceUtil: ServiceUtil, private http: HttpClient) {
    this.serviceUtil = serviceUtil;
    this.http = http;
  }

  sendNotification(notification: any) {
    const url = `${this.subscriptionUrl}/SendNotification`;
    return this.http
      .post<InquiryResponse<any>>(
        url,
        notification,
        this.serviceUtil.getCommonAdminHeadersWithAuth()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.serviceUtil.handleResponse)
      );
  }
}
