<fieldset ngModelGroup="questionLanguagePanel">
    <div class="col-12  question px-0">
        <div *ngFor="let question of category.surveyQuestions; let q = index">
          <ng-template [ngIf]="question.modelAction != 3">
            <div class="row col-sm-12 pr-0 pl-1">
              <div class="col-12  bg-light ">
                <div class="editor color-dark">
                  <div class="row">
                    <div class="col-sm-12">
                      <br />
                      <div>
                        <label>Question Text *</label>
                      </div>
                      <br />
                      <div class="color-dark bg-white">
                        <input class="form-control"
                          [(ngModel)]="question.description"
                          name="question_text_ + {{getQuestionGroup()}} {{ q }}"
                          required [readonly]="readOnly"
                          maxlength="{{ validationControlUtil.largeTextLength }}"
                          (errorMsg)="validationControlUtil.showError($event)"
                        />
                      </div>
                    </div>
                  </div>
                  <br />

                  <div *ngIf="question.questionOptions && question.questionOptions.length > 0" class="col-12  bg-white options">
                    <br />
                    <div *ngFor="let option of question.questionOptions; let i = index">
                      <div class="row col-12">
                        <ng-template [ngIf]="option.modelAction != 3 && !option.isTableOption">
                            <input
                                class="form-control"
                                name="question_option_text_q{{getQuestionGroup()}}{{ q }}{{ i }}"
                                placeholder="Option Text"
                                [(ngModel)]="option.description"
                                required [readonly]="readOnly"
                            />
                        </ng-template>
                      </div>
                      <br />
                    </div>
                    <br />
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </ng-template>
          <br />
        </div>
      </div>
    </fieldset>
