import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { ServiceUtil } from "projects/AdminApp/src/app/shared/util/service-util";
import { InquiryRequest, InquiryResponse, Institution, ThemeElement
      ,SpinnerService, MessageType, ServiceConstant,
      ToasterService } 
      from "projects/shared-lib/src/public-api";

@Injectable({ providedIn: "root" })
export class InstitutionService {
  private institutionUrl = `${this.serviceUtil.rootUrl}/institution`;
  private themeElementUrl = `${this.serviceUtil.rootUrl}/themeElement`;
  public institutions: Institution[] = [];
  public themeElements: ThemeElement[] = null;
  constructor(
    private serviceUtil: ServiceUtil,
    private http: HttpClient,
    private toasterService: ToasterService,
    private spinnerService: SpinnerService
  ) {
    this.serviceUtil = serviceUtil;
    this.http = http;
    this.handleInstitutionsResponse = this.handleInstitutionsResponse.bind(
      this
    );
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
    this.onSaveSuccess = this.onSaveSuccess.bind(this);
  }

  private handleInstitutionsResponse(
    response: InquiryResponse<Institution>
  ) {}

  getAllInstitutions()
  {
    this.spinnerService.show();
    this.institutions.length = 0;
    this.getData(new InquiryRequest,this.institutionUrl).subscribe(this.onSuccess, this.onError);
  }


  getInstitutions(data: InquiryRequest)
  {
    const url = `${this.institutionUrl}/fetch`;
    return this.http
      .post<InquiryResponse<Institution | ThemeElement>>(
        url,
        data,
        this.serviceUtil.getCommonAdminHeadersWithAuth()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.handleInstitutionsResponse)
      ); 
  }

  getData(data: InquiryRequest,serviceUrl: string) 
  {
    const url = `${serviceUrl}/fetch`;
    return this.http
      .post<InquiryResponse<Institution | ThemeElement>>(
        url,
        data,
        this.serviceUtil.getCommonAdminHeadersWithAuth()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.handleInstitutionsResponse)
      );
  }

  private onError(error) 
  {
    this.spinnerService.hide();
    this.toasterService.show(MessageType.SYSTEM_ERROR, error);
  }

  private onSuccess(response: InquiryResponse<Institution | ThemeElement>) {
    if (response.operationSuccess) {
      this.spinnerService.hide();
      if(response.totalItems > 0)
      {
        if("description" in response.returnValues[0])
          this.institutions = [...response.returnValues] as Institution[];
        else
          this.themeElements = [...response.returnValues] as ThemeElement[];
      }
    } else if (response.messages.length > 0) {
      this.spinnerService.hide();
      this.toasterService.show(
        response.messages[0].type,
        response.messages[0].text
      );
    } else {
      this.onError(this.serviceUtil.getErrorMessage(response));
    }
  }

  getThemeElements(): ThemeElement[]
  {
    if( this.themeElements == null)
    {
      this.spinnerService.show();
      this.getData(new InquiryRequest,this.themeElementUrl).subscribe(this.onSuccess, this.onError);
    }
    return this.themeElements;
  }

  onSaveSuccess(response: InquiryResponse<any>)
  {
    this.spinnerService.hide();
    const msg = "Institution saved successfully";
      if (response.operationSuccess) {
        this.toasterService.show(MessageType.INFORMATIONAL, msg);
        this.getAllInstitutions();
      } else if (response.messages.length > 0) {
        this.toasterService.show(
          response.messages[0].type,
          response.messages[0].text
        );
      } else {
        this.toasterService.show(MessageType.SYSTEM_ERROR, ServiceConstant.somethingWentWrongMessage);
      }
  }

  saveInstitution(data: Institution)
  {
    this.save(data).subscribe(this.onSaveSuccess,this.onError);
  }

  private save(data: Institution)
  {
    this.spinnerService.show();
    const url = `${this.institutionUrl}/save`;
    return this.http
      .post<InquiryResponse<any>>(
        url,
        data,
        this.serviceUtil.getCommonAdminHeadersWithAuth()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.handleInstitutionsResponse)
      );
  }
}
