<fieldset ngModelGroup="questionPanel">
  <div class="col-12  question">
    <div *ngFor="let question of questionGroup.questions; let q = index">
      <ng-template
        [ngIf]="question.modelAction != 3 && canShowMultipleQuestions(q)"
      >
        <div class="row col-sm-12">
          <div class="col-11  bg-light ">
            <div class="editor color-dark">
              <div class="row">
                <div class="col-sm-12" *ngIf="!questionGroup.isStepBased">
                  <br />
                  <label>Question Text *</label>
                  <br />
                </div>
                <div
                  class="col-sm-12"
                  *ngIf="questionGroup.isStepBased"
                  class="m-2"
                >
                  <br />
                  <label>Step Text *</label>
                </div>
                <br />
                <div class="color-dark bg-white">
                  <angular-editor
                    id="question_text_{{q}}"
                    [(ngModel)]="question.text"
                    [config]="config"
                    name="question_text_ + {{ q }}"
                    required
                    maxlength="{{ validationControlUtil.largeTextLength }}"
                    (errorMsg)="validationControlUtil.showError($event)"
                  ></angular-editor>
                </div>
                <div *ngIf="question && question?.text" class="col-12 text-left pt-1">
                  <label>Characters count: {{ question.text?.length }}</label>
                </div>
              </div>
              <br />
              <div class="row col-10">
                <br />
                <label>Tag *</label>
              </div>
              <div class="row col-12">
                <input
                  class="col-sm-6 form-control"
                  id="question_tag_ + {{ q }}"
                  name="question_tag_ + {{ q }}"
                  placeholder="Tag"
                  [(ngModel)]="question.tag"
                  required
                  maxlength="{{ validationControlUtil.tagMaxLength }}"
                />
                <div class="col-sm-6">
                  <mat-checkbox
                    labelPosition="before"
                    name="question_multiple_ + {{ q }}"
                    [(ngModel)]="question.multipleSelection"
                    >Multiple</mat-checkbox
                  >
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-sm-6">
                  <label>Display order *</label>
                  <br />
                  <input
                    class="form-control"
                    type="number"
                    id="questionOrder_{{ q }}"
                    name="displayOrder_{{ q }}"
                    placeholder="Number"
                    [(ngModel)]="question.displayOrder"
                    inputmode="numeric"
                    pattern="[0-9]+"
                    maxlength="4"
                    numberOnly
                  />
                </div>
              </div>
              <br />
              <div class="col-12  bg-white options">
                <br />
                <div *ngFor="let option of question.options; let i = index">
                  <div class="row col-12">
                    <ng-template [ngIf]="option.modelAction != 3">
                      <mat-accordion class="col-sm-9">
                        <mat-expansion-panel class="bg-secondary">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              {{ getOptionText(option) }}
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <div class="row">
                            <div class="col-sm-12 ">
                              <label>{{ getLabelForOption() }} Text *</label>
                              <br />
                              <input
                                class="form-control"
                                id="question_option_text_q{{ q }}{{ i }}"
                                name="question_option_text_q{{ q }}{{ i }}"
                                placeholder="{{ getLabelForOption() }} Text"
                                [(ngModel)]="option.text"
                                required
                              />
                            </div>
                          </div>
                          <br />
                          <div class="row">
                            <div class="col-sm-6 ">
                              <label>Tag *</label>
                              <br />
                              <input
                                class="form-control"
                                id="question_option_tag_q{{ q }}{{ i }}"
                                name="question_option_tag_q{{ q }}{{ i }}"
                                placeholder="Tag"
                                [(ngModel)]="option.tag"
                                required
                                maxlength="{{
                                  validationControlUtil.tagMaxLength
                                }}"
                              />
                            </div>
                            <div
                              class="col-sm-6"
                              *ngIf="
                                question.multipleSelection ||
                                questionGroup.isScoreBased
                              "
                            >
                              <label>Weight*</label>
                              <br />
                              <input
                                class="form-control"
                                name="question_option_weight_q{{ q }}{{ i }}"
                                placeholder="weight"
                                [(ngModel)]="option.weight"
                                [required]
                              />
                            </div>
                            
                          </div>
                          <br />
                          <div class="row">
                            <div class="col-sm-6">
                              <label>Display order *</label>
                              <br />
                              <input
                                class="form-control"
                                type="number"
                                id="displayOrder_{{ q }}_{{ i }}"
                                name="displayOrder_{{ q }}_{{ i }}"
                                placeholder="Number"
                                [(ngModel)]="option.displayOrder"
                                inputmode="numeric"
                                pattern="[0-9]+"
                                maxlength="4"
                                numberOnly
                              />
                            </div>
                          </div>
                          <div *ngIf="displayVariableField()" class="row">
                            <label>Variables </label>
                            <br/>
                            <div class="form-group">
                              <ng-select
                                id="variable_{{ q }}_{{ option.id }}"
                                name="variableIds_{{option.id}}"
                                [multiple]="true"
                                [(ngModel)]="option.variables"
                                placeholder="Variables" 
                                (change)="variableListChanged(option)"
                              >
                                <ng-option
                                  *ngFor="let v of surveyVariables; let j = index"
                                  id="variable_option_{{ j }}"
                                  [value]="v"
                                  required
                                >
                                  {{ v }}
                                </ng-option>
                              </ng-select>
                            </div>
                          </div>         
                          <div *ngIf="!questionGroup.isScoreBased">
                            <div
                              class="row"
                              #itemsList="cdkDropList"
                              cdkDropList
                              [cdkDropListData]="relatedObjects"
                              (cdkDropListDropped)="onDrop($event, option)"
                            >
                              <div class="col-sm-12 ">
                                <label>Next Step:</label>
                                <br />
                                <mat-select (openedChange)="tagHelper.onOpenClose($event,option.id)"
                                  class="form-control bg-white"
                                  (selectionChange)="
                                    onRelatedObjectChange($event, option)
                                  "
                                  [(ngModel)]="option.relatedObject"
                                  name="question_option_nextid{{ q }}{{ i }}"
                                >
                                <mat-select-filter [displayMember]="'tag'"
                                  [array]="tagHelper.optionMap.get(option.id)"
                                  (filteredReturn)="tagHelper.cloneOptionMap.set(option.id,$event)"
                                ></mat-select-filter>
                                  <mat-option
                                    *ngFor="let object of tagHelper.cloneOptionMap.get(option.id)"
                                    id="{{ object.tag }}"
                                    [value]="object"
                                  >
                                    {{ object.tag }}
                                  </mat-option>
                                </mat-select>
                              </div>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                      <div class="col-sm-3">
                        <button
                          *ngIf="question.options.length > 1"
                          type="button"
                          class="btn btn-secondary"
                          (click)="removeOption(question, i)"
                        >
                          -
                        </button>
                      </div>
                    </ng-template>
                  </div>
                  <br />
                </div>
                <div class="row col-12">
                  <div class="col-6">
                    <button
                      type="button"
                      id="addBtn_{{ q }}"
                      class="btn btn-secondary"
                      (click)="addOption(question)"
                    >
                      +
                    </button>
                    <label class="color-dark ml-2"
                      >Add {{ getLabelForOption() }}</label
                    >
                  </div>
                </div>
                <br />
              </div>
              <br />
            </div>
          </div>
          <div class="col-1">
            <button
              *ngIf="questionGroup.questions.length > 1"
              type="button"
              class="btn btn-white"
              (click)="removeQuestion(q)"
            >
              -
            </button>
          </div>
        </div>
      </ng-template>
      <br />
    </div>
  </div>
  <div class="col-12 add-question" *ngIf="shouldShowAddAndRemoveQuestion()">
    <br />
    <button type="button" class="btn btn-white" (click)="addQuestion()">
      +
    </button>
    <label class="color-white ml-2">Add Question</label>
  </div>
</fieldset>
