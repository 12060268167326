import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "filterByLangCode" })
export class FilterByLangCode implements PipeTransform {
  private containValue(obj: any, key: string, value: string): boolean {
    if (obj[key].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
      return true;
    }
    return false;
  }

  private filterByProp(key: string, value: string) {
    return (element) => {
      return !this.containValue(element, key, value);
    };
  }

  private filterByLangCode(elements: any[], value: string) {
    const key = "languageCode";

    if (value.length === 0) {
      return [...elements];
    } else {
      return elements.filter(this.filterByProp(key, value));
    }
  }

  transform(elements: any[], value: string) {
    return this.filterByLangCode(elements, value);
  }
}
