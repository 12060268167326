import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[scoreTextValidator][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useClass: ScoreTextValidator, multi: true }
  ]
})
export class ScoreTextValidator implements Validator
{
    validate(control: AbstractControl): ValidationErrors 
    {
        const reg1: RegExp = /^(<|>)(=)?(\s)?[0-9]+(\s)?$/;// < number or > number or <= number or >= number;
        const reg2: RegExp = /^[0-9]+(\s)?[-]{1}(\s)?[0-9]+$/;//Eg: 10 - 20 or 10-20
        const reg3: RegExp = /^[0-9]+$/;
        let value = control.value as string;
        if(value == null)
            return null;
        if(!reg1.test(value))
        {
            if(!reg2.test(value))
            {
                if(!reg3.test(value))
                    return {invalidScore: true};
            }
        }
        return null;
    }    
}