import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { QuestionGroup, Question, HiLowRange,
    ParseScoreUtility } from 'projects/shared-lib/src/public-api';

@Injectable({ providedIn: "root" })
export class ScoreListValidator
{
    constructor(private parseScoreUtility: ParseScoreUtility){}
    getWeightedRangeForMultiSelect(question: Question): number
    {
        let total = 0;
        for(let j = 0; j < question.options.length;j++)
        {
            let w = +question.options[j].weight;
            total = total + w;
        }
        return +total;
    }

    getWeightedRangeForSingleSelect(question: Question): HiLowRange
    {
        let range = new HiLowRange();
        range.low = +question.options[0].weight;
        range.high = +question.options[0].weight;
        for(let j = 1; j < question.options.length;j++)
        {
            if(range.low > question.options[j].weight)
                range.low = +question.options[j].weight;
            else if(range.high < question.options[j].weight)
                range.high = +question.options[j].weight;
        }
        return range;
    }

    getQuestionWeightedRange(group: QuestionGroup): Set<number>
    {
        let range = new HiLowRange();
        range.low = 0;
        range.high = 0;
        for(let i = 0; i < group.questions.length;i++)
        {
            let question = group.questions[i];
            let result = this.getWeightedRangeForSingleSelect(question);
            if(question.multipleSelection)
            {
                result.high = this.getWeightedRangeForMultiSelect(question);
            }
            range.low += result.low;
            range.high += result.high;
        }
        let output = new Set<number>();
        for(let i = range.low; i <= range.high;i++)
            output.add(i);
        return output;
    }
    
    validate(group: QuestionGroup): ValidationErrors
    {
        if(!group.isScoreBased)
            return null;
        let totalRange = this.getQuestionWeightedRange(group);
        let scoreRange = new Set<number>();
        for(let i = 0; i < group.scoreToNextObjectList.length; i++)
        {
            let scoreObject = group.scoreToNextObjectList[i];
            let data = scoreObject.score.replace(/\s/g, "");
            let result = this.parseScoreUtility.parseScore(data);
            if(result == null)
            {
                return ["key","Invalid range"];
            }
            for(let j = result.low; j <=result.high;j++ )
            {
                if(!scoreRange.has(j))
                {
                    scoreRange.add(j);
                }
                else
                {
                    return ["key","Overlapping range: " + scoreObject.score];
                }
                if(totalRange.has(j))
                    totalRange.delete(j);
            }
        }
        if(totalRange.size > 0)
        {
            let error: string = '';
            for(let s of totalRange)
            {
                error += s.toString();
                error += ",";
            }
            return ["key","Whole range is not covered. Here are items missing: " + error];
        }
        return null;
    }
}