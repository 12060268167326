import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, NgForm } from '@angular/forms';
import { SpinnerService, InquiryResponse, Institution,
      Decision, MessageType, ToasterService  } from 'projects/shared-lib/src/public-api';
import { HttpClient } from '@angular/common/http';
import { ServiceUtil } from 'projects/AdminApp/src/app/shared/util/service-util';
import { environment } from 'projects/AdminApp/src/environments/environment';


@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {
  @Input() instance: Institution | Decision;
  private uploadUrl = `${this.serviceUtil.rootUrl}/Image`;

  public form: FormGroup;

  constructor(
    private spinnerService: SpinnerService,
    private toasterService: ToasterService,
    private http: HttpClient,
    private serviceUtil: ServiceUtil) {
    this.onImageSuccess = this.onImageSuccess.bind(this);
    this.onError = this.onError.bind(this);
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({
      uploadPanel: new FormControl()
    });
  }

  onFileChanged(files: FileList) {
    if (files.length === 0) {
      return;
    }
    const fileToUpload = files.item(0);
    const formData = new FormData();
    formData.append('filesData', fileToUpload);
    const request = this.uploadFile(formData);
    request.subscribe(this.onImageSuccess,this.onError);
  }

  uploadFile(formData: FormData) {
    this.spinnerService.show();
    let url: string = `${this.uploadUrl}/`;
    if("themeElements" in this.instance) {
      url += "UploadInstitutionLogoFileToBlob";
    } else {
      url += "UploadGuidelinesIconFileToBlob";
    }
    let oldUrl: string = null;
    if (this.instance.logo !== undefined) {
      oldUrl = this.instance.logo;
    }
    formData.append("oldUrl",oldUrl);
    return this.http.post<InquiryResponse<string>>(url, formData,
      this.serviceUtil.getNonJSONContentAuthHeader());
  }

  private onError(error: InquiryResponse<string>) {
    this.spinnerService.hide();
    this.toasterService.show(MessageType.SYSTEM_ERROR, error.messages[0].text);
  }

  onImageSuccess(result: InquiryResponse<string>) {
    const group = this.form.controls["uploadPanel"];
    group.markAsDirty();
    this.spinnerService.hide();
    this.instance.logo = result.returnValues[0];
  }

  showImage(): boolean {
    if (this.instance.logo !== undefined && this.instance.logo !== null
      && this.instance.logo.length > 0) {
        return true;
    }
    return false;
  }

  isNotAutomatedTestEnvironment(): boolean {
    return environment.isAutomation === undefined || !environment.isAutomation;
  }
}
