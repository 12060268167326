import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Decision, Pathway, Institution } from 'projects/shared-lib/src/public-api';


@Component({
  selector: 'app-guidelines-selector',
  templateUrl: './guidelines-selector.component.html',
  styleUrls: ['./guidelines-selector.component.css']
})
export class GuidelinesSelectorComponent implements OnInit {
  decisions: Decision[];
  institutions: Decision[];
  selectedDecision: Decision;
  selectedPathway: Pathway;
  selectedInstitution: Institution;
  pathwaysForInstitution: Pathway[] = [];
  constructor(public dialogRef: MatDialogRef<GuidelinesSelectorComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) 
  { 
    this.decisions = data.decisions;
    this.institutions = data.institutions;
  }

  ngOnInit() {
  }

  onDecisionChanged()
  {
    this.selectedPathway = null;
    this.selectedInstitution = null;
  }

  onInstitutionChanged()
  {
    this.selectedPathway = null;
    this.pathwaysForInstitution.length = 0;
    this.selectedDecision.pathways.forEach(p=>{
      let pathway = p as Pathway;
      if(pathway.institutionId == this.selectedInstitution.id)
      {
        if(pathway.startingQuestionGroupId > 0)
          this.pathwaysForInstitution.push(pathway);
      }
      pathway.byInstitutions.forEach(associatedInstitution=>{
        if(associatedInstitution.institutionId == this.selectedInstitution.id)
        {
          if(!this.pathwaysForInstitution.includes(pathway))
            if(pathway.startingQuestionGroupId > 0)
              this.pathwaysForInstitution.push(pathway);
        }
        });
    });
  }

  close()
  {
    this.dialogRef.close();
  }

  import()
  {
    this.dialogRef.close(this.selectedPathway);
  }
}
