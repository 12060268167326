import { Injectable } from '@angular/core';
import { OptionVariable, QuestionGroup, PersistenceAction, Pathway,
        Question, Option } from "projects/shared-lib/src/public-api";

@Injectable({ providedIn: "root" })
export class OptionVariableUtility
{
  setOptionVariables(questionGroup: QuestionGroup) 
  {
    questionGroup.questions.forEach( q=>{
      q.options.forEach( o => {
        o.variables = [];
        if(o.variableList === undefined)
          o.variableList = [];
        o.variableList.forEach( v =>{
          if(v.modelAction !== PersistenceAction.DELETE)
            o.variables.push(v.text);
        });
      });
    });
  }

  /**
   * Copy option variable changes from group to all other pathway groups.
   * @param group 
   * @param pathway 
   * @returns 
   */
  copyOptionVariablesToOtherOptions(group: QuestionGroup, pathway: Pathway)
  {
    if(group.isScoreBased)
      return;
    for(var question of group.questions) {
      for(var option of question.options){
        var addedVariables: string[] = [];
          var removedVariables: string[] = [];
          let variableMap: Map<string,OptionVariable> = this.convertToOptionVariablesToMap(option);
          option.variableList.forEach( (element, index) => {
            var item = option.variables.indexOf( element.text );
            if (item === -1) {
              if (element.modelAction == PersistenceAction.NONE)
                element.modelAction = PersistenceAction.DELETE;
              else
                option.variableList.splice(index, 1);
              this.setGroupModelAction(group);
              removedVariables.push(element.text);
            }
            else //if deleted add it back
            {
              if (element.modelAction === PersistenceAction.DELETE)
              {
                element.modelAction = PersistenceAction.NONE;
                addedVariables.push(element.text);
              }
            }
          });
          if(option.variables) {
            for(var v of option.variables) {
              if(!variableMap.has(v))
              {
                addedVariables.push(v);
                this.addVariable(option,v);
                this.setGroupModelAction(group);
              }
              else
              {
                var vi = variableMap.get(v);
                if (vi.modelAction === PersistenceAction.DELETE)
                {
                  vi.modelAction = PersistenceAction.NONE;
                  addedVariables.push(vi.text);
                }
              }
            }
          }
          if(option.copyVariables)
            this.propagateChanges(addedVariables,removedVariables,question,option,pathway,group);
      }
    }
    /*group.questions.forEach( question =>{
      console.log(question);
      console.log("question");
      console.log(question.options)
      
      question.options.forEach( option => {
          var addedVariables: string[] = [];
          var removedVariables: string[] = [];
          let variableMap: Map<string,OptionVariable> = this.convertToOptionVariablesToMap(option);
          option.variableList.forEach( (element, index) => {
            var item = option.variables.indexOf( element.text );
            if (item === -1) {
              if (element.modelAction == PersistenceAction.NONE)
                element.modelAction = PersistenceAction.DELETE;
              else
                option.variableList.splice(index, 1);
              this.setGroupModelAction(group);
              removedVariables.push(element.text);
            }
            else //if deleted add it back
            {
              if (element.modelAction === PersistenceAction.DELETE)
              {
                element.modelAction = PersistenceAction.NONE;
                addedVariables.push(element.text);
              }
            }
          });
          option.variables.forEach( v => {
            if(!variableMap.has(v))
            {
              addedVariables.push(v);
              this.addVariable(option,v);
              this.setGroupModelAction(group);
            }
            else
            {
              var vi = variableMap.get(v);
              if (vi.modelAction === PersistenceAction.DELETE)
              {
                vi.modelAction = PersistenceAction.NONE;
                addedVariables.push(vi.text);
              }
            }
          });
          if(option.copyVariables)
            this.propagateChanges(addedVariables,removedVariables,question,option,pathway,group);
      });
    });*/
  }

  /**
   * Propagate changes to all other question groups in pathway
   * @param addedVariables all newly added variables to an option 
   * @param removedVariables all removed variables to an option
   * @param question current question
   * @param option current option
   * @param pathwaySelected pathway for get all other question groups
   * @param questionGroup current question group
   */
  private propagateChanges(addedVariables: string[], removedVariables: string[],question: Question, option: Option,
                          pathwaySelected: Pathway, questionGroup: QuestionGroup)
  {
    for(var i = 0; i < pathwaySelected.questionGroupList.length;i++)
    {
      var group = pathwaySelected.questionGroupList[i];
      if(group.id == questionGroup.id)
        continue;
      if(group.templateQuestionGroupId !== questionGroup.templateQuestionGroupId)
        continue;
      for(var j =0; j < group.questions.length; j++)
      {
        var question1 = group.questions[j];
        if(question1.tag !== question.tag)
          continue;
        for(var k =0; k < question1.options.length; k++)
        {
          var option1 = question1.options[k];
          if(option1.tag !== option.tag)
            continue;
          let varMap = this.convertToOptionVariablesToMap(option1);
          addedVariables.forEach( v => {
            if(!varMap.has(v))
            {
              this.addVariable(option1,v);
              this.setGroupModelAction(group);
            }
            else
            {
              if(varMap.get(v).modelAction === PersistenceAction.DELETE)
                varMap.get(v).modelAction = PersistenceAction.NONE;
            }
          });
          removedVariables.forEach( v => {
            for(var i = option1.variableList.length-1; i >= 0; i--)
            {
              let vi = option1.variableList[i];
              if(vi.text !== v)
                continue;
              if(vi.modelAction === PersistenceAction.INSERT)
              {
                option1.variableList.splice(i,1);
                break;
              }
              else
              {
                vi.modelAction = PersistenceAction.DELETE;
                this.setGroupModelAction(group);
                break;
              }
            }
          });
        }
      }
    }
  }
  
  /**
   * Add new variable to an existing variable list
   * @param option 
   * @param text 
   */
  private addVariable(option: Option, text: string){
    var newItem = new OptionVariable();
    newItem.id = -1;
    newItem.optionId = option.id;
    newItem.text = text;
    newItem.displayOrder = 99;
    newItem.modelAction = PersistenceAction.INSERT;
    option.variableList.push(newItem);
  }

  /**
   * Convert option variables to a Map
   * @param option 
   * @returns 
   */
  private convertToOptionVariablesToMap(option: Option): Map<string,OptionVariable>
  {
    let varMap = new Map<string,OptionVariable>();
    if(option.variableList === undefined)
          option.variableList = [];
    option.variableList.forEach( v =>{
      varMap.set(v.text,v);
    });
    return varMap;
  }

  private setGroupModelAction(group: QuestionGroup){
    if(group.modelAction === PersistenceAction.NONE)
      group.modelAction = PersistenceAction.UPDATE;

  }
}