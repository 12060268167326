import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { DialogModule } from "./dialog/dialog.module";
import { NotFoundModule } from "./not-found/not-found.module";

@NgModule({
  imports: [
    RouterModule,
    DialogModule,
    NotFoundModule
  ],
  exports: [
    RouterModule,
    DialogModule,
    NotFoundModule
  ],
  declarations: [],
  providers: []
})
export class CoreModule {}
