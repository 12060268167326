import { Component } from "@angular/core";

@Component({
  selector: "app-not-found",
  template: `
    <div class=" text-center text-dark">
      <br />
      <h2>Page not found!</h2>
      <br />
    </div>
  `
})
export class NotFoundComponent {
  constructor() {}
}
