<mat-sidenav-container>
  <mat-sidenav-content>
    <nav class="navbar navbar-expand-lg bg-light navbar-light fixed-top">
      <div class="navbar-logo">
        <img class="retina" alt="" src="../assets/childrens-h-logo.png" />
      </div>
      <div class="collapse navbar-collapse" id="collapsibleNavbar">
        <ul class="navbar-nav mr-auto">
          <li class="pr-2 nav-item dropdown">
            <a
              href="javascript:void(0)"
              class="drop-btn dropdown-toggle color-primary"
              >Guidelines</a
            >
            <div class="dropdown-content bg-white">
              <li class="dropdown-submenu">
                <a
                  class="nav-item color-primary"
                  tabindex="-1"
                  href="{{ guidelinesUserAppUrl }}"
                  target="_blank"
                  >Guidelines</a
                >
                <ul class="dropdown-menu">
                  <div *ngFor="let category of categories">
                    <a
                      class="nav-item color-primary"
                      href="{{
                        userAppUrl +
                          category.description.toLowerCase() +
                          '/guidelines?loggedIn=1'
                      }}"
                      target="_blank"
                      >{{ category.description }}</a
                    >
                  </div>
                </ul>
              </li>
              <a
                class="nav-item color-primary"
                href="{{ surveyUserAppUrl }}"
                target="_blank"
                >Survey</a
              >
            </div>
          </li>
          <li
            class="pr-2 nav-item dropdown"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a href="javascript:void(0)" class="drop-btn dropdown-toggle"
              >Admin</a
            >
            <div class="dropdown-content bg-white">
              <a
                id="guidelines_menu"
                class="nav-item"
                routerLinkActive="active"
                routerLink="/admin/guidelines"
                >Guidelines</a
              >
              <a
                id="questions_tree_menu"
                class="nav-item"
                routerLinkActive="active"
                routerLink="/admin/question-tree"
                >Question Tree</a
              >
              <a
                id="templates_menu"
                class="nav-item"
                routerLinkActive="active"
                routerLink="/admin/templates"
                >Templates</a
              >
              <a
                id="notifications_menu"
                class="nav-item"
                routerLinkActive="active"
                routerLink="/admin/notifications"
              >
                Notifications</a
              >
              <a
                id="languages_menu"
                class="nav-item"
                routerLinkActive="active"
                routerLink="/admin/language"
              >
                Languages</a
              >
              <a id="user_types_menu" class="nav-item" routerLinkActive="active" routerLink="admin/user-types">
                  User Types</a>
              <a
                id="institutions_menu"
                class="nav-item"
                routerLinkActive="active"
                routerLink="/admin/institution"
              >
                Institutions</a
              >
              <a id="user_types_menu" class="nav-item" routerLinkActive="active" routerLink="admin/facilities">
                Facilities</a>
            </div>
          </li>
          <li
            class="pr-2 nav-item dropdown"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }">
            <a href="javascript:void(0)" class="drop-btn dropdown-toggle">Utilities</a>
            <div class="dropdown-content bg-white">
              <a
                id="export"
                class="nav-item"
                routerLinkActive="active"
                routerLink="/utilities/export"
                >Export</a>
                <a id="import" class="nav-item" routerLinkActive="active" routerLink="/utilities/import">Import</a>
                <a id="users-import" class="nav-item" routerLinkActive="active" routerLink="/admin/users-import">Users Import</a>
                <a id="register-users" class="nav-item" routerLinkActive="active" routerLink="/utilities/register-users">Register Users</a>
            </div>
            </li>
        </ul>
        <ul *ngIf="isAuthenticated" class="nav navbar-nav navbar-right">
          <li class="pr-2">
            <a id="username_label" class="color-dark">
              <i class="material-icons">account_circle</i>
              {{ username | lowercase }}
            </a>
          </li>
          <li class="pr-2 nav-item">
            <a id="log_out_menu" class="color-dark" (click)="onLogout()"
              >Log out</a
            >
          </li>
        </ul>
      </div>
    </nav>
    <br />
    <app-toaster-container></app-toaster-container>
    <div class="col-12 app-body">
      <router-outlet></router-outlet>
    </div>
    <app-spinner></app-spinner>
  </mat-sidenav-content>
</mat-sidenav-container>
