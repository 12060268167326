<form [formGroup]="form" (ngSubmit)="onConfirm()">
  <div class="import-container">
    <div>
      <h3 mat-dialog-title>Import options from existing data</h3>
    </div>
    <div class="dropdowns">
      <div class="section">
        <div>
          <label>Select a catalog:</label>
          <ng-select [multiple]="false" placeholder="Catalog" formControlName="queryableTableId"
            (change)="onChangeTable($event)">
            <ng-option *ngFor="let queryableTable of queryableTables" [value]="queryableTable.id" required>
              {{ queryableTable.name }}
            </ng-option>
          </ng-select>
          <div *ngIf="submitted && f.queryableTableId.errors" class="invalid-feedback">
            <div *ngIf="f.queryableTableId.errors.required">This field is required</div>
          </div>
        </div>
      </div>
      <div class="section">
        <label>Select column:</label>
          <ng-select [multiple]="false" placeholder="Column" formControlName="columnId">
            <ng-option *ngFor="let column of columns" [value]="column.id" required>
              {{ column.label }}
            </ng-option>
          </ng-select>
          <div *ngIf="submitted && f.columnId.errors" class="invalid-feedback">
            <div *ngIf="f.columnId.errors.required">This field is required</div>
          </div>
        <br />
      </div>
      <div class="section">
        <label>Select comparer:</label>
        <ng-select [multiple]="false" placeholder="Comparer" formControlName="comparerId">
          <ng-option *ngFor="let comparer of comparers" [value]="comparer.id" required>
            {{ comparer.name }}
          </ng-option>
        </ng-select>
        <div *ngIf="submitted && f.comparerId.errors" class="invalid-feedback">
          <div *ngIf="f.comparerId.errors.required">This field is required</div>
        </div>
        <br />
      </div>
      <div>
        <label>Value:</label>
        <br />
        <input class="form-control" placeholder="Value" formControlName="value" required />
        <div *ngIf="submitted && f.value.errors" class="invalid-feedback">
          <div *ngIf="f.value.errors.required">This field is required</div>
        </div>
      </div>
      <div hidden>
        <label>Select combiner:</label>
        <ng-select [multiple]="false" placeholder="Combiner" formControlName="combinerId" [readonly]="true">
          <ng-option *ngFor="let combiner of combiners" [value]="combiner.id" required>
            {{ combiner.name }}
          </ng-option>
        </ng-select>
          <div *ngIf="submitted && f.combinerId.errors" class="invalid-feedback">
            <div *ngIf="f.combinerId.errors.required">This field is required</div>
          </div>
        <br />
      </div>
    </div>
    <div class="btns">
      <button mat-button type="button" class="btn btn-dark" (click)="onCancel()">Cancel</button>
      <button mat-button type="submit" class="btn btn-dark confirm-btn" cdkFocusInitial>Apply</button>
    </div>
  </div>
</form>




