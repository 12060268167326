import { Injectable } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MessageType, ToasterService } from "projects/shared-lib/src/public-api";
import { globalLengthLimit } from "../constant/max-length.constant";

@Injectable({ providedIn: "root" })
export class ValidationControlUtil {
  constructor( private toaster: ToasterService) {}

  public showError(err: string) {
    this.toaster.show(MessageType.VALIDATION, err);
  }

  getErrorMessgageInternal(controls): string
  {
    const controlArray = Object.values<FormControl>(controls);
    return controlArray.reduce((message: string, control: FormControl) => {
      if (control && control.errors) {
        if (control.errors.required) {
          message += "The field is required ";
          message += "\n";
        } else if (control.errors.tagLength) {
          message += "The field is too long ";
          message += "\n";
        } else if (control.errors.invalidScore) {
          message += "Invalid Score";
          message += "\n";
        }
        else if(control.errors.maxlength)
        {
          message += "Text length is greater than allowed length\n";
        }
      }
      return message;
    }, "") as string;
  }

  getErrorMsg(controls): string {
    let errorMessage: string = '';
    const controlArray = Object.values<FormControl>(controls);
    controlArray.forEach(element => {
      if( 'controls' in element)
      {
        let formGroup: FormGroup = element as FormGroup;
        errorMessage += this.getErrorMessgageInternal(formGroup.controls)
      }
    });
    errorMessage += this.getErrorMessgageInternal(controls);
    return errorMessage;
  }

  controlErrorMessage(message: string, control: FormControl) {}

  get textLength() {
    return globalLengthLimit.textLength;
  }

  get additionalInfoTextLength() {
    return globalLengthLimit.additionalInfoTextLength;
  }

  get nameLength() {
    return globalLengthLimit.nameLength;
  }

  get pathwayNameLength() {
    return globalLengthLimit.pathwayName;
  }

  get scoreMaxLength() {
    return globalLengthLimit.scoreLength;
  }

  get tagMaxLength() {
    return globalLengthLimit.tagLength;
  }

  get largeTextLength() {
    return globalLengthLimit.largeTextLength;
  }

  get descriptionLength(){
    return globalLengthLimit.institutionDescriptionLength;
  }

  get languageCodeLength() {
    return globalLengthLimit.languageCodeLength;
  }

  get languageDescriptionLength(){
    return globalLengthLimit.languageDescriptionLength;
  }

  get appTextLength(){
    return globalLengthLimit.languageAppTextLength;
  }
}
