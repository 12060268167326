import { Directive } from "@angular/core";
import {
  NG_VALIDATORS,
  Validator,
  AbstractControl,
  ValidationErrors
} from "@angular/forms";
import { globalLengthLimit } from '../constant/max-length.constant';

@Directive({
  selector: "[tagLengthValidator][ngModel]",
  providers: [{ provide: NG_VALIDATORS, useClass: TagValidator, multi: true }]
})
export class TagValidator implements Validator {
  validate(control: AbstractControl): ValidationErrors {
    let data = control.value as string;
    if (data != null && data.length > globalLengthLimit.tagLength) {
      return { tagLength: true };
    } else {
      return null;
    }
  }
}
