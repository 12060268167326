<div class="text-left">
  <form
    (ngSubmit)="onSave(questionGroupForm, questionGroup, localStorageUtil.getQsGroup())"
    #questionGroupForm="ngForm"
    name="form"
  >
    <br />
    <div class="edit-question-group bg-dark">
      <br />
      <div class="col-12">
        <div class="toolbar-container">
          <div *ngIf="!(questionGroup.id |showLangToolbar)" class="lang-toolbar"></div>
          <app-lang-toolbar
            *ngIf="questionGroup.id |showLangToolbar"
            (afterLanguageWindowClosed)="saveLanguageText($event)"
            [selectedInstance]="questionGroup"
            [instanceType]="tableType"
            [isLoading]="false"
          >
          </app-lang-toolbar>
          <div class="btn-toolbar justify-content-between" role="toolbar">
            <div class="btn-group color-white" role="group"></div>
            <div class="btn-toolbar" role="group">
              <button
                id="save_new_question_btn"
                type="button"
                class="btn btn-dark"
                (click)="onCancel()"
              >
                Cancel
              </button>
              <button *ngIf="questionGroup.isSaveAs" id="save_as_new_questions_btn" class="btn btn-white">
                Save As
              </button>
              <button *ngIf="showSaveButton()" id="save_new_questions_btn" class="btn btn-white">
                Save
              </button>
            </div>
          </div>
        </div>
        <br />
        <div class="row color-white">
          <div class="col-sm-9 col-9">
            <mat-checkbox
              id="qs_group_is_score_based"
              labelPosition="before"
              [(ngModel)]="questionGroup.isScoreBased"
              name="isScoreBased"
              [disabled]="questionGroup.isStepBased"
              >Score Based
            </mat-checkbox>
            <mat-checkbox
              id="qs_group_is_step_based"
              labelPosition="before"
              class="m-3"
              [(ngModel)]="questionGroup.isStepBased"
              name="isStepBased"
              [disabled]="questionGroup.isScoreBased"
              >Step Based
            </mat-checkbox>
          </div>
        </div>
        <br />
        <div class="row color-white">
          <div class="col-sm-3 col-3">
            <label>Display order *</label>
            <br />
            <input
              class="form-control"
              type="number"
              id="displayOrder_{{ questionGroup.id }}"
              name="displayOrder_{{ questionGroup.id }}"
              placeholder="Number"
              [(ngModel)]="questionGroup.displayOrder"
              inputmode="numeric"
              pattern="[0-9]+"
              maxlength="4"
              numberOnly
            />
          </div>
        </div>
      </div>
      <br />

      <ng-template [ngIf]="questionGroup.isScoreBased">
        <div class="editor">
          <mat-tab-group>
            <mat-tab label="Questions">
              <app-question-panel
                [questionGroup]="questionGroup"
                [relatedObjects]="relatedObjects"
                [selectedObject]="selectedObject"
                [canAssociateObjects]="canAssociateObjects"
                [isObjectRemoved]="isObjectRemoved"
                [isUsedByTree]="isUsedByTree"
              ></app-question-panel>
            </mat-tab>
            <mat-tab label="Score Mapping" *ngIf="questionGroup.isScoreBased">
              <app-score-panel
                [questionGroup]="questionGroup"
                [relatedObjects]="relatedObjects"
                [selectedObject]="selectedObject"
                [canAssociateObjects]="canAssociateObjects"
                [isObjectRemoved]="isObjectRemoved"
              ></app-score-panel>
            </mat-tab>
          </mat-tab-group>
        </div>
      </ng-template>
      <ng-template [ngIf]="!questionGroup.isScoreBased">
        <div class="editor">
          <app-question-panel
            [questionGroup]="questionGroup"
            [relatedObjects]="relatedObjects"
            [selectedObject]="selectedObject"
            [canAssociateObjects]="canAssociateObjects"
            [isObjectRemoved]="isObjectRemoved"
            [isUsedByTree]="isUsedByTree"
            [surveyVariables]="surveyVariables"
          ></app-question-panel>
        </div>
      </ng-template>
      <br />
    </div>
    <br />
  </form>
</div>
