import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  OnDestroy,
} from "@angular/core";
import {
  Language,
  LanguageText,
  TableType,
  TreatmentLang,
  Decision,
  Pathway,
  GuidelineLangText,
  PathwayLangText,
  QuestionGroup, 
  Treatment,
  Category,
  PersistenceAction
} from "projects/shared-lib/src/public-api";
import { LanguageTextControlUtil } from "../../util/language-text-control.util";
import { LanguageService } from "../../service/language.service";
import { MatDialog } from "@angular/material/dialog";
import { TreatmentLangComponent } from "projects/AdminApp/src/app/admin/treatment-lang/treatment-lang.component";
import { MultipleLanguageGroups } from "../../model/multipleLanguageGroups";
import { GroupLangComponent } from "../../../admin/group-lang/group-lang.component";
import { QuestionGroupUtil } from "../../util/question-group.util";
import { GuidelineLang } from "../../model/guideline-lang.model";
import { GuidelinesLangComponent } from "../../../admin/guidelines-lang/guidelines-lang.component";
import { PathwayLang } from "../../model/pathaway-lang.model";
import { PathwayLangComponent } from "../../../admin/pathway-lang/pathway-lang.component";
import { Subscription } from "rxjs";
import { CategoryLanguageGroups } from "../../model/category-lang.model";
import { UserTypesLangComponent } from "../../../admin/user-types/user-types-lang/user-types-lang.component";
import { CategoryUtil } from "../../util/category.util";

@Component({
  selector: "app-lang-toolbar",
  templateUrl: "./lang-toolbar.component.html",
  styleUrls: ["./lang-toolbar.component.css"],
})
export class LangToolbarComponent implements OnInit, OnChanges {
  @Input() selectedInstance: QuestionGroup | Treatment | Decision | Pathway | Category;
  @Input() instanceType: TableType;
  @Input() isLoading: boolean;
  @Output() afterLanguageWindowClosed: EventEmitter<
    LanguageText | QuestionGroup | GuidelineLangText | PathwayLangText | Category
  > = new EventEmitter<
    LanguageText | QuestionGroup | GuidelineLangText | PathwayLangText | Category
  >();
  public selectedLanguageId: number = -1;
  public supportedLanguages: Language[] = [];
  public languages: Language[] = [];
  private languagesSub: Subscription;

  constructor(
    private languageTextControlUtil: LanguageTextControlUtil,
    private categoryUtil: CategoryUtil,
    public languageService: LanguageService,
    public questionGroupUtility: QuestionGroupUtil,
    public dialog: MatDialog
  ) {
    this.selectedLangChanged = this.selectedLangChanged.bind(this);
    this.onTreatmentWindowClosed = this.onTreatmentWindowClosed.bind(this);
    this.onGroupWindowClosed = this.onGroupWindowClosed.bind(this);
    this.onGuidelineWindowClosed = this.onGuidelineWindowClosed.bind(this);
    this.onPathwayWindowClosed = this.onPathwayWindowClosed.bind(this);
    this.onUpdateLanguages = this.onUpdateLanguages.bind(this);
  }

  private onUpdateLanguages(languages: Language[]) {
    this.languages = languages;
    if (this.languages.length > 0) {
      this.isLoading = false;
      this.updateSupportedLang();
    }
  }

  private subscribeToLenguages() {
    this.languagesSub = this.languageService.languages$.subscribe(
      this.onUpdateLanguages
    );
  }

  ngOnInit() {
    this.isLoading = true;
    this.languageService.initLanguages();
    this.languages = this.languageService.getLanguages();
    this.updateSupportedLang();
    this.subscribeToLenguages();
  }

  ngOnChanges() {
    this.updateSupportedLang();
  }

  private updateSupportedLang() {
    if (this.languages.length > 0) {
      if (!this.isQuestionGroup() && !this.isCategory()) {
        this.supportedLanguages = this.findSupportedLang(
          (this.selectedInstance as QuestionGroup).textList
        );
      } else if(this.isCategory()) {
        this.supportedLanguages = [];
      } else {
        this.supportedLanguages = [];
        let group: QuestionGroup = this.selectedInstance as QuestionGroup;
        group.questions.forEach((question) => {
          let languages = this.findSupportedLang(question.textList);
          languages.forEach((l) => {
            let matched = this.supportedLanguages.find((sl) => sl.id === l.id);
            if (matched === undefined || matched === null) {
              this.supportedLanguages.push(l);
            }
          });
        });
      }
    }
  }

  isCategory(): boolean {
    if ("surveyQuestions" in this.selectedInstance) return true;
    return false;
  }

  changeSelectedLang() {
    this.selectedLangChanged(this.selectedLanguageId, this.instanceType);
  }

  private findLanguage(langId: number, allLang: Language[]): string {
    const langFound: Language = allLang.find((lang) => lang.id === langId);
    return langFound ? langFound.languageDesc : "";
  }

  private findSupportedLang(translations: LanguageText[]): Language[] {
    const translationIds = translations.map((t) => t.languageId);
    const uniqueIds = Array.from(new Set(translationIds));
    const filteredLang: Language[] = uniqueIds.map((id) =>
      this.languages.find((l) => l.id === id)
    );
    return filteredLang;
  }

  showQuestionGroupLang(
    lang: string,
    readable: QuestionGroup,
    editable: QuestionGroup
  ) {
    const configuration = {
      minHeight: "99%",
      minWidth: "99%",
      height: "99%",
      width: "99%",
      panelClass: "treatment-lang-dialog",
      data: new MultipleLanguageGroups(lang, readable, editable),
    };
    const dialogRef = this.dialog.open(GroupLangComponent, configuration);
    dialogRef.afterClosed().subscribe(this.onGroupWindowClosed);
  }

  onTreatmentWindowClosed(result: LanguageText) {
    if (result != undefined) {
      this.afterLanguageWindowClosed.emit(result);
    }
    this.selectedLanguageId = -1;
  }

  onGroupWindowClosed(result: QuestionGroup) {
    if (result != undefined) {
      this.languageTextControlUtil.saveLanguageTextToGroup(
        result,
        this.selectedInstance as QuestionGroup,
        this.selectedLanguageId
      );
      this.afterLanguageWindowClosed.emit(
        this.selectedInstance as QuestionGroup
      );
    }
    this.selectedLanguageId = -1;
  }

  onGuidelineWindowClosed(result: GuidelineLangText) {
    if (result !== undefined) {
      this.afterLanguageWindowClosed.emit(result);
    }
    this.selectedLanguageId = -1;
  }

  onPathwayWindowClosed(result: PathwayLangText) {
    if (result !== undefined) {
      this.afterLanguageWindowClosed.emit(result);
    }
    this.selectedLanguageId = -1;
  }

  private showTreatmentLang(
    lang: string,
    readable: Treatment,
    editable: LanguageText
  ) {
    const treatmentConf = {
      minHeight: "99%",
      minWidth: "99%",
      height: "99%",
      width: "99%",
      panelClass: "treatment-lang-dialog",
      data: new TreatmentLang(lang, readable, editable),
    };
    const dialogRef = this.dialog.open(TreatmentLangComponent, treatmentConf);
    dialogRef.afterClosed().subscribe(this.onTreatmentWindowClosed);
  }

  private showCategoryLang(
    lang: string,
    readable: Category,
    editable: Category
  ) {
    const categoryConf = {
      minHeight: "99%",
      minWidth: "99%",
      height: "99%",
      width: "99%",
      panelClass: "treatment-lang-dialog",
      data: new CategoryLanguageGroups(lang, readable, editable),
    };
    const dialogRef = this.dialog.open(UserTypesLangComponent, categoryConf);
    dialogRef.afterClosed().subscribe(result => {
      this.onCategoryWindowClosed(result);
    });
  }
  
  onCategoryWindowClosed(result) {
    if (result != undefined) {
      this.languageTextControlUtil.saveLanguageTextToCategory(
        result,
        this.selectedInstance as Category,
        this.selectedLanguageId
      );
      // Remove code
      (this.selectedInstance as Category).surveyQuestions.forEach(q => {
        q.modelAction = PersistenceAction.UPDATE;
        q.questionOptions.forEach(o => {
          o.modelAction = PersistenceAction.UPDATE;
        })
      });
      this.afterLanguageWindowClosed.emit(
        this.selectedInstance as Category
      );
    }
    this.selectedLanguageId = -1;
  }

  private showDecisionLang(
    lang: string,
    readable: Decision,
    editable: GuidelineLangText
  ) {
    const guidelinesConf = {
      minHeight: "99%",
      minWidth: "99%",
      height: "99%",
      width: "99%",
      panelClass: "treatment-lang-dialog",
      data: new GuidelineLang(lang, readable, editable),
    };
    const dialogRef = this.dialog.open(GuidelinesLangComponent, guidelinesConf);
    dialogRef.afterClosed().subscribe(this.onGuidelineWindowClosed);
  }

  private showPathwayLang(
    lang: string,
    readable: Pathway,
    editable: PathwayLangText
  ) {
    const pathConf = {
      minHeight: "99%",
      minWidth: "99%",
      height: "99%",
      width: "99%",
      panelClass: "treatment-lang-dialog",
      data: new PathwayLang(lang, readable, editable),
    };
    const dialogRef = this.dialog.open(PathwayLangComponent, pathConf);
    dialogRef.afterClosed().subscribe(this.onPathwayWindowClosed);
  }

  isQuestionGroup(): boolean {
    if ("questions" in this.selectedInstance) return true;
    return false;
  }

  selectedLangChanged(langId: number, instanceType: TableType) {
    this.selectedLanguageId = +langId;
    if (this.selectedLanguageId !== -1) {
      const langDesc = this.findLanguage(
        this.selectedLanguageId,
        this.languages
      );
      if (instanceType === TableType.DECISION) {
        const dec = this.selectedInstance as Decision;
        const inst = this.languageTextControlUtil.getDecisionLangText(
          dec,
          this.selectedLanguageId
        );
        this.showDecisionLang(langDesc, dec, inst);
      } else if (instanceType === TableType.PATHWAY) {
        const path = this.selectedInstance as Pathway;
        const inst = this.languageTextControlUtil.getPathwayLangText(
          path,
          this.selectedLanguageId
        );
        this.showPathwayLang(langDesc, path, inst);
      } else if (instanceType === TableType.QUESTION) {
        const qs = this.selectedInstance as QuestionGroup;
        const inst = this.questionGroupUtility.getQuestionGroupLanguageText(
          qs,
          langId
        );

        this.showQuestionGroupLang(langDesc, qs, inst);
      } else if (instanceType === TableType.TREATMENT) {
        const treat = this.selectedInstance as Treatment;
        const inst = this.languageTextControlUtil.getTreatmentLanguageText(
          treat,
          this.selectedLanguageId
        );
        this.showTreatmentLang(langDesc, treat, inst);
      } else if(instanceType === TableType.SURVEY_QUESTION) {
        const cat = this.selectedInstance as Category;
        const inst = this.categoryUtil.getCategoryLanguageText(
          cat,
          this.selectedLanguageId
        );
        this.showCategoryLang(langDesc, cat, inst);
      }
    }
  }
}
