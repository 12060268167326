import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgOptionHighlightModule } from "@ng-select/ng-option-highlight";
import { AngularEditorModule } from "../../../../angular-editor/src/public-api";
import { NgxGraphModule } from "@swimlane/ngx-graph";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker"
import { FilterByNameOrTagPipe } from "./pipe/filter-by-name-tag.pipe";
import { FilterByLangCode } from "./pipe/filter-by-lang-code";
import { TagValidator } from "./validate/tag.validator";
import { AuthGuard } from "./guard/auth.guard";
import { NumberOnlyDirective } from "./directives/number-only.directive";
import { MatRippleModule } from "@angular/material/core";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatNativeDateModule } from "@angular/material/core"

import { FilterByDescriptionPipe } from "./pipe/filter-by-description.pipe";
import { UploadComponent } from "./components/upload/upload.component";
import { ParseTreatmentTextPipe } from "./pipe/parse-treatment-text";
import { filterGuidelinesByCategoryPipe } from "./pipe/filter-guidelines-by-category.pipe";
import { LangToolbarComponent } from "./components/lang-toolbar/lang-toolbar.component";
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { ShowLangToolbarPipe } from "./pipe/show-lang-toolbar.pipe";
import { ImageInterceptor } from "./interceptor/image.interceptor";
import { FilterByColumnTypePipe } from "./pipe/filter-by-column-type.pipe";
import { filterTableFGroupByQuestionPipe } from "./pipe/filter-table-f-group-by-question.pipe";
import { FilterByTableNamePipe } from "./pipe/filter-by-table-name.pipe";
import { FilterGuidelineByCategoryPipe } from "./pipe/filter-guideline-by-category.pipe";
import { FilterOptionTextByTextPipe } from "./pipe/filter-option-text-by-text.pipe";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { FilterFacilityByNamePipe } from "./pipe/filter-facility-by-name.pipe";

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule,
    NgOptionHighlightModule,
    AngularEditorModule,
    NgxGraphModule,
    CommonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatInputModule,
    DragDropModule,
    MatRadioModule,
    MatIconModule,
    MatListModule,
    MatSelectModule,
    MatTabsModule,
    MatSidenavModule,
    MatDialogModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule,
    NgOptionHighlightModule,
    AngularEditorModule,
    NgxGraphModule,
    CommonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatInputModule,
    DragDropModule,
    MatRadioModule,
    MatIconModule,
    MatListModule,
    MatSelectModule,
    MatTabsModule,
    MatSidenavModule,
    MatDialogModule,
    FilterByNameOrTagPipe,
    FilterByLangCode,
    FilterByDescriptionPipe,
    ParseTreatmentTextPipe,
    filterGuidelinesByCategoryPipe,
    ShowLangToolbarPipe,
    FilterByColumnTypePipe,
    filterTableFGroupByQuestionPipe,
    FilterByTableNamePipe,
    FilterGuidelineByCategoryPipe,
    FilterOptionTextByTextPipe,
    FilterFacilityByNamePipe,
    TagValidator,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    NumberOnlyDirective,
    MatCardModule,
    MatButtonModule,
    MatRippleModule,
    MatToolbarModule,
    MatGridListModule,
    UploadComponent,
    LangToolbarComponent,
    ConfirmationModalComponent,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  declarations: [
    FilterByNameOrTagPipe,
    FilterByLangCode,
    TagValidator,
    NumberOnlyDirective,
    FilterByDescriptionPipe,
    UploadComponent,
    ParseTreatmentTextPipe,
    filterGuidelinesByCategoryPipe,
    ShowLangToolbarPipe,
    FilterByColumnTypePipe,
    filterTableFGroupByQuestionPipe,
    FilterByTableNamePipe,
    FilterGuidelineByCategoryPipe,
    FilterOptionTextByTextPipe,
    FilterFacilityByNamePipe,
    LangToolbarComponent,
    ConfirmationModalComponent,
  ],
  providers: [AuthGuard, { provide: HTTP_INTERCEPTORS, useClass: ImageInterceptor, multi: true }],
})
export class SharedModule {}
