import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { ServiceUtil } from "projects/AdminApp/src/app/shared/util/service-util";
import { InquiryResponse } from "projects/shared-lib/src/public-api";

@Injectable({ providedIn: "root" })
export class ScheduledNotificationService {
    private subscriptionUrl = `${this.serviceUtil.rootUrl}/ScheduledNotification`;
  
    constructor(private serviceUtil: ServiceUtil, private http: HttpClient) {
      this.serviceUtil = serviceUtil;
      this.http = http;
    }
  
    fetch() {
      const url = `${this.subscriptionUrl}/Fetch`;
      return this.http
        .get<InquiryResponse<any>>(
          url,
          this.serviceUtil.getCommonAdminHeadersWithAuth()
        )
        .pipe(
          catchError(this.serviceUtil.handleError),
          tap(this.serviceUtil.handleResponse)
        );
    }

    save(notification: any) {
      const url = `${this.subscriptionUrl}/Save`;
      return this.http
        .post<InquiryResponse<any>>(
          url,
          notification,
          this.serviceUtil.getCommonAdminHeadersWithAuth()
        )
        .pipe(
          catchError(this.serviceUtil.handleError),
          tap(this.serviceUtil.handleResponse)
        );
    }
}
