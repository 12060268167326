import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { QuestionGroup, ScoreToNextObject, Treatment, PersistenceAction } 
        from 'projects/shared-lib/src/public-api';
import { GraphNode } from '../util/graphnode';
import { Edge } from '@swimlane/ngx-graph';
import { AdminPathway } from '../model/admin-pathway.model';

export class VisibleNodes{
    nodes: GraphNode[] = [];
    edges: Edge[] = [];
}

@Injectable({providedIn: 'root'})
export class GraphService{
    mapGroup: Map<number,GraphNode> = new Map<number, GraphNode>();
    mapTreatment: Map<number,GraphNode> = new Map<number, GraphNode>();
    existingMap: Map<string,GraphNode> = new Map<string, GraphNode>();

    constructor()
    {
    }

    createQuestionGroupGraphNodes(groups?: QuestionGroup[]){
        for(let g of groups)
        {
            let node =  new GraphNode();
            node.populateGroupNode(g);
            this.mapGroup.set(g.id,node);
        } 
    }

    createTreatmentGraphNodes(treatments?: Treatment[]){
        for(let t of treatments)
        {
            let node = new GraphNode();
            node.populateTreatmentNode(t);
            this.mapTreatment.set(t.id,node);
        } 
    }

    createTreeForPathway(pathway: AdminPathway)
    {
        this.createQuestionGroupGraphNodes(pathway.questionGroupList);
        this.createTreatmentGraphNodes(pathway.treatmentList);   
        this.existingMap.clear();
        let n: GraphNode = this.mapGroup.get(pathway.startingQuestionGroupId);
        pathway.nodes = new Array();
        pathway.edges = new Array();
        if(n == undefined || n.data == undefined)
        {
            return;
        }
        let group: QuestionGroup = n.data.group;
        this.existingMap.set(n.id,n);
        pathway.nodes.push(n);
        //this.existingMap.set(pathway.groupId,n);
        this.buildTree(n,group,pathway);
    }

    buildTree(parent: GraphNode,group: QuestionGroup,pathway: AdminPathway)
    {
        if(group.isScoreBased)
        {
            this.populateChildNodes(pathway,parent,group.scoreToNextObjectList);
        }
        else{
            for(let question of group.questions){
                if(question.modelAction == PersistenceAction.DELETE)//delete
                    continue;
                for(let option of question.options){
                    if(option.modelAction == PersistenceAction.DELETE)//delete
                        continue;
                    let connectorNode = new GraphNode();
                    connectorNode.data.parentNode = parent;
                    connectorNode.populateOptionNode(option);
                    pathway.nodes.push(connectorNode);
                    if(parent.data.childNodes.get(connectorNode.id) == null)
                        parent.data.childNodes.set(connectorNode.id,connectorNode);
                    if(option.treatmentId != 0 && option.nextQuestionGroupId != 0)
                    {
                        return throwError("Bad configuration");
                    }
                    if(option.treatmentId == 0)
                        this.createChildNodeByGroupId(pathway,parent,connectorNode,option.nextQuestionGroupId);
                    else
                        this.createChildNodeByTreatmentId(pathway,parent,connectorNode,option.treatmentId);
                }
            }
        }
    }

    populateChildNodes(pathway: AdminPathway,parent: GraphNode,nodes: ScoreToNextObject[])
    {
        if(nodes == null)
            return;
        for(let item of nodes )
        {
            if(item.modelAction == PersistenceAction.DELETE)//delete
                continue;
            let connectorNode = new GraphNode();
            connectorNode.populateScoreNode(item);
            connectorNode.data.parentNode = parent;
            pathway.nodes.push(connectorNode);
            if(parent.data.childNodes.get(connectorNode.id) == null)
                parent.data.childNodes.set(connectorNode.id,connectorNode);
            if(item.treatmentId != 0 && item.nextQuestionGroupId != 0)
            {
                return throwError("Bad configuration");
            }
            if(item.treatmentId == 0)
                this.createChildNodeByGroupId(pathway,parent,connectorNode,item.nextQuestionGroupId);
            else
                this.createChildNodeByTreatmentId(pathway,parent,connectorNode,item.treatmentId);
        }
    }

    createChildNodeByTreatmentId(pathway: AdminPathway,parent: GraphNode,connectorNode: GraphNode,id: number)
    {
        if(id != null && id != undefined && id != 0){
            let tNode = this.mapTreatment.get(id);
            if(tNode === undefined)
            {
                console.log('Treatment not found with id: ' + id);
                return;
            }
            let childNode = this.existingMap.get(tNode.id);
            if(childNode == null || childNode == undefined){
                childNode = tNode;
                pathway.nodes.push(tNode);
                parent.data.childNodes.set(tNode.id,tNode);
                tNode.data.parentNode = parent;
                this.existingMap.set(tNode.id,tNode);
            }
            this.createEdges(pathway,parent,connectorNode,childNode);
        }
        return null;
    }

    createChildNodeByGroupId(pathway: AdminPathway,parent: GraphNode,connectorNode: GraphNode,id: number)
    {
        if(id != null && id != undefined && id != 0)
        {
            let node = this.mapGroup.get(id);
            if(node === undefined)
            {
                console.log('Group not found with id: ' + id);
                return;
            }
            let childNode = this.existingMap.get(node.id);
            if(childNode == null || childNode == undefined){
                childNode = node;
                this.existingMap.set(node.id,node);
                pathway.nodes.push(node);
                node.data.parentNode = parent;
                parent.data.childNodes.set(node.id,node);
                this.buildTree(childNode,childNode.data.group,pathway);
            }
            this.createEdges(pathway,parent,connectorNode,childNode);
        }
        else
        {
            this.createEdges(pathway,parent,connectorNode,null);
        }
        return null;
    }

    createEdges(pathway: AdminPathway,parent: GraphNode,connectorNode: GraphNode,childNode: GraphNode)
    {
        if(childNode != null)
        {
            if(parent.data.childNodes.get(childNode.id) == null)
            {
                parent.data.childNodes.set(childNode.id,childNode);
                childNode.data.parentNode = parent;
            }
        }
        let e1 = this.addEdge(pathway,parent,connectorNode,connectorNode.label);
        this.addEdgeToChildMap(e1,parent);
        if(childNode != null)
        {
            let e2 = this.addEdge(pathway,connectorNode,childNode,connectorNode.label);
            this.addEdgeToChildMap(e2,parent);
        }
    }

    addEdgeToChildMap(edge: Edge, parent: GraphNode){
        let resultMap = parent.data.childEdges.get(edge.source);
        if(resultMap == null){
            parent.data.childEdges.set(edge.source,new Map<string,Edge>());
            resultMap = parent.data.childEdges.get(edge.source);
        }
        if(resultMap.get(edge.target) == null)
            resultMap.set(edge.target,edge);
    }

    addEdge(pathway: AdminPathway,parent: GraphNode,child: GraphNode,text:string)
        : Edge
    {
        const edge1: Edge =   
        {
            source: parent.id,
            target: child.id,
            label: '',
            data: 
            {
              linkText: text,
            }
        }
         pathway.edges.push(edge1);
         return edge1;
    }

    updateShowFlags(node: GraphNode) : VisibleNodes{
        node.data.toggleShow();
        let returnObject = new VisibleNodes();
        let root = this.findRoot(node);
        returnObject.nodes.push(root);
        this.addCurrentNodeAndChildren(root,returnObject);
        return returnObject;
    }

    findRoot(node: GraphNode) : GraphNode
    {
        let root = node;
        while(root != null && root.data.parentNode != null)
            root = root.data.parentNode;
        return root;
    }

    addCurrentNodeAndChildren(node: GraphNode, returnObject : VisibleNodes){
        if(node.data.show){
            for(let child of node.data.childNodes.values())
            {
                returnObject.nodes.push(child);
                this.addCurrentNodeAndChildren(child, returnObject);
            }
            for(let map of node.data.childEdges.values()){
                for(let edge of map.values()){
                    returnObject.edges.push(edge);
                }
            }
        }
    }

    createOtherTrees(groups: QuestionGroup[], treatments: Treatment[],
         nodes: GraphNode[], edges: Edge[]) 
    {
        let pathway: AdminPathway = new AdminPathway();
        this.createQuestionGroupGraphNodes(groups);
        this.createTreatmentGraphNodes(treatments);
        let n: GraphNode = this.mapGroup.get(groups[0].id);
        let group: QuestionGroup = n.data.group;
        pathway.nodes = new Array();
        pathway.edges = new Array();
        this.existingMap.set(n.id,n);
        pathway.nodes.push(n);
        //this.existingMap.set(pathway.groupId,n);
        this.buildTree(n,group,pathway);
        pathway.nodes.forEach(node => {
            nodes.push(node);
        });
        pathway.edges.forEach(edge => {
            edges.push(edge);
        });
    }
}
  