import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { ServiceUtil } from "projects/AdminApp/src/app/shared/util/service-util";
import { InquiryRequest, PathwayStatus, InquiryResponse, ImportInstitutionViewModel } from "projects/shared-lib/src/public-api";

@Injectable({ providedIn: "root" })
export class FileService {
  private downloadFileUrl = `${this.serviceUtil.rootUrl}/export`;
  private importUrl = `${this.serviceUtil.rootUrl}/import`;

  constructor(private serviceUtil: ServiceUtil, private http: HttpClient) { }

  getPathwayExport(id: number) {
    let url = `${this.downloadFileUrl}/getPathwayData?pathwayId=`;
    url += id;
    return this.http
      .get<InquiryResponse<string>>(
        url,
        this.serviceUtil.getCommonAdminHeadersWithAuth()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(res => res)
      );
  }

  importPathway(data) {
    let url = `${this.importUrl}/loadPathway`;
    return this.http
    .post<InquiryResponse<any>>(
      url,
      data,
      this.serviceUtil.getCommonAdminHeadersWithAuth())
      .pipe(catchError(this.serviceUtil.handleError),
      tap(res => res)
      );
  }

  importInstitutions(data: ImportInstitutionViewModel) {
    const url = `${this.importUrl}/institutions`;
    return this.http
      .post<InquiryResponse<any>>(
        url,
        data,
        this.serviceUtil.getCommonAdminHeadersWithAuth()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(result => result)
      );
  }

}
