<div class="guideline-lang-container">
  <div class="guideline-header color-black">English(Read Only)</div>
  <div class="guideline-header color-black">{{ languageDesc }}</div>
  <div class="guideline-section text-left bg-primary">
    <label class="color-white">Name</label>
    <mat-card class="guideline-content">
      <mat-card-content>
        <div [innerHTML]="readableGuideline.name | sanitizer: 'html'"></div>
      </mat-card-content>
    </mat-card>
    <br />
    <label class="color-white">Description</label>
    <mat-card class="guideline-content">
      <mat-card-content>
        <div
          [innerHTML]="readableGuideline.description | sanitizer: 'html'"
        ></div>
      </mat-card-content>
    </mat-card>
    <br />
    <label class="color-white">Disclaimer</label>
    <mat-card class="guideline-content">
      <mat-card-content>
        <div
          [innerHTML]="readableGuideline.disclaimer | sanitizer: 'html'"
        ></div>
      </mat-card-content>
    </mat-card>
    <br />
    <label class="color-white">Additional information</label>
    <mat-card class="guideline-content">
      <mat-card-content>
        <div
          [innerHTML]="
            readableGuideline.additionalInformation | sanitizer: 'html'
          "
        ></div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="guideline-section text-left bg-primary">
    <form (ngSubmit)="onSave(form)" #form="ngForm" name="form">
      <mat-dialog-actions>
        <div class="col-12">
          <div class="btn-toolbar justify-content-between" role="toolbar">
            <div class="btn-group" role="group"></div>
            <div class="btn-toolbar" role="group">
              <button
                type="button"
                class="btn btn-primary"
                (click)="onCancel()"
              >
                Cancel
              </button>
              &nbsp;&nbsp;
              <button class="btn btn-white" cdkFocusInitial type="submit">
                Save
              </button>
            </div>
          </div>
          <br />
        </div>
      </mat-dialog-actions>
      <div class="editor color-white">
        <div class="row">
          <div class="col-sm-12">
            <label>Name *</label>
            <br />
            <input
              class="form-control"
              id="editable_lang_decision_name"
              name="editable_lang_decision_name"
              placeholder="Name"
              [(ngModel)]="editableGuideline.name.value"
              maxlength="{{ validationControlUtil.pathwayNameLength }}"
              required
              #name="ngModel"
            />
          </div>
          <div class="col-sm-12" *ngIf="submitted && form.invalid">
            <p *ngIf="name.errors.required">
              This field is required
            </p>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-sm-12">
            <label>Description *</label>
          </div>
        </div>
        <div class="color-dark bg-white">
          <angular-editor
            id="editable_lang_decision_desc"
            name="editable_lang_decision_desc"
            [(ngModel)]="editableGuideline.description.value"
            [config]="config"
            maxlength="{{ validationControlUtil.textLength }}"
            required
            #description="ngModel"
            (errorMsg)="validationControlUtil.showError($event)"
          >
          </angular-editor>
        </div>
        <div *ngIf="editableGuideline && editableGuideline?.description && editableGuideline?.description.value" class="col-12 text-right pt-1">
          <label>Characters count: {{ editableGuideline.description.value?.length }}</label>
        </div>
        <div class="row">
          <div class="col-sm-12" *ngIf="submitted && form.invalid">
            <div *ngIf="description.errors.required">
              This field is required
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-sm-12">
            <label>Disclaimer *</label>
          </div>
        </div>
        <div class="color-dark bg-white">
          <angular-editor
            id="editable_lang_decision_disc"
            name="editable_lang_decision_disc"
            [(ngModel)]="editableGuideline.disclaimer.value"
            [config]="config"
            maxlength="{{ validationControlUtil.textLength }}"
            required
            #disclaimer="ngModel"
            (errorMsg)="validationControlUtil.showError($event)"
          >
          </angular-editor>
        </div>
        <div *ngIf="editableGuideline && editableGuideline?.disclaimer && editableGuideline?.disclaimer.value" class="col-12 text-right pt-1">
          <label>Characters count: {{ editableGuideline.disclaimer?.value.length }}</label>
        </div>
        <div class="row">
          <div class="col-sm-12" *ngIf="submitted && form.invalid">
            <p *ngIf="disclaimer.errors.required">
              This field is required
            </p>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-sm-12">
            <label>Additional information</label>
          </div>
        </div>
        <div class="color-dark bg-white">
          <angular-editor
            id="editable_lang_decision_add_info"
            name="editable_lang_decision_add_info"
            [(ngModel)]="editableGuideline.addInformation.value"
            [config]="config"
            maxlength="{{ validationControlUtil.additionalInfoTextLength }}"
            (errorMsg)="validationControlUtil.showError($event)"
          >
          </angular-editor>
        </div>
        <div *ngIf="editableGuideline && editableGuideline?.addInformation && editableGuideline?.addInformation.value" class="col-12 text-right pt-1">
          <label>Characters count: {{ editableGuideline.addInformation.value?.length }}</label>
        </div>
        <br />
      </div>
    </form>
  </div>
</div>
