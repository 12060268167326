import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { Observable } from "rxjs";

import { AuthService } from "projects/AdminApp/src/app/shared/service/auth.service";
import { AuthControlUtil } from "projects/AdminApp/src/app/shared/util/auth-control.util";
import { InquiryResponse, Message } from "projects/shared-lib/src/public-api";
import { LoginRequest } from "projects/AdminApp/src/app/shared/model/login-request.model";
import { SessionInfo } from "projects/AdminApp/src/app/shared/model/session-info.model";

@Component({
  selector: "app-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.css"]
})
export class AuthComponent implements OnInit {
  private redirectTo = "admin/guidelines";
  public isLoading = false;
  public submitted = false;
  public error: string = null;
  public warnings: Message[] = [];
  public loginForm: FormGroup;

  constructor(
    private authService: AuthService,
    private authControlUtil: AuthControlUtil,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.login = this.login.bind(this);
    this.loginSuccess = this.loginSuccess.bind(this);
    this.loginError = this.loginError.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  private loginSuccess(response: InquiryResponse<SessionInfo>) {
    this.isLoading = false;
    if (response.operationSuccess) {
      this.router.navigate([this.redirectTo]);
    } else {
      this.warnings = response.messages;
    }
  }

  private loginError(error: string) {
    this.isLoading = false;
    this.error = error;
  }

  private login(loginRequest: LoginRequest) {
    let authObs: Observable<InquiryResponse<SessionInfo>>;
    authObs = this.authService.login(loginRequest);
    authObs.subscribe(this.loginSuccess, this.loginError);
  }

  public get f() {
    return this.loginForm.controls;
  }

  public onSubmit() {
    const username = this.f.username.value;
    const password = this.f.password.value;
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.isLoading = true;
    const loginReq = this.authControlUtil.toLoginRequest(username, password);
    this.login(loginReq);
  }

  public ngOnInit() {
    const loginFormGroup = this.authControlUtil.toLoginFormGroup();
    this.loginForm = this.formBuilder.group(loginFormGroup);
  }
}
