import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NgForm, FormGroup } from "@angular/forms";
import {
  QuestionGroup,
  RelatedObject,
  SpinnerService,
  MessageType,
  PersistenceAction,
  ToasterService,
  TableType,
  LocalStorageUtil,
  InquiryResponse,
  Pathway,
  OptionVariable
} from "projects/shared-lib/src/public-api";
import { QuestionGroupService } from "projects/AdminApp/src/app/shared/service/questiongroup.service";
import { DialogComponent } from "projects/AdminApp/src/app/core/dialog/dialog.component";
import { Dialog } from "projects/AdminApp/src/app/core/dialog/dialog.model";
import { ScoreListValidator } from "projects/AdminApp/src/app/shared/validate/scoreList.validator";
import { ValidationControlUtil } from "projects/AdminApp/src/app/shared/util/validation.util";
import { Subscription } from "rxjs";
import { QuestionGroupUtil } from "../../../shared/util/question-group.util";
import { OptionVariableUtility } from "../../../shared/util/option-variable.util";
import { PathwayStatusEnum } from "../../../shared/constant/pathway-status-enum.constant";

@Component({
  selector: "app-newquestion",
  templateUrl: "./newquestion.component.html",
  styleUrls: ["./newquestion.component.css"],
})
export class NewquestionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isUsedByTree: boolean;
  @Input() questionGroup: QuestionGroup;
  @Input() canAssociateObjects: RelatedObject[];
  @Input() surveyVariables: string[];
  @Input() pathwaySelected: Pathway;//for propagting variables.
  
  @Output() afterSaveComplete = new EventEmitter();
  @Output() afterCancelClicked = new EventEmitter();


  languageGroup: QuestionGroup = null;
  relatedObjects: RelatedObject[] = [];
  selectedObject: RelatedObject;

  isObjectRemoved: boolean;
  public tableType: TableType = TableType.QUESTION;

  public initialQsGroup: QuestionGroup = null;
  private subscriptions: Subscription[] = [];

  constructor(
    private toaster: ToasterService,
    public dialog: MatDialog,
    private service: QuestionGroupService,
    private spinnerService: SpinnerService,
    private scoreListValidator: ScoreListValidator,
    private qsGroupUtil: QuestionGroupUtil,
    public localStorageUtil: LocalStorageUtil,
    public validationControlUtil: ValidationControlUtil,
    private optionVariableUtil: OptionVariableUtility
  ) {
    this.getQsGroupByIdSuccess = this.getQsGroupByIdSuccess.bind(this);
    this.getQsGroupByIdError = this.getQsGroupByIdError.bind(this);
    this.saveQsGroupSuccess = this.saveQsGroupSuccess.bind(this);
    this.saveQsGroupError = this.saveQsGroupError.bind(this);
    this.isObjectRemoved = false;
  }


  private createNewDialog(isUsedByTree: boolean): Dialog {
    const title =  "Updating template will update instances created by the template in the decision trees";
    const content =  "- Cancel to cancel<br/> - Continue to update template and instances<br/>";
    const option_1 = "Cancel";
    const option_2 =  "Continue";
    const option_3 =  "";// future 
    return new Dialog(title, content, option_1, option_2, option_3);
  }


  private showDialog(questionGroup: QuestionGroup) {
    const isNotUsedByTree = !this.isUsedByTree;
    const dialog = this.createNewDialog(this.isUsedByTree);
    const dialogConf = { data: dialog };
    const dialogRef = this.dialog.open(DialogComponent, dialogConf);

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        if (result === false) {
          this.toaster.show(MessageType.SYSTEM_ERROR, "Not implemented.");
          return;
        }
        if(!this.isUsedByTree){
          this.optionVariableUtil.copyOptionVariablesToOtherOptions(questionGroup,this.pathwaySelected);
        }
        this.saveForm(questionGroup);
      }
    });
  }


  private getQsGroupByIdSuccess(infoMsg: string) {
    return (response: InquiryResponse<any>) => {
      this.spinnerService.hide();
      if (response.operationSuccess) {
        this.questionGroup.updatedDate = response.first.updatedDate;
        this.questionGroup.questions = [...response.first.questions];
        this.questionGroup.scoreToNextObjectList = [...response.first.scoreToNextObjectList];
        this.afterSaveComplete.emit();
        this.toaster.show(MessageType.INFORMATIONAL, infoMsg);
      } else {
        this.toaster.show(response.messages[0].type, response.messages[0].text);
      }
    };
  }

  private getQsGroupByIdError(error: any): void {
    this.toaster.show(MessageType.SYSTEM_ERROR, error);
  }

  private saveQsGroupSuccess(response: any): void {
    const infoMsg = "Questions saved successfully";
    const errorMsg = "Something was wrong";
    this.spinnerService.hide();
    if (response.operationSuccess) {
      if (this.isUsedByTree && this.languageGroup != null) {
        this.localStorageUtil.deleteQsGroup();
        this.reloadGroup(infoMsg);
      } else {
        this.afterSaveComplete.emit(true);
        this.toaster.show(MessageType.INFORMATIONAL, infoMsg);
      }
    } else if (response.messages.length > 0) {
      this.toaster.show(response.messages[0].type, response.messages[0].text);
    } else {
      this.toaster.show(MessageType.SYSTEM_ERROR, errorMsg);
    }
  }

  private saveQsGroupError(error: any): void {
    this.toaster.show(MessageType.SYSTEM_ERROR, error);
  }

  private saveForm(questionGroup: QuestionGroup) {
    this.spinnerService.show();
    this.buildOptionVariables(questionGroup);
    delete questionGroup.isSaveAs;
    this.subscriptions.push( this.service.saveQuestionGroup(questionGroup).subscribe(this.saveQsGroupSuccess, this.saveQsGroupError));
  }

  private buildOptionVariables(questionGroup: QuestionGroup) {
    questionGroup.questions.forEach(q => {
      q.options.forEach(o => {
        if(o.variables){
          o.variables.forEach(v => {
            if(o.variableList && !o.variableList.find(vl => vl.text == v)) {
              var optVar = new OptionVariable();
              optVar.text = v;
              optVar.id = -1;
              optVar.optionId = o.id;
              optVar.modelAction = PersistenceAction.INSERT;
              o.variableList.push(optVar);
            }
          });
        }
        if(o.variableList) {
          o.variableList.forEach(optVar => {
            if(o.variables && !o.variables.find(v => v == optVar.text)){
              optVar.modelAction = PersistenceAction.DELETE;
            }
          })
        }
      });
    })
  }

  private reloadGroup(infoMsg: string) {
    if (this.isUsedByTree && this.languageGroup != null) {
      this.spinnerService.show();
      this.subscriptions.push(this.service.retrieveQuestionGroupById(this.questionGroup.id)
      .subscribe(this.getQsGroupByIdSuccess(infoMsg), this.getQsGroupByIdError));
    }
    this.languageGroup = null;
  }

  public saveLanguageText(result: QuestionGroup) {
    const infoMsg = "Save this question group to persist the changes"
    if (result !== undefined) {
      result.modelAction = PersistenceAction.UPDATE;
      this.languageGroup = result;
      const isNotSaveAs = !result.isSaveAs;
      if (isNotSaveAs) {
        this.showDialog(result);
      } else {
        this.toaster.show(MessageType.INFORMATIONAL, infoMsg);
      }
    }
  }

  private isNotUndefined(prop: any): boolean {
    return prop !== undefined ? true : false;
  }

  private isDifferentFromZero(value: number): boolean {
    return value !== 0 ? true : false;
  }

  private isNotInsertActionFlag(modelAction: PersistenceAction): boolean {
    return modelAction !== PersistenceAction.INSERT;
  }

  private isGreaterThanZero(value: number): boolean {
    return value > 0 ? true : false;
  }

  private isTouched(removed: boolean, modelAction: PersistenceAction): boolean {
    const differentFromNone = modelAction !== PersistenceAction.NONE ? true : false;
    if (removed || differentFromNone) {
      return true;
    }
    return false;
  }

  private isTemplateQsGroup(qsGroupId: number): boolean {
    const notUndefined = this.isNotUndefined(qsGroupId);
    const differentFromZero = this.isDifferentFromZero(qsGroupId);
    return notUndefined && differentFromZero ? true : false;
  }

  private saveQsGroup(qsGroup: QuestionGroup, isUsedByTree: boolean, isRemoved: boolean): void {
    const templateQsGroup = this.isTemplateQsGroup(qsGroup?.templateQuestionGroupId);
    const touched = this.isTouched(isRemoved, this.questionGroup.modelAction);
    const notInsertActionFlag = this.isNotInsertActionFlag(qsGroup.modelAction);
    const greaterThanZero = this.isGreaterThanZero(qsGroup.id);
    const isNotUsedByTree = !isUsedByTree;
    if (templateQsGroup && touched && notInsertActionFlag) {
      qsGroup.modelAction = PersistenceAction.UPDATE;
    }
    if (templateQsGroup) {
      this.showDialog(qsGroup);
    } else if (isNotUsedByTree && greaterThanZero) {
      this.showDialog(qsGroup);
    } else {
      this.saveForm(qsGroup);
    }
  }

  private saveQsGroupAs(qsGroup: QuestionGroup): void {
    const filteredQsGroup = this.qsGroupUtil.filterActiveQsGroupItems(qsGroup);
    const saveAsQsGroup = this.qsGroupUtil.setForSaveAs(filteredQsGroup);
    this.saveForm(saveAsQsGroup);
  }

  private showValidationMsg(message: string): void {
    this.toaster.show(MessageType.VALIDATION, message);
  }

  private showWarningMsg(message: string): void {
    this.toaster.show(MessageType.WARNING, message);
  }

  private getValidationMsg(qpForm: FormGroup, spForm: FormGroup): string {
    let valMsg = this.validationControlUtil.getErrorMsg(qpForm.controls);
    if (spForm !== undefined) {
      valMsg += this.validationControlUtil.getErrorMsg(spForm.controls);
    }
    return valMsg;
  }


  private isSaveAsNotValid(qsGroupForm: NgForm, qsGroup: QuestionGroup, initialQsGroup: QuestionGroup): boolean {
    const tagMsg = "All tags must be unique";
    const isNotValid = this.isSaveNotValid(qsGroupForm);
    if (isNotValid) { return true; }
    const filteredQsGroup = this.qsGroupUtil.filterActiveQsGroupItems(qsGroup);
    const notHasUniqueTags = this.qsGroupUtil.notHasUniqueTags(filteredQsGroup, initialQsGroup);
    if (notHasUniqueTags) {
      this.showValidationMsg(tagMsg);
      return true;
    }
    return false;
  }

  private isSaveNotValid(qsGroupForm: NgForm): boolean {
    const noChangesMsg = "No changes detected";
    const qpForm = qsGroupForm.controls.questionPanel as FormGroup;
    const spForm = qsGroupForm.controls.scorePanel as FormGroup;
    const scoreListVal = this.scoreListValidator.validate(this.questionGroup);
    if (qsGroupForm.invalid) {
      const message = this.getValidationMsg(qpForm, spForm);
      this.showValidationMsg(message);
      return true;
    }
    if (scoreListVal !== null) {
      this.showValidationMsg(scoreListVal[1]);
      return true;
    }
    if (!qsGroupForm.dirty) {
      this.showWarningMsg(noChangesMsg);
      return true;
    }

    return false;
  }

  private isNotValidQsGroup(qsGroupForm: NgForm, qsGroup: QuestionGroup, initialQsGroup: QuestionGroup): boolean {
   if (qsGroup.isSaveAs) {
     return this.isSaveAsNotValid(qsGroupForm, qsGroup, initialQsGroup);
   } else {
     return this.isSaveNotValid(qsGroupForm);
   }
  }

  public onSave(qsGroupForm: NgForm, qsGroup: QuestionGroup, initialQsGroup: QuestionGroup) {
    const isNotValid = this.isNotValidQsGroup(qsGroupForm, qsGroup, initialQsGroup);
    if (isNotValid) { return; }
    if (qsGroup.isSaveAs) {
      this.saveQsGroupAs(qsGroup);
    } else {
      if (qsGroup.modelAction == PersistenceAction.NONE)//finsihed checking for dirty flag.
        qsGroup.modelAction = PersistenceAction.UPDATE;
      this.saveQsGroup(qsGroup, this.isUsedByTree, this.isObjectRemoved);
    }
  }

  public onCancel() {
    if (this.afterCancelClicked !== undefined) {
      this.afterCancelClicked.emit();
    }
    this.localStorageUtil.deleteQsGroup();
  }

  private updateRelatedObjects(): void {
    this.relatedObjects.length = 0;
    for (let i = 0; i < this.canAssociateObjects.length; i++) {
      if (this.canAssociateObjects[i].isQuestionGroup) {
        if (this.canAssociateObjects[i].id !== this.questionGroup.id) {
          this.relatedObjects.push(this.canAssociateObjects[i]);
        }
      } else {
        this.relatedObjects.push(this.canAssociateObjects[i]);
      }
    }
  }

  ngOnInit() {}

  // update qsGroup obj after updating component with new data
  ngOnChanges(changes: SimpleChanges): void {
    this.updateRelatedObjects();
  }

  ngOnDestroy() {
    if (this.subscriptions.length) {
      this.subscriptions.forEach((s) => {
        s.unsubscribe();
      });
    }
  }

  showSaveButton(){
    if(this.questionGroup.isSaveAs)
      return false;
    if(this.pathwaySelected === undefined)//Template screen
      return true;
    if(this.pathwaySelected !== undefined && this.pathwaySelected.pathwayStatusId !== 4)
      return true;
    return false;
  }
}
