import { Pipe, PipeTransform } from "@angular/core";
import { Facility } from "projects/shared-lib/src/lib/model/facility.model";

@Pipe({ name: "filterFacilityByName" })
export class FilterFacilityByNamePipe implements PipeTransform {

  private containValue(obj: any, key: string, value: string): boolean {
    if (obj[key].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
      return true;
    }
    return false;
  }

  private filterByProps(firstKey: string, value: string) {
    return element => {
      return (
        this.containValue(element, firstKey, value)
      );
    };
  }

  transform(facilities: Facility[], value: string) {
    if (value === undefined || value === null || value.length === 0) {
      return [...facilities];
    } else {
      return facilities.filter(this.filterByProps("name", value.toLowerCase()));
    }

  }
}
