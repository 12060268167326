import {
  QuestionGroup,
  ScoreToNextObject,
  Option,
  RelatedObject,
  Treatment,
  Pathway,
} from "projects/shared-lib/src/lib/model/decision.model";
import { Injectable } from "@angular/core";
import { TreatmentUtil } from "./treatment.util";
import { QuestionGroupUtil } from "./question-group.util";

@Injectable({ providedIn: "root" })
export class RelatedObjectUtility {
  nextId: number = -1;
  constructor(
    private treatmentUtility: TreatmentUtil,
    private questionGroupUtility: QuestionGroupUtil
  ) {}

  private addEmptyObject(relatedObjects: RelatedObject[]) {
    var emptyObject = new RelatedObject();
    emptyObject.SetToEmpty();
    relatedObjects.push(emptyObject);
  }

  combineRelatedObjectArrays(
    array1: RelatedObject[],
    array2: RelatedObject[]
  ): RelatedObject[] {
    let relatedObjects = new Array();
    //Empty related
    this.addEmptyObject(relatedObjects);
    array1.forEach((group) => {
      relatedObjects.push(group);
    });
    array2.forEach((treatment) => {
      relatedObjects.push(treatment);
    });
    return relatedObjects;
  }

  convertGroupsTreatmentsToRelatedObjects(
    groups: QuestionGroup[],
    treatments: Treatment[],
    includeEmptyObject: boolean,
    isTemplate: boolean
  ): RelatedObject[] {
    let array = new Array();
    if (includeEmptyObject) this.addEmptyObject(array);
    groups.forEach((group) => {
      let item = this.convertQuestionGroupToRelatedObject(group, isTemplate);
      array.push(item);
    });
    treatments.forEach((treatment) => {
      let item = this.convertTreatmentToRelatedObject(treatment, isTemplate);
      array.push(item);
    });
    return array;
  }

  populateRelatedObjectsOnPathwayGroups(pathway: Pathway){
    let resultObjects = this.convertGroupsTreatmentsToRelatedObjects(pathway.questionGroupList,pathway.treatmentList,false,false);
    this.populateNextIdsOnGroups(pathway.questionGroupList,resultObjects);
  }

  populateRelatedObjectsOnGroups(
    groups: QuestionGroup[],
    relatedObjects: RelatedObject[]
  ) {
    groups.forEach((group) => {
      group.scoreToNextObjectList.forEach((score) => {
        this.populateRelatedObject(score, relatedObjects);
      });
      group.questions.forEach((question) => {
        question.options.forEach((option) => {
          this.populateRelatedObject(option, relatedObjects);
        });
      });
    });
  }

  populateNextIdsOnGroups(
    groups: QuestionGroup[],
    canAssociateObjects: RelatedObject[]
  ) {
    groups.forEach((group) => {
      group.scoreToNextObjectList.forEach((score) => {
        this.populateRelatedObject(score, canAssociateObjects);
      });
      group.questions.forEach((question) => {
        question.options.forEach((option) => {
          this.populateRelatedObject(option, canAssociateObjects);
        });
      });
    });
  }

  populateRelatedObject(
    instance: Option | ScoreToNextObject,
    relatedObjects: RelatedObject[]
  ) {
    if (
      instance.nextQuestionGroupId != null &&
      instance.nextQuestionGroupId != 0
    ) {
      for (let i = 0; i < relatedObjects.length; i++) {
        if (
          relatedObjects[i].isQuestionGroup &&
          relatedObjects[i].id == instance.nextQuestionGroupId
        ) {
          instance.relatedObject = relatedObjects[i];
          break;
        }
      }
    } else if (instance.treatmentId != null && instance.treatmentId != 0) {
      for (let i = 0; i < relatedObjects.length; i++) {
        if (
          !relatedObjects[i].isQuestionGroup &&
          relatedObjects[i].id == instance.treatmentId
        ) {
          instance.relatedObject = relatedObjects[i];
          break;
        }
      }
    }
  }

  getGroupFromRelatedObject(
    selectedObject: RelatedObject,
    groups: QuestionGroup[]
  ): QuestionGroup {
    return groups.find((element) => {
      if (element.id == selectedObject.id) {
        return element;
      }
    });
  }

  convertQuestionGroupToRelatedObject(
    group: QuestionGroup,
    isTemplate: boolean
  ): RelatedObject {
    let associated = new RelatedObject();
    associated.id = group.id;
    associated.tag = (group.questions[0]) ? group.questions[0].tag : '';
    associated.text = (group.questions[0]) ? group.questions[0].text : '';
    associated.isQuestionGroup = true;
    associated.isTemplate = isTemplate;
    return associated;
  }

  convertTreatmentToRelatedObject(
    treatment: Treatment,
    isTemplate: boolean
  ): RelatedObject {
    let associated = new RelatedObject();
    associated.id = treatment.id;
    associated.tag = treatment.tag;
    associated.text = this.treatmentUtility.getDropdownText(treatment);
    associated.isQuestionGroup = false;
    associated.isTemplate = isTemplate;
    return associated;
  }

  getTreatmentFromRelatedObject(
    selectedObject: RelatedObject,
    treatments: Treatment[]
  ): Treatment {
    return treatments.find((element) => {
      if (element.id == selectedObject.id) {
        return element;
      }
    });
  }

  getRelatedObjects(
    item: Option | ScoreToNextObject,
    relatedObjects: RelatedObject[]
  ) {
    let outputList = relatedObjects.concat([]);
    if(item === null)
      return this.sortRelatedObjectsByTag(outputList);
    if (item.relatedObject != null) {
      let found: boolean = false;
      for (let i = 0; i < outputList.length; i++) {
        if (outputList[i].id == item.relatedObject.id) {
          found = true;
          break;
        }
      }
      if (!found)
        outputList.push(item.relatedObject);
    }
    return this.sortRelatedObjectsByTag(outputList);
  }

  sortRelatedObjectsByTag(outputList: RelatedObject[])
    : RelatedObject[]
  {
    return outputList.sort((a: RelatedObject,b: RelatedObject)=>{
      let tag1 = a.tag.toLowerCase();
      let tag2 = b.tag.toLowerCase();
      if (tag1 > tag2)
        return 1;
      if (tag1 < tag2)
        return -1;
      return 0;
    });
  }

  createObjectsForTemplateRelatedObjects(
    group: QuestionGroup,
    selectedPathway: Pathway,
    templateGroups: QuestionGroup[],
    templateTreatments: Treatment[]
  ) {
    let results = this.getScoreOrOptionArray(group);
    results.forEach((item) => {
      if (item.relatedObject != undefined && item.relatedObject != null) {
        if (item.relatedObject.isTemplate) {
          if (item.relatedObject.isQuestionGroup) {
            let templateGroup = this.getGroupFromRelatedObject(
              item.relatedObject,
              templateGroups
            );
            let copyGroup = this.questionGroupUtility.copyQuestionGroup(
              templateGroup
            );
            this.questionGroupUtility.setQuestionGroupToInsert(copyGroup);
            item.nextQuestionGroupId = copyGroup.id;
            item.relatedObject = this.convertQuestionGroupToRelatedObject(
              copyGroup,
              false
            );
            selectedPathway.questionGroupList.push(copyGroup);
            this.getAllChildrenRelatedObjectsFromGroup(copyGroup,templateGroups,templateTreatments,selectedPathway);
          } else {
            let template = this.getTreatmentFromRelatedObject(
              item.relatedObject,
              templateTreatments
            );
            let treatment = this.treatmentUtility.copyTreatment(template);
            item.treatmentId = treatment.id;
            item.relatedObject = this.convertTreatmentToRelatedObject(
              treatment,
              false
            );
            this.treatmentUtility.setTreatmentForInsert(treatment);
            selectedPathway.treatmentList.push(treatment);
          }
        }
        else
        {
          console.log("Selected item related object is not template: "+ item.id);
        }
      }
      else
      {
        console.log("Selected item does not have related object: "+ item.id);
      }
    });
  }

  getRelatedObjectsFromOptions(
    group: QuestionGroup,
    selectedPathway: Pathway,
    templateTreatments: Treatment[], allGroups: QuestionGroup[]) {
      group.questions.forEach(q => {
        q.options.forEach(opt => {
          if(opt.treatmentId) {
            var treatment = templateTreatments.find(t => t.id == opt.treatmentId*-1);
            if(treatment && !selectedPathway.treatmentList.find(t =>t.id == treatment.id)) {
              let newTreatment = this.treatmentUtility.copyTreatment(treatment);
              opt.treatmentId = this.treatmentUtility.nextId--;
              newTreatment.id = opt.treatmentId;
              newTreatment.parentPathwayId = selectedPathway.id;
              selectedPathway.treatmentList.push(newTreatment);
            }
          }
          if(opt.nextQuestionGroupId) {
            var questionGroup = allGroups.find(q => q.id == opt.nextQuestionGroupId*-1);
            if(questionGroup && !selectedPathway.questionGroupList.find(q=>q.id == questionGroup.id)) {
              let newQuestionGroup = this.questionGroupUtility.copyQuestionGroup(questionGroup);
              opt.nextQuestionGroupId = this.questionGroupUtility.nextId--;//temp fix to set unique id
              newQuestionGroup.id = opt.nextQuestionGroupId;
              newQuestionGroup.parentPathwayId = selectedPathway.id;
              selectedPathway.questionGroupList.push(newQuestionGroup);
            }
          }
        });
      });

  }

  getScoreOrOptionArray(group: QuestionGroup): (Option | ScoreToNextObject)[] {
    if (group.isScoreBased) return group.scoreToNextObjectList;
    let options: Option[] = [];
    group.questions.forEach((question) => {
      options = options.concat(question.options);
    });
    return options;
  }

  getAllChildrenRelatedObjectsFromGroup(
    group: QuestionGroup,
    allGroups: QuestionGroup[],
    allTreatments: Treatment[],
    pathway: Pathway
  ) {
    let startIndex: number = pathway.questionGroupList.length;
    this.createObjectsForTemplateRelatedObjects(
      group,
      pathway,
      allGroups,
      allTreatments
    );
    let currentCount: number = pathway.questionGroupList.length;
    while (true) {
      for (let i = startIndex; i < currentCount; i++) {
        this.createObjectsForTemplateRelatedObjects(
          pathway.questionGroupList[i],
          pathway,
          allGroups,
          allTreatments
        );
      }
      let newCount = pathway.questionGroupList.length;
      if (newCount == currentCount) break;
      startIndex = currentCount;
      currentCount = newCount;
    }
  }

  createNodeAndChildNodes(selectedObject: RelatedObject,
    selectedPathway: Pathway, allGroups: QuestionGroup[],
    allTreatments: Treatment[],
    ) : QuestionGroup
  {
    let groupRelObj = this.getGroupFromRelatedObject(selectedObject,allGroups);
    let clone = this.questionGroupUtility.copyQuestionGroup(groupRelObj);

    this.getRelatedObjectsFromOptions(clone, selectedPathway, allTreatments, allGroups);
    this.questionGroupUtility.setQuestionGroupToInsert(clone);
    selectedPathway.questionGroupList.push(clone);
    this.getAllChildrenRelatedObjectsFromGroup(clone,allGroups,allTreatments,selectedPathway);
    return clone;
  }
}
