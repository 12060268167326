import { Injectable } from '@angular/core';
import { QuestionGroup, Question, ScoreToNextObject, Option, PersistenceAction, LanguageText, OptionVariable }
      from "projects/shared-lib/src/public-api";
import { BaseClassUtil } from './base-class.util';
import { LanguageTextControlUtil } from './language-text-control.util';

@Injectable({ providedIn: "root" })
export class QuestionGroupUtil {
  nextId: number = -1;

  constructor(private languageTextControlUtility: LanguageTextControlUtil, private baseClassUtil: BaseClassUtil){
    this.mapQsTags = this.mapQsTags.bind(this);
    this.mapActiveQuestions = this.mapActiveQuestions.bind(this);
  }

  public createEmptyQuestionGroup(): QuestionGroup{
    let questionGroup = new QuestionGroup();
    questionGroup.id = this.nextId--;
    questionGroup.modelAction = PersistenceAction.INSERT;
    questionGroup.rowStatus = true;
    questionGroup.displayOrder = 0;
    questionGroup.questions = new Array();
    questionGroup.scoreToNextObjectList = new Array();
    questionGroup.textList = new Array();
    questionGroup.isScoreBased = false;
    questionGroup.isStepBased = false;
    questionGroup.createdDate = new Date();
    questionGroup.updatedDate = new Date();
    questionGroup.createdBy = "ADMIN";
    questionGroup.updatedBy = "ADMIN";
    // questionGroup.parentPathwayId = 0;
    questionGroup.tag = "tag";
    // questionGroup.templateQuestionGroupId = 0;
    (questionGroup as any).validationErrors = new Array();
    return questionGroup;
  }

  public createEmptyQuestion(questionGroup: QuestionGroup): Question
  {
    let question = new Question();
    question.modelAction = PersistenceAction.INSERT;
    question.id = this.nextId--;
    question.rowStatus = true;
    question.displayOrder = 0;
    question.multipleSelection = false;
    question.options = new Array();
    question.createdDate = new Date();
    question.updatedDate = new Date();
    question.createdBy = "ADMIN";
    question.updatedBy = "ADMIN";
    questionGroup.questions.push(question);
    question.textList = new Array();
    return question;
  }

  private copyQuestionGroupData(source: QuestionGroup, destination: QuestionGroup)
  {
    this.baseClassUtil.copyAllAttributes(source,destination);
    destination.isScoreBased = source.isScoreBased;
    destination.isStepBased = source.isStepBased;

    destination.modelAction = source.modelAction;
  }


  private copyScoreData(source: ScoreToNextObject, destination: ScoreToNextObject)
  {
    this.baseClassUtil.copyAllAttributes(source,destination);
    destination.score = source.score;
    destination.treatmentId = source.treatmentId * -1;
    destination.nextQuestionGroupId = source.nextQuestionGroupId * -1;
    destination.relatedObject = source.relatedObject;
  }

  private copyScores(source: QuestionGroup, destination: QuestionGroup)
  {
    source.scoreToNextObjectList.forEach(score=>{
      let newScore = new ScoreToNextObject();
      newScore.id = this.nextId--;
      this.copyScoreData(score,newScore);
      newScore.modelAction = PersistenceAction.INSERT;
      destination.scoreToNextObjectList.push(newScore);
    });
  }

  private copyQuestionData(source: Question, destination: Question,copyAudit: boolean)
  {
    this.baseClassUtil.copyAllAttributes(source,destination);
    destination.text = source.text;
    destination.multipleSelection = source.multipleSelection;
    this.languageTextControlUtility.copyLanguageText(source.textList,destination.textList);
  }

  private copyQuestions(source: QuestionGroup, destination: QuestionGroup)
  {
    source.questions.forEach(question=>{
      let newQuestion = this.createEmptyQuestion(destination);
      this.copyQuestionData(question,newQuestion,true);
      question.options.forEach(option=>{
        let newOption = this.addOption(newQuestion);
        newOption.id = --this.nextId;
        this.copyOptionData(option,newOption,true, true);
      });
    });
  }

  createQuestionGroup(): QuestionGroup{
    let questionGroup = this.createEmptyQuestionGroup();
    this.addQuestion(questionGroup);
    return questionGroup;
  }

  copyQuestionGroup(input: QuestionGroup): QuestionGroup
  {
    let group = this.createEmptyQuestionGroup();
    this.copyQuestionGroupData(input,group);
    this.copyScores(input,group);
   this.copyQuestions(input,group);
    if(input.templateQuestionGroupId != 0)
      group.templateQuestionGroupId = input.templateQuestionGroupId;
    else
      group.templateQuestionGroupId = input.id;
    return group;
  }

  private copyOptionData(source: Option, destination: Option,copyAudit: boolean, changeTreatmentId: boolean)
  {
    this.baseClassUtil.copyAllAttributes(source,destination);
    destination.text = source.text;
    destination.treatmentId = 0;
    destination.nextQuestionGroupId = source.nextQuestionGroupId;
    if(source.treatmentId && changeTreatmentId){
      destination.treatmentId = (changeTreatmentId) ? source.treatmentId*-1 : source.treatmentId;
    }
    if(source.nextQuestionGroupId && changeTreatmentId) {
      destination.nextQuestionGroupId = (changeTreatmentId) ? source.nextQuestionGroupId*-1 : source.nextQuestionGroupId;
    }
    destination.relatedObject = source.relatedObject;
    destination.weight = +source.weight;
    this.languageTextControlUtility.copyLanguageText(source.textList,destination.textList);
    destination.variableList = [];
    for(let l = 0; l < source.variableList.length; l++)
    {
      var copiedVariable = this.copyVariableData(source.variableList[l]);
      destination.variableList.push(copiedVariable);
    }
  }

  copyVariableData(source: OptionVariable) : OptionVariable 
  {
    let destination: OptionVariable = new OptionVariable();
    this.baseClassUtil.copyAllAttributes(source,destination);
    destination.modelAction = source.modelAction;
    destination.optionId = source.optionId;
    destination.text = source.text;
    destination.id = source.id;
    return destination;
  }

  questionGroupDeepCopy(source: QuestionGroup,destination: QuestionGroup)
  {
    let modified: boolean = false;
    destination.id = source.id;
    this.copyQuestionGroupData(source,destination);
    destination.templateQuestionGroupId = source.templateQuestionGroupId;
    destination.parentPathwayId = source.parentPathwayId;
    destination.questions = [];
    destination.scoreToNextObjectList = [];
    destination.displayOrder = source.displayOrder;
    for(let i = 0;i < source.questions.length;i++)
    {
      let question = source.questions[i];
      let destQuestion = this.createEmptyQuestion(destination);
      destQuestion.id = question.id;
      destQuestion.modelAction = question.modelAction;
      if(destQuestion.modelAction == PersistenceAction.NONE)
        destQuestion.modelAction = PersistenceAction.UPDATE;
      else if(destQuestion.modelAction == PersistenceAction.DELETE)
        modified = true;
      this.copyQuestionData(question,destQuestion,true);
      for(let j = 0;j < question.options.length;j++)
      {
        let option = question.options[j];
        let destOption = this.addOption(destQuestion);
        destOption.id = option.id;
        destOption.modelAction = option.modelAction;
        if(destOption.modelAction == PersistenceAction.NONE)
          destOption.modelAction = PersistenceAction.UPDATE;
        else if(destOption.modelAction == PersistenceAction.DELETE)
          modified = true;
        this.copyOptionData(option,destOption,true, false);
      }
    }

    for(let i = 0;i < source.scoreToNextObjectList.length;i++)
    {
      let score = source.scoreToNextObjectList[i];
      let destScore = new ScoreToNextObject();
      destScore.id = score.id;
      destScore.modelAction = score.modelAction;
      if(destScore.modelAction == PersistenceAction.NONE)
        destScore.modelAction = PersistenceAction.UPDATE;
      else if(destScore.modelAction == PersistenceAction.DELETE)
        modified = true;
      destScore.nextQuestionGroupId = score.nextQuestionGroupId;
      destScore.relatedObject = score.relatedObject;
      destScore.score = score.score;
      destScore.treatmentId = score.treatmentId;
      this.baseClassUtil.copyAllAttributes(score,destScore);
      destination.scoreToNextObjectList.push(destScore);
    }
    if(destination.modelAction == PersistenceAction.NONE && modified)
      destination.modelAction = PersistenceAction.UPDATE;
  }

  addQuestion(questionGroup: QuestionGroup){
    let question = this.createEmptyQuestion(questionGroup);
    this.addOption(question);
  }

  removeQuestion(questionGroup: QuestionGroup,index: number){
    let question = questionGroup.questions[index];
    if(question.modelAction != PersistenceAction.INSERT)
    {
      question.modelAction = PersistenceAction.DELETE;
      this.languageTextControlUtility.markTextItemToDelete(question.textList);
    }
    else
      questionGroup.questions.splice(index,1);
  }

  addOption(question: Question){
    let o = new Option();
    o.id = this.nextId--;
    o.modelAction = PersistenceAction.INSERT;
    o.displayOrder = 0;
    o.weight = 0;
    o.textList = new Array();
    o.variableList = new Array();
    question.options.push(o);
    return o;
  }

  removeOption(question: Question, index: number) {
    let option = question.options[index];
    if(option.modelAction != PersistenceAction.INSERT)
    {
      option.modelAction = PersistenceAction.DELETE;
      this.languageTextControlUtility.markTextItemToDelete(option.textList);
    }
    else
      question.options.splice(index,1);
  }

  editQuestionGroup(group: QuestionGroup): QuestionGroup{
    group.modelAction = PersistenceAction.UPDATE;
    group.questions.forEach(question => {
      question.modelAction = PersistenceAction.UPDATE;
      question.options.forEach(option=>{
        option.modelAction = PersistenceAction.UPDATE;
      })
    });
    group.scoreToNextObjectList.forEach(score => {
      score.modelAction = PersistenceAction.UPDATE;
    });
    return group;
  }

  addScoreObject(questionGroup: QuestionGroup) {
    if(questionGroup.scoreToNextObjectList == undefined || questionGroup.scoreToNextObjectList == null)
      questionGroup.scoreToNextObjectList = [];
    let o = new ScoreToNextObject();
    o.id = this.nextId--;
    o.modelAction = PersistenceAction.INSERT;
    o.treatmentId = 0;
    o.nextQuestionGroupId = 0;
    questionGroup.scoreToNextObjectList.push(o);
  }

  removeScoreObject(questionGroup: QuestionGroup, i: number) {
    let scoreObject = questionGroup.scoreToNextObjectList[i];
    if(scoreObject.modelAction != PersistenceAction.INSERT)
    {
      scoreObject.modelAction = PersistenceAction.DELETE;
    }
    else
    questionGroup.scoreToNextObjectList.splice(i,1);
  }

  isQuestionGroup(instance: any): boolean {
    if (instance != null) {
      return "questions" in instance;
    }
    return false;
  }

  getDropdownText(question: Question): string
  {
      return question.text.replace(/<[^>]*>/g, "");
  }

  getQuestionGroupLanguageText(selectedInstance: QuestionGroup , langId: number)
  : QuestionGroup
  {
    let questionGroup = this.copyQuestionGroup(selectedInstance);
    for(let i = 0; i < selectedInstance.questions.length; i++)
    {
      let rQuestion = selectedInstance.questions[i];
      let eQuestion = questionGroup.questions[i];
      eQuestion.id = rQuestion.id;
      let questionText = this.languageTextControlUtility.findTranslation(langId,rQuestion.id,rQuestion.textList);
      this.populateQuestionGroupText(questionText,eQuestion);
      for(let j = 0; j < rQuestion.options.length; j++)
      {
        let rOption = rQuestion.options[j];
        let eOption = eQuestion.options[j];
        eOption.id = rOption.id;
        let optionText = this.languageTextControlUtility.findTranslation(langId,rOption.id,rOption.textList);
        this.populateQuestionGroupText(optionText,eOption);
      }
    }
    return questionGroup;
  }

  populateQuestionGroupText(text: LanguageText, q: Question | Option)
  {
    if(text != undefined && text != null)
    {
      q.text = text.value;
    }
    else
    {
      q.text = "";
    }
  }

  setQuestionGroupToInsert(group: QuestionGroup)
  {
    group.modelAction = PersistenceAction.INSERT;
    group.scoreToNextObjectList.forEach(s=>{
      s.modelAction = PersistenceAction.INSERT;
    });
    group.questions.forEach(q=>{
      q.modelAction = PersistenceAction.INSERT;
      q.options.forEach(o=>{
        o.modelAction = PersistenceAction.INSERT;
        o.textList.forEach(t=>{
          t.modelAction = PersistenceAction.INSERT;
          t.id = this.nextId--;
        });
        o.variableList.forEach(v => {
          v.modelAction = PersistenceAction.INSERT;
          v.id = this.nextId--;
        })
      });
      q.textList.forEach(t=>{
        t.modelAction = PersistenceAction.INSERT;
        t.id = this.nextId--;
      });
    });
  }

  newQsGroupAs(qsGroup: QuestionGroup): QuestionGroup {
    const newQsGroupAs = { ...qsGroup };
    newQsGroupAs.isSaveAs = true;
    return newQsGroupAs;
  }

  private mapOptionTags(option: Option): string {
    return option.tag;
  }

  private mapQsTags(acc: string[], curr: Question): string[] {
    const optTags = curr.options.map(this.mapOptionTags);
    return acc.concat(optTags, [curr.tag]);
  }

  private reduceQsGroupTags(questions: Question[]): string [] {
    return questions.reduce(this.mapQsTags, []);
  }

  private hasRepeatedTag(initialTags: string[]) {
    return (tag: string) => initialTags.includes(tag);
  }

  notHasUniqueTags(qsGroup: QuestionGroup, initialQsGroup: QuestionGroup): boolean {
    const tagsToBeCompared = this.reduceQsGroupTags(qsGroup.questions);
    const initialTags = this.reduceQsGroupTags(initialQsGroup.questions);
    return tagsToBeCompared.some(this.hasRepeatedTag(initialTags));
  }

  private filterActiveOption(option: Option): boolean {
    return option.modelAction !== PersistenceAction.DELETE;
  }

  private mapActiveQuestions(acc: Question[], curr: Question): Question[] {
    if (curr.modelAction !== PersistenceAction.DELETE) {
      const activeOpts = curr.options.filter(this.filterActiveOption);
      curr.options = activeOpts;
      return acc.concat(curr);
    }
    return acc;
  }

  private filterActiveQuestions(questions: Question[]): Question[] {
    return questions.reduce(this.mapActiveQuestions, []);
  }

  filterActiveQsGroupItems(qsGroup: QuestionGroup): QuestionGroup {
    const filteredQsGroup = {...qsGroup };
    filteredQsGroup.questions = this.filterActiveQuestions(qsGroup.questions);
    return filteredQsGroup;
  }

  setForSaveAs(qsGroup: QuestionGroup): QuestionGroup {
    const saveQsGroupAs = { ...qsGroup };
    saveQsGroupAs.id =  this.nextId--;
    saveQsGroupAs.modelAction = PersistenceAction.INSERT;
    saveQsGroupAs.createdBy = "Anonymous";
    saveQsGroupAs.createdDate = new Date();
    saveQsGroupAs.updatedBy = "Anonymous";
    saveQsGroupAs.updatedDate = new Date();
    saveQsGroupAs.textList.forEach(t => {
      t.id = this.nextId--;
      t.tableId = this.nextId--;
      t.modelAction = PersistenceAction.INSERT;
      t.createdBy = "Anonymous";
      t.createdDate = new Date();
      t.updatedBy = "Anonymous";
      t.updatedDate = new Date();
    });
    saveQsGroupAs.scoreToNextObjectList.forEach(so => {
      so.id = this.nextId--;
      so.modelAction = PersistenceAction.INSERT;
      so.createdBy = "Anonymous";
      so.createdDate = new Date();
      so.updatedBy = "Anonymous";
      so.updatedDate = new Date();
    });
    saveQsGroupAs.questions.forEach(q => {
      q.id = this.nextId--;
      q.modelAction = PersistenceAction.INSERT;
      q.createdBy = "Anonymous";
      q.createdDate = new Date();
      q.updatedBy = "Anonymous";
      q.updatedDate = new Date();
      q.textList.forEach(t => {
        t.id = this.nextId--;
        t.tableId = this.nextId--;
        t.modelAction = PersistenceAction.INSERT;
        t.createdBy = "Anonymous";
        t.createdDate = new Date();
        t.updatedBy = "Anonymous";
        t.updatedDate = new Date();
      });
      q.options.forEach(o => {
        o.id = this.nextId--;
        o.modelAction = PersistenceAction.INSERT;
        o.createdBy = "Anonymous";
        o.createdDate = new Date();
        o.updatedBy = "Anonymous";
        o.updatedDate = new Date();
        o.textList.forEach(t => {
          t.id = this.nextId--;
          t.tableId = this.nextId--;
          t.modelAction = PersistenceAction.INSERT;
          t.createdBy = "Anonymous";
          t.createdDate = new Date();
          t.updatedBy = "Anonymous";
          t.updatedDate = new Date();
        });
      });
    });
    return saveQsGroupAs;
  }
}
