import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Observable, forkJoin, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog'
import { RelatedObject, Treatment, QuestionGroup, Decision,
        Pathway, Option, ScoreToNextObject, MessageType,
        Institution, InquiryRequest, InquiryResponse, SpinnerService,
        PersistenceAction, ToasterService, Category }
      from 'projects/shared-lib/src/public-api';
import { GraphService } from 'projects/AdminApp/src/app/shared/service/graph.service';
import { DagreNodesOnlyLayout } from 'projects/AdminApp/src/app/admin/admin-question/customDagreNodesOnly';
import { Edge, Node, Layout } from '@swimlane/ngx-graph';
import * as shape from "d3-shape";
import { CustomEditorConfig } from 'projects/AdminApp/src/app/admin/customEditorConfig';
import { AngularEditorConfig } from '../../../../../../../angular-editor/src/public-api';
import { QuestionGroupUtil } from 'projects/AdminApp/src/app/shared/util/question-group.util';
import { TreatmentUtil } from 'projects/AdminApp/src/app/shared/util/treatment.util';
import { GraphNode } from 'projects/AdminApp/src/app/shared/util/graphnode';
import { DecisionService } from 'projects/AdminApp/src/app/shared/service/decision.service';
import { InstitutionService } from 'projects/AdminApp/src/app/shared/service/institution.service';
import { PathwayService } from 'projects/AdminApp/src/app/shared/service/pathway.service';
import { RelatedObjectUtility } from 'projects/AdminApp/src/app/shared/util/related-object-utility';
import { GuidelinesSelectorComponent } from 'projects/AdminApp/src/app/admin/guidelines-selector/guidelines-selector.component';
import { AdminPathway } from 'projects/AdminApp/src/app/shared/model/admin-pathway.model';
import { PathwayStatusEnum } from 'projects/AdminApp/src/app/shared/constant/pathway-status-enum.constant';
import { finalize } from 'rxjs/operators';
import { ConfirmationModalComponent } from 'projects/AdminApp/src/app/shared/components/confirmation-modal/confirmation-modal.component';
import { CategoryService } from 'projects/AdminApp/src/app/shared/service/category.service';

@Component({
  selector: 'app-pathway-tree',
  templateUrl: './pathway-tree.component.html',
  styleUrls: ['./pathway-tree.component.css']
})
export class PathwayTreeComponent implements OnInit {
  @Input() groups: QuestionGroup[];
  @Input() treatments: Treatment[];
  @Input() canAssociateObjects: RelatedObject[];
  @Output() pathwayItemSelectedForEdit: EventEmitter<QuestionGroup | Treatment>
                              = new EventEmitter<QuestionGroup | Treatment>();
  @Output() pathwayTreeSaved: EventEmitter<boolean>
                              = new EventEmitter<boolean>();

  public zoomToFit$: Subject<boolean> = new Subject();
  config: AngularEditorConfig = new CustomEditorConfig(false, true);
  decisions: Decision[];
  institutions: Institution[] = [];
  filteredInstitutions: Institution[] = [];
  categories: Category[] = [];
  selectedPathway: AdminPathway = null;
  selectedDecision: Decision = null;
  selectedInstitution: Institution = null;
  disconnectedGroups: QuestionGroup[] = [];
  pathwaysForInstitution: AdminPathway[] = [];
  selectedNode: GraphNode;
  public nodes: GraphNode[] = [];
  public links: Edge[] = [];
  public layoutSettings = {
    orientation: "TB"
  };
  public categoryId: number = null;
  public curve: any = shape.curveLinear;
  public layout: Layout = new DagreNodesOnlyLayout();
  readOnlyErrorMessage: string = "Tree cannot be changed as pathway is published.";
  public surveyVariables: string[];

  constructor(private graphService: GraphService,
    private toasterService: ToasterService, private questionGroupUtil: QuestionGroupUtil,
    private decisionService: DecisionService,
    private institutionService: InstitutionService,
    private categoryService: CategoryService,
    public pathwayService: PathwayService,
    private treatmentUtil: TreatmentUtil,
    public dialog: MatDialog,
    private spinnerService: SpinnerService,
    private relatedObjectUtilty: RelatedObjectUtility  ) {
      this.onSuccess = this.onSuccess.bind(this);
      this.onError = this.onError.bind(this);
    }

  ngOnInit() {
    this.getDecisionsAndInstitutions();
  }

  isPathwayReadOnly()
  {
    if(this.selectedPathway != undefined && this.selectedPathway != null)
    {
      if(this.pathwayService.getPathwayStatus(this.selectedPathway) == PathwayStatusEnum.PUBLISHED)
        return true;
      return false;
    }
    return true;
  }

  onDecisionChanged()
  {
    this.selectedPathway = null;
    this.selectedInstitution = null;
    if(this.selectedDecision) {
      this.filterInstitutionsByUserType();
      this.filteredInstitutions = this.filteredInstitutions.filter(inst => {
        let hasPathway = null;
        if(this.selectedDecision) {
          hasPathway = this.selectedDecision.pathways.find(pathway => {
            let result = false;
            if(pathway.institutionId == inst.id)
            {
              return true;
            }
            pathway.byInstitutions.forEach(associatedInstitution=>{
              if(associatedInstitution.institutionId == inst.id)
              {
                 result = true;
              }
              });
              return result;
          });
        }
        return hasPathway != null;
      });
    }
  }

  onUserTypeChanged(): void {
    
    this.filterInstitutionsByUserType();
    this.selectedDecision = null;
    this.selectedPathway = null;
    this.selectedInstitution = null;
    this.spinnerService.show();
    this.categoryService.getVariablesForUserType(this.categoryId).subscribe(res => {
      this.spinnerService.hide();
      if(res.operationSuccess){
        this.surveyVariables = [];
        res.returnValues.forEach( s=> this.surveyVariables.push(s));
      } else if (res.messages.length > 0) {
        this.toasterService.show(res.messages[0].type, res.messages[0].text);
      } else {
        this.toasterService.show(MessageType.SYSTEM_ERROR, "Could not get variables.");
      }
    });
  }

  filterInstitutionsByUserType() {
    var selectedCategory = this.categories.find(c => c.id == this.categoryId);
    this.filteredInstitutions = this.institutions.filter(i => {
      var selectedCategory = this.categories.find(c => c.id == this.categoryId);

      return selectedCategory.relatedInstitutions.find(r => r.institutionId == i.id);
    });
  }


  onInstitutionChanged() {
    this.selectedPathway = null;
    this.pathwaysForInstitution.length = 0;
    const isSelectedInstIdInvalid = this.isIdInValid(this.selectedInstitution?.id);
    if (isSelectedInstIdInvalid) { return; };
    this.selectedDecision.pathways.forEach(p=>{
      let pathway = p as Pathway;
      if(pathway.institutionId == this.selectedInstitution.id)
      {
        this.pathwaysForInstitution.push(pathway);
      }
      pathway.byInstitutions.forEach(associatedInstitution=>{
        if(associatedInstitution.institutionId == this.selectedInstitution.id)
        {
          if(!this.pathwaysForInstitution.includes(pathway))
            this.pathwaysForInstitution.push(pathway);
        }
        });
    });
  }

  updateTreeWithSavedData()
  {
    if(this.selectedDecision == undefined || this.selectedDecision == null)
      return;
    this.selectedDecision = this.decisions.find( d=>this.selectedDecision.id === d.id);
    if(this.selectedInstitution == undefined || this.selectedDecision == null)
      return;
    this.selectedInstitution = this.institutions.find( i => i.id === this.selectedInstitution.id);
    this.onInstitutionChanged()
    if(this.selectedPathway === undefined || this.selectedPathway === null)
      return;

    this.selectedPathway = this.selectedDecision.pathways.find(p=>this.selectedPathway.id === p.id);
    this.updateTree();
  }

  private onSuccess([firstResponse, secondResponse, thirdResponse]) {
    this.spinnerService.hide();
    const instResponse: InquiryResponse<Institution> = firstResponse;
    const decisionResponse: InquiryResponse<Decision> = secondResponse;
    const catResponse: InquiryResponse<Category> = thirdResponse;
    if (instResponse.operationSuccess && decisionResponse.operationSuccess && catResponse.operationSuccess) {
      this.institutions = instResponse.returnValues;
      this.decisions = decisionResponse.returnValues;
      this.categories = catResponse.returnValues;
      this.updateTreeWithSavedData();
    } else if (instResponse.messages.length > 0) {
      this.toasterService.show(
        instResponse.messages[0].type,
        instResponse.messages[0].text
      );
    } else if (decisionResponse.messages.length > 0) {
      this.toasterService.show(
        decisionResponse.messages[0].type,
        decisionResponse.messages[0].text
      );
    } else if (catResponse.messages.length > 0) {
      this.toasterService.show(
        catResponse.messages[0].type,
        catResponse.messages[0].text
      );
    } else {
      this.toasterService.show(MessageType.SYSTEM_ERROR, "Something was wrong");
    }
  }

  private onError(error) {
    this.spinnerService.hide();
    this.toasterService.show(MessageType.SYSTEM_ERROR, error);
  }

  private getDecisionsAndInstitutions() {
    this.spinnerService.show();
    let instObs: Observable<InquiryResponse<Institution>>;
    instObs = this.institutionService.getInstitutions(new InquiryRequest());
    let decisionObs: Observable<InquiryResponse<Decision>>;
    decisionObs = this.decisionService.getDecisions(new InquiryRequest(),true);
    let catObs: Observable<InquiryResponse<Category>>;
    catObs = this.categoryService.getCategoriesWithoutQuestions(new InquiryRequest());
    forkJoin(instObs, decisionObs, catObs).subscribe(this.onSuccess, this.onError);
  }

  savePathwayTree()
  {
    if(this.isPathwayReadOnly())
    {
      this.toasterService.show(MessageType.VALIDATION,this.readOnlyErrorMessage)
      return;
    }
    this.spinnerService.show();
    let inst = this.pathwayService.savePathway(this.selectedPathway,true);
    this.selectedPathway.modelAction = PersistenceAction.UPDATE;
    inst.subscribe(response => {
      this.spinnerService.hide();
      this.pathwayTreeSaved.emit(response.operationSuccess);
      if (response.operationSuccess) {
        this.toasterService.show(
          MessageType.INFORMATIONAL,
          "Pathway tree successfully saved."
        );
        this.getDecisionsAndInstitutions();
      } else if (response.messages.length > 0) {
        this.toasterService.show(response.messages[0].type, response.messages[0].text);
      } else {
        this.toasterService.show(MessageType.SYSTEM_ERROR, "Something was wrong");
      }
    });
  }



  showConfirmationModal(msg: string, func) {
    const configuration = {
      data: msg,
    };
    const dialogRef = this.dialog.open(ConfirmationModalComponent, configuration);
    dialogRef.afterClosed().subscribe(func);
  }
  deleteTreeCall() {
    this.selectedPathway.modelAction = PersistenceAction.UPDATE;
    this.selectedPathway.questionGroupList
      .filter(q => q.parentPathwayId == this.selectedPathway.id )
      .forEach(q => {
        q.modelAction = PersistenceAction.DELETE;
      });
    this.selectedPathway.treatmentList
      .filter(q => q.parentPathwayId == this.selectedPathway.id )
      .forEach(q => {
        q.modelAction = PersistenceAction.DELETE;
      });
    this.selectedPathway.startingQuestionGroupId = 0;
    this.selectedPathway.byTreeList
      .filter(pbt => pbt.treeOwner === this.selectedPathway.id)
      .forEach(pbt => {
        pbt.modelAction = PersistenceAction.DELETE;
      });

    // set starting qs group id for pathways
    this.spinnerService.show();
    this.pathwayService.savePathway(this.selectedPathway)
    .pipe(finalize( () => this.spinnerService.hide()))
    .subscribe(res => {
      if(res.operationSuccess){
        this.selectedPathway.questionGroupList = [];
        this.selectedPathway.treatmentList = [];
      } else if (res.messages.length > 0) {
        this.toasterService.show(res.messages[0].type, res.messages[0].text);
      } else {
        this.toasterService.show(MessageType.SYSTEM_ERROR, "Could not delete pathway tree.");
      }
    });
  }

  deletePathwayTree(){
    if(this.treeShareWithPublishedPathvways()){
      this.toasterService.show(MessageType.SYSTEM_ERROR, "Tree is shared with published pathways so can't be deleted.");
      return;
    }
    if(this.selectedPathway && this.selectedPathway.byTreeList.length <= 0){
      this.showConfirmationModal("Are you sure you want to delete the pathway tree?",
        res => {
          if(res){
            this.deleteTreeCall()
          } else {
            return;
          }
        });
    } else {
      if(this.isTreeOwner()){
        this.showConfirmationModal("This tree is shared with other pathways, are you sure you want to delete the pathway tree?",
          res => {
            if(res){
              this.deleteTreeCall()
            } else {
              return;
            }
          });
      }
      else{
        this.showConfirmationModal("This tree is not owned by this pathway, are you sure you want to delete the pathway tree?",
          res => {
            if(res){
              this.deleteTreeCall()
            } else {
              return;
            }
          });
      }
    }
    return;

  }

  canDeleteTree() {
    return this.selectedPathway.questionGroupList.filter(q => q.parentPathwayId == this.selectedPathway.id ).length > 0;
  }

  isTreeOwner() {
    if(!this.selectedPathway) return false;

    if(this.selectedPathway.byTreeList.length > 0){
      return this.selectedPathway.byTreeList[0].treeOwner == this.selectedPathway.id
    } else {
      return true;
    }
  }

  treeShareWithPublishedPathvways(){
    let allPublishedPathways = [];
    this.decisions.forEach(d => allPublishedPathways = allPublishedPathways.concat(d.pathways)) //.filter(p => p.pathwayStatusId == PathwayStatusEnum.PUBLISHED)));
    allPublishedPathways = allPublishedPathways.filter(p =>{
      return this.pathwayService.getPathwayStatus(p) == PathwayStatusEnum.PUBLISHED
    });
    let publisedPathways = this.selectedPathway.byTreeList.find(byTree => allPublishedPathways.find(p => p.id == byTree.sharedWithPathwayId) != null);

    return publisedPathways;
  }

  onDrop(event: CdkDragDrop<string[]>, containerObject: GraphNode) {

    if(this.isPathwayReadOnly())
    {
      this.toasterService.show(MessageType.VALIDATION,this.readOnlyErrorMessage)
      return;
    }    let selectedObject = event.item.data as RelatedObject;
    if(containerObject == null)
    {
      if(this.selectedPathway == undefined || this.selectedPathway == null)
      {
        this.toasterService.show(MessageType.INFORMATIONAL, "Please select a pathway before this action.");
        return;
      }
      if(!selectedObject.isQuestionGroup)
      {
        this.toasterService.show(MessageType.INFORMATIONAL, "Treatment cannot be the first object.");
        return;
      }
      let clone = this.relatedObjectUtilty.createNodeAndChildNodes(selectedObject,this.selectedPathway,
        this.groups, this.treatments);
      if(this.selectedPathway.startingQuestionGroupId == 0)
      {
        this.selectedPathway.startingQuestionGroupId = clone.id;
      }
      // else{
      //   this.addNodeToTree(selectedObject,containerObject);
      // }
    }
    else
    {
      this.addNodeToTree(selectedObject,containerObject);
    }
    this.updateTree();
  }

  populateGroupNextGroup(group: QuestionGroup,selectedObject: RelatedObject)
  {
    if(group.isScoreBased)
    {
      group.scoreToNextObjectList.forEach(item=>{
        let clone = this.relatedObjectUtilty.createNodeAndChildNodes(selectedObject,
          this.selectedPathway,this.groups,this.treatments);
        item.nextQuestionGroupId = clone.id;
        item.treatmentId = 0;
      });
    }
    else
    {
      group.questions.forEach(question=>{
        question.options.forEach(option=>{
          let clone = this.relatedObjectUtilty.createNodeAndChildNodes(selectedObject,this.selectedPathway,
            this.groups, this.treatments);
          option.nextQuestionGroupId = clone.id;
          option.treatmentId = 0;
        });
      });
    }
  }

  populateGroupNextTreatment(group: QuestionGroup,treatment: Treatment)
  {
    if(group.isScoreBased)
    {
      group.scoreToNextObjectList.forEach(item=>{
        this.populateNextTreatmentObject(treatment,item);
      });
    }
    else
    {
      group.questions.forEach(question=>{
        question.options.forEach(option=>{
          this.populateNextTreatmentObject(treatment,option);
        });
      });
    }
  }


  addNodeToTree(selectedObject: RelatedObject, containerObject: GraphNode )
  {
    if(selectedObject.isQuestionGroup)
    {
      if(containerObject.data.isGroupNode())
      {
        this.populateGroupNextGroup(containerObject.data.group,selectedObject);
      }
      else if(containerObject.data.isScoreNode())
      {
        let clone = this.relatedObjectUtilty.createNodeAndChildNodes(selectedObject,this.selectedPathway,
          this.groups, this.treatments);
        containerObject.data.score.nextQuestionGroupId = clone.id;
        containerObject.data.score.treatmentId = 0;
      }
      else if(containerObject.data.isOptionNode())
      {
        let clone = this.relatedObjectUtilty.createNodeAndChildNodes(selectedObject,this.selectedPathway,
          this.groups,this.treatments);
        containerObject.data.option.nextQuestionGroupId = clone.id;
        containerObject.data.option.treatmentId = 0;
      }
    }
    else
    {
      let treatment = this.relatedObjectUtilty.getTreatmentFromRelatedObject(selectedObject,this.treatments);
      this.populateNextTreatmentIds(treatment,containerObject);
    }
  }

  populateNextTreatmentObject(treatmentTemplate: Treatment,element: Option | ScoreToNextObject)
  {
    let clone = this.treatmentUtil.copyTreatment(treatmentTemplate);
    this.treatmentUtil.setTreatmentForInsert(clone);
    this.selectedPathway.treatmentList.push(clone);
    element.nextQuestionGroupId = 0;
    element.treatmentId = clone.id;
    element.relatedObject = this.relatedObjectUtilty.convertTreatmentToRelatedObject(clone,false);
  }

  populateNextTreatmentIds(treatment: Treatment,containerObject: GraphNode)
  {
    if(containerObject.data.isGroupNode())
    {
      this.populateGroupNextTreatment(containerObject.data.group,treatment);
    }
    else if(containerObject.data.isOptionNode())
    {
      this.populateNextTreatmentObject(treatment,containerObject.data.option);
    }
    else if(containerObject.data.isScoreNode())
    {
        this.populateNextTreatmentObject(treatment,containerObject.data.score);
    }
  }

  updateSelectedObjectAndTree(selectedItem: QuestionGroup | Treatment)
  {
    if(this.selectedNode.data.isGroupNode())
    {
      //Make copies of any new template related objects
      this.relatedObjectUtilty.createObjectsForTemplateRelatedObjects(selectedItem as QuestionGroup,this.selectedPathway,
                this.groups,this.treatments);
      this.questionGroupUtil.questionGroupDeepCopy(selectedItem as QuestionGroup,
        this.selectedNode.data.group);
    }
    else
    {
      this.treatmentUtil.treatmentDeepCopy(selectedItem as Treatment,
        this.selectedNode.data.treatment);
    }
    this.updateTree();
  }

  private isIdInValid(id: number): boolean {
    const notValidValues = [0, null, undefined];
    return notValidValues.includes(id);
  }

  updateTree() {
    const isStartingQsGroupIdInvalid = this.isIdInValid(this.selectedPathway?.startingQuestionGroupId);
    if (isStartingQsGroupIdInvalid) { return; };
    this.graphService.createTreeForPathway(this.selectedPathway);
    this.nodes = this.selectedPathway.nodes;
    this.links = this.selectedPathway.edges;
    this.relatedObjectUtilty.populateRelatedObjectsOnPathwayGroups(this.selectedPathway);
  }

  updateAndFocus(){
    this.updateTree();
    this.zoomToFit$.next(true);
  }

  onSave()
  {
    this.updateTree();
  }

  onCancel()
  {
    //this.selectedGroup = null;
  }

  getStyles(node: Node): any {}
  changeColor() {}

  getNodeWidth(node: Node): string {
    return node.dimension.width.toString();
  }

  getNodeHeight(node: Node) {
    return '100%';
  }

  showOptions: boolean;
  toggleShowingOptions() {
    this.showOptions = !this.showOptions;
    this.layout.settings.multigraph = false;
  }

  onExpandCollapse(node: GraphNode) {
    let result = this.graphService.updateShowFlags(node);
    this.nodes = result.nodes;
    this.links = result.edges;
  }

  editItem(node: GraphNode)
  {
    // if(this.isPathwayReadOnly())
    // {
    //   this.toasterService.show(MessageType.VALIDATION,this.readOnlyErrorMessage)
    //   return;
    // }
    this.selectedNode = node;
    let selectedItem: QuestionGroup | Treatment = null;
    if(node.data.isTreatmentNode())
    {
      selectedItem = node.data.treatment;
      let copyItem = new Treatment();
      this.treatmentUtil.treatmentDeepCopy(selectedItem,copyItem);
      this.pathwayItemSelectedForEdit.emit(copyItem);
    }
    else
    {
      if(node.data.isOptionNode() || node.data.isScoreNode())
        this.selectedNode = node.data.parentNode;
      selectedItem = node.data.getQuestionGroup();
      let copyItem = new QuestionGroup();
      this.questionGroupUtil.questionGroupDeepCopy(selectedItem,copyItem);
      this.pathwayItemSelectedForEdit.emit(copyItem);
    }
  }

  selectedObjectClass(node)
  {
    if(node == this.selectedNode)
    {
      return "selectedObject";
    }
    return "";
  }

  importDecision()
  {
    const dialogRef = this.dialog.open(GuidelinesSelectorComponent, {
      width: '500px', height: '400px', role: "alertdialog",
      data: {decisions: this.decisions, institutions: this.institutions}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result == undefined || result == null)
        return;
      let importPathway = result as Pathway;
      this.populateImportTree(importPathway);
    });
  }

  populateImportTree(importPathway: Pathway)
  {
    let treatmentMap = new Map<number,number>();
    let questionGroupMap = new Map<number,number>();
    let relatedObjects = [];
    this.selectedPathway.questionGroupList = [];
    this.selectedPathway.treatmentList = [];
    importPathway.treatmentList.forEach(treatment=>{
      let copyTreatment = this.treatmentUtil.copyTreatment(treatment);
      this.treatmentUtil.setTreatmentForInsert(copyTreatment);
      this.selectedPathway.treatmentList.push(copyTreatment);
      treatmentMap.set(treatment.id * -1,copyTreatment.id);
      let related = this.relatedObjectUtilty.convertTreatmentToRelatedObject(copyTreatment,false);
      relatedObjects.push(related);
    });this.updateTreeWithSavedData
    importPathway.questionGroupList.forEach(group=>{
      let copyGroup = this.questionGroupUtil.copyQuestionGroup(group);
     this.questionGroupUtil.setQuestionGroupToInsert(copyGroup);
      this.selectedPathway.questionGroupList.push(copyGroup);
      if(importPathway.startingQuestionGroupId == group.id)
        this.selectedPathway.startingQuestionGroupId = copyGroup.id;
      questionGroupMap.set(group.id * -1,copyGroup.id);
      let related = this.relatedObjectUtilty.convertQuestionGroupToRelatedObject(copyGroup,false);
      relatedObjects.push(related);
    });
    //update Next ids
    this.selectedPathway.questionGroupList.forEach(group=>{
      if(group.isScoreBased)
      {
        group.scoreToNextObjectList.forEach(score=>{

          if(score.nextQuestionGroupId != 0)
          {
            score.nextQuestionGroupId = questionGroupMap.get(score.nextQuestionGroupId);
          }
          else if(score.treatmentId)
          {
            score.treatmentId = treatmentMap.get(score.treatmentId);
          }
        });
      }
      else
      {
        group.questions.forEach(question=>{
          question.options.forEach(option=>{
            if(option.nextQuestionGroupId != 0)
              option.nextQuestionGroupId = questionGroupMap.get(option.nextQuestionGroupId);
            else if(option.treatmentId)
              option.treatmentId = treatmentMap.get(option.treatmentId);
          });
        });
      }
    });
    this.relatedObjectUtilty.populateNextIdsOnGroups(this.selectedPathway.questionGroupList,relatedObjects);
    this.updateTree();
  }

  getImageSourcePath(node: GraphNode){
    if(!node.data.show)
      return "./assets/icons/add_18dp.png";
    return "./assets/icons/remove_18dp.png";
  }

  calcMinOptionNodeHeight(node: GraphNode){
    if(window.devicePixelRatio){
      return node.dimension.height * window.devicePixelRatio;
    }
  }

  calcMinOptionNodeWidth(node: GraphNode){
    if(window.devicePixelRatio){
      return node.dimension.width * window.devicePixelRatio;
    }
  }
}

