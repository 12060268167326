import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "./shared/shared.module";
import { AuthModule } from "./auth/auth.module";
import { CoreModule } from "./core/core.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { QuestiongroupComponent } from "./admin/admin-question/questiongroup/questiongroup.component";
import { NewquestionComponent } from "./admin/admin-question/newquestion/newquestion.component";
import { PanelComponent } from "./admin/panel/panel.component";
import { TreatmentComponent } from "./admin/treatment/treatment.component";
import { MAT_LABEL_GLOBAL_OPTIONS } from "@angular/material/core";
import { QuestionPanelComponent } from "./admin/admin-question/newquestion/question-panel/question-panel.component";
import { ScorePanelComponent } from "./admin/admin-question/newquestion/score-panel/score-panel.component";
import { PathwayTreePanelComponent } from "./admin/panel/pathway-tree-panel/pathway-tree-panel.component";
import { TreatmentTemplatePanelComponent } from "./admin/panel/treatment-template-panel/treatment-template-panel.component";
import { QuestionTemplatePanelComponent } from "./admin/panel/question-template-panel/question-template-panel.component";
import { PathwayTreeComponent } from "./admin/panel/pathway-tree-panel/pathway-tree/pathway-tree.component";
import { GuidelinesSelectorComponent } from "./admin/guidelines-selector/guidelines-selector.component";
import { ScoreTextValidator } from "./shared/validate/scoreText.validator";
import { TreatmentLangComponent } from "./admin/treatment-lang/treatment-lang.component";
import { GuidelinesLangComponent } from "./admin/guidelines-lang/guidelines-lang.component";
import {
  SharedLibraryModule,
  SpinnerModule,
  ToasterModule,
} from "projects/shared-lib/src/public-api";
import { DialogModule } from "./core/dialog/dialog.module";
import { GroupLangComponent } from "./admin/group-lang/group-lang.component";
import { GroupLangPanelComponent } from "./admin/group-lang/group-lang-panel/group-lang-panel.component";
import { PathwayLangComponent } from "./admin/pathway-lang/pathway-lang.component";
import { LanguageComponent } from "./admin/language/language.component";
import { LanguageEditComponent } from "./admin/language/language-edit/language-edit.component";
import { MatSelectFilterModule } from "mat-select-filter";

import { NotificationsComponent } from "./admin/notifications/notifications.component";
import { DownloadStudentsComponent } from './utilities/export-data/export-data.component';
import { ImportCatalogModalComponent } from './admin/import-catalog-modal/import-catalog-modal.component';
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { RegisterUserComponent } from './admin/register-user/register-user.component';

@NgModule({
  declarations: [
    AppComponent,
    QuestiongroupComponent,
    NewquestionComponent,
    PanelComponent,
    TreatmentComponent,
    QuestionPanelComponent,
    ScorePanelComponent,
    PathwayTreePanelComponent,
    TreatmentTemplatePanelComponent,
    QuestionTemplatePanelComponent,
    PathwayTreeComponent,
    GuidelinesSelectorComponent,
    ScoreTextValidator,
    TreatmentLangComponent,
    GuidelinesLangComponent,
    GroupLangComponent,
    GroupLangPanelComponent,
    PathwayLangComponent,
    LanguageComponent,
    LanguageEditComponent,
    NotificationsComponent,
    DownloadStudentsComponent,
    ImportCatalogModalComponent,
    RegisterUserComponent,
  ],
  entryComponents: [
    GuidelinesSelectorComponent,
    TreatmentLangComponent,
    GuidelinesLangComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AuthModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    SharedLibraryModule,
    DialogModule,
    SpinnerModule,
    ToasterModule,
    MatSelectFilterModule,
    NgxMaterialTimepickerModule
  ],
  providers: [
    { provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: { float: "always" } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
