import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { ServiceUtil } from "projects/AdminApp/src/app/shared/util/service-util";
import {
  InquiryResponse,
  LocalStorageUtil,
} from "projects/shared-lib/src/public-api";
import { LoginRequest } from "../model/login-request.model";
import { SessionInfo } from "../model/session-info.model";
import { Account } from "../../../../../shared-lib/src/public-api";
import { AccountService } from "./account.service";
import { RegisterUser } from "../model/register-user.model";

@Injectable({ providedIn: "root" })
export class AuthService {
  private accountUrl = `${this.serviceUtil.rootUrl}/administrator`;

  constructor(
    private accountService: AccountService,
    private localStorageUtil: LocalStorageUtil,
    private serviceUtil: ServiceUtil,
    private http: HttpClient
  ) {
    this.localStorageUtil = localStorageUtil;
    this.serviceUtil = serviceUtil;
    this.http = http;
    this.handleLoginResponse = this.handleLoginResponse.bind(this);
    this.autoLogout = this.autoLogout.bind(this);
  }

  private handleLoginResponse(response: InquiryResponse<SessionInfo>) {
    if (response.operationSuccess) {
      const username = response.first.username;
      const token = response.first.token;
      const expiresIn = response.first.expiresIn;
      const account = new Account(username, token, expiresIn);
      this.accountService.setAccount(account);
      this.localStorageUtil.saveAdmin(account);
      this.autoLogout(expiresIn);
    }
  }

  login(data: LoginRequest) {
    const url = `${this.accountUrl}/login`;
    return this.http
      .post<InquiryResponse<SessionInfo>>(
        url,
        data,
        this.serviceUtil.getCommonHeaders()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.handleLoginResponse)
      );
  }

  register(data: RegisterUser) {
    const url = `${this.accountUrl}/register`;
    return this.http
      .post<InquiryResponse<any>>(
        url,
        data,
        this.serviceUtil.getCommonAdminHeadersWithAuth()
      );
  }

  autoLogin() {
    const admin: Account = this.localStorageUtil.getAdmin();
    if (admin && admin.getToken() && admin.getExpiration()) {
      this.accountService.setAccount(admin);
      this.autoLogout(admin.getExpiration());
    }
  }

  autoLogout(duration: number) {
    this.accountService.initTokenExpTimer(duration);
  }

  logout() {
    this.accountService.clearAccount();
  }
}
