import { Component } from "@angular/core";
import { FileService } from "../../shared/service/file.service";

@Component({
    selector: 'user-import',
    templateUrl: './user-import.component.html',
    styleUrls: ['./user-import.component.css']
})
export class UserImportComponent {

    constructor(fileService: FileService) {

    }

    onImportModal() {
        
    }
}