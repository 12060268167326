import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { QuestionGroup, InquiryResponse, InquiryRequest, ToasterService } 
      from 'projects/shared-lib/src/public-api';
import { ServiceUtil } from 'projects/AdminApp/src/app/shared/util/service-util';

@Injectable({providedIn: 'root'})
  export class QuestionGroupService{
    
    constructor(private httpClient: HttpClient,private serviceUtility: ServiceUtil,
      private toasterService: ToasterService){}

    saveQuestionGroup(group: QuestionGroup){
      return this.httpClient.post<any>(this.serviceUtility.rootUrl + '/QuestionGroup/Save',
      group,this.serviceUtility.getCommonAdminHeadersWithAuth())
      .pipe(
        catchError(this.serviceUtility.handleError),
        tap(function(){})
      );
    }

    private handleResponse(response: InquiryResponse<QuestionGroup>):QuestionGroup[] {
        if (response.operationSuccess) {
          return response.returnValues;
        }
        return null;
      }

   retrieveQuestionGroups() 
   {
      const data = new InquiryRequest();
      return this.httpClient
    .post<InquiryResponse<QuestionGroup>>(this.serviceUtility.rootUrl + '/QuestionGroup/Fetch', data, this.serviceUtility.getCommonAdminHeadersWithAuth())
    .pipe(
      catchError(this.serviceUtility.handleError),
      tap(this.handleResponse)
    );
  }

  retrieveQuestionGroupById(id: number) 
   {
      return this.httpClient
    .post<InquiryResponse<QuestionGroup>>(this.serviceUtility.rootUrl + '/QuestionGroup/FetchById/?id=' + id, null, this.serviceUtility.getCommonAdminHeadersWithAuth())
    .pipe(
      catchError(this.serviceUtility.handleError),
      tap(this.handleResponse)
    );
  }
}
