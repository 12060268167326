<div class="row bg-secondary" *ngIf="decisions">
    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 p-1">
      <label>User Types</label>
      <ng-select name="categoryId" [multiple]="false" [(ngModel)]="categoryId" placeholder="Select an option"   (change)="onUserTypeChanged()">
        <ng-option *ngFor="let category of categories;" [value]="category.id" required>
          {{ category.description }}
        </ng-option>
      </ng-select>
    </div>
    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 p-1">
      <label>Guidelines</label>
      <ng-select
        name="decisionId"
        [multiple]="false"
        [(ngModel)]="selectedDecision"
        placeholder="Select an option"
        (change)="onDecisionChanged()"
      >
        <ng-option
          *ngFor="let decision of decisions | filterGuidelineByCategory: categoryId;"
          [value]="decision"
          required>
          {{ decision.name }}
        </ng-option>
      </ng-select>
    </div>
    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 p-1">
      <label>Institution</label>
      <ng-select
        name="instOwnerId"
        [multiple]="false"
        [(ngModel)]="selectedInstitution"
        placeholder="Select an option" (change)="onInstitutionChanged()"
      >
        <ng-option
          *ngFor="let inst of filteredInstitutions;"
          [value]="inst"
          required
        >
          {{ inst.description }}
        </ng-option>
      </ng-select>
    </div>
    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 p-1">
      <label>Pathway</label>
      <ng-select
        name="pathway"
        [multiple]="false"
        [(ngModel)]="selectedPathway"
        placeholder="Select a option" (change)="updateAndFocus()">
        <ng-option
          *ngFor="let pathway of pathwaysForInstitution"
          [value]="pathway"
          required
        >
          {{ pathway.name }} - {{pathwayService.getPathwayStatus(pathway)}}
        </ng-option>
      </ng-select>
    </div>
    <div class="btn-toolbar p-1 pt-4" role="group">
      <button id="saveTreeBtn" class="btn btn-white" (click)="savePathwayTree()" [disabled]="isPathwayReadOnly()">Save</button>
    </div>
</div>
<br/>
<div class="row action-button" *ngIf="!isPathwayReadOnly() && selectedPathway && selectedPathway.startingQuestionGroupId && canDeleteTree()">
  <div class="col-12 d-flex justify-content-start">
    <button class="btn btn-dark" id="deleteBtn" (click)="deletePathwayTree()">Delete tree</button>
  </div>
</div>
<div *ngIf="selectedPathway == null" class="treePanel"
cdkDropList
(cdkDropListDropped)="onDrop($event, null)">
</div>
<div class="mainPanel" *ngIf="selectedPathway != null">
    <div *ngIf="selectedPathway.startingQuestionGroupId == 0"
        class="treePanel"
        #itemsList="cdkDropList"
        cdkDropList
        [cdkDropListData]="canAssociateObjects"
        (cdkDropListDropped)="onDrop($event, null)">
        <div *ngIf="selectedPathway.startingQuestionGroupId == 0">
          <p><button id="importBTN" class="btn btn-primary" (click)="importDecision()">Import</button> an existing pathway tree or build one from scratch.</p>
        </div>
      </div>
    <div class="questionRootDiv" *ngIf="selectedPathway.startingQuestionGroupId">
        <ngx-graph [links]="links" [nodes]="nodes" [layout]="layout"  [autoCenter]="true" [autoZoom]="true"
                    [layoutSettings]="layoutSettings" [curve]="curve" [enableZoom]="true"
                    [draggingEnabled]="false" [zoomToFit$]="zoomToFit$" [minZoomLevel]="0.1" [maxZoomLevel]="2.5" >
        <ng-template #defsTemplate>
            <svg:marker id="arrow" viewBox="0 -5 10 10" refX="8" refY="0" markerWidth="4" markerHeight="4" orient="auto">
            <svg:path d="M0,-5L10,0L0,5" class="arrow-head" />
            </svg:marker>
        </ng-template>
        <ng-template #nodeTemplate let-node>
            <svg:g xmlns="http://www.w3.org/2000/xhtml">
            <svg:foreignObject [style.width.px]="getNodeWidth(node)" [style.height]="getNodeHeight(node)" z-index="1000">
                <xhtml:div class="cardContainer" [ngClass]="selectedObjectClass(node)" xmlns="http://www.w3.org/1999/xhtml" [ngStyle]=getStyles(node)
                [style.min-height.px]="calcMinOptionNodeHeight(node) - 40" *ngIf="node.data.isGroupNode()" cdkDropList
                [cdkDropListData]="canAssociateObjects"
                (cdkDropListDropped)="onDrop($event, node)" (click)="editItem(node)">
                    <app-questiongroup [node]=node></app-questiongroup>
                </xhtml:div>
                <xhtml:div class="bg-secondary color-white" xmlns="http://www.w3.org/1999/xhtml" [ngStyle]=getStyles(node)
                [style.min-height.px]="calcMinOptionNodeHeight(node)" *ngIf="node.data.isOptionNode()" cdkDropList
                [cdkDropListData]="canAssociateObjects"
                (cdkDropListDropped)="onDrop($event, node)" (click)="editItem(node)">
                    <p class="name pt-2 pl-2">{{node.data.option.text}}</p>
                </xhtml:div>
                <xhtml:div class="cardScoreContainer" xmlns="http://www.w3.org/1999/xhtml" [ngStyle]=getStyles(node)
                [style.min-height.px]="calcMinOptionNodeHeight(node)" *ngIf="node.data.isScoreNode()" cdkDropList
                [cdkDropListData]="canAssociateObjects"
                (cdkDropListDropped)="onDrop($event, node)" (click)="editItem(node)">
                <label class="pt-2 pl-2">{{node.data.score.score}}</label>
                </xhtml:div>
                <xhtml:div class="cardTreatmentContainer" [ngClass]="selectedObjectClass(node)" xmlns="http://www.w3.org/1999/xhtml"
                [ngStyle]=getStyles(node) *ngIf="node.data.isTreatmentNode()" (click)="editItem(node)">
                <angular-editor id={{node.data.treatment.id}} [(ngModel)]="node.data.treatment.text"
                [config]="config" ></angular-editor>
                </xhtml:div>
                <xhtml:div xmlns="http://www.w3.org/1999/xhtml" class="expandButtonDiv" *ngIf="node.data.isGroupNode()">
                  <xhtml:div class="expandButton" *ngIf="node.data.show">
                    <xhtml:img src="./assets/icons/remove_18dp.png"  (click)="onExpandCollapse(node)"/>
                  </xhtml:div>
                  <xhtml:div class="expandButton" *ngIf="!node.data.show">
                    <xhtml:img src="./assets/icons/add_18dp.png"  (click)="onExpandCollapse(node)"/>
                  </xhtml:div>
                </xhtml:div>
            </svg:foreignObject>
            </svg:g>
        </ng-template>
        <ng-template #linkTemplate let-link>
            <svg:g class="edge">
            <svg:path class="line" stroke-width="2" marker-end="url(#arrow)">
            </svg:path>
            </svg:g>
        </ng-template>

        </ngx-graph>
    </div>

</div>
