<div>
  <div class="lang-toolbar">
    <div class="all-lang">
      <select
        id="lang_toolbar_dropdown"
        name="lang_toolbar_dropdown"
        [(ngModel)]="selectedLanguageId"
        placeholder="Add/Edit a language"
        (change)="changeSelectedLang()"
        [disabled]="isLoading"
        class="custom-select"
      >
        >
        <option [value]="-1" required>
          Add/Edit Language
        </option>
        <option
          *ngFor="let lang of languages | filterByLangCode: 'en'"
          [value]="lang.id"
          required
        >
          {{ lang.languageDesc }}
        </option>
      </select>
    </div>
    <div
      class="supported-lang color-white"
      *ngIf="supportedLanguages.length > 0"
    >
      <span
        class="badge badge-secondary lang-item"
        *ngFor="let langSpan of supportedLanguages"
        ><ng-container *ngIf="langSpan?.languageCode">{{
          langSpan.languageCode | uppercase
        }}</ng-container></span
      >
      <span
        *ngIf="supportedLanguages.length > 0"
        class="color-white"
        aria-hidden="true"
      >
        <img src="../../../../assets/translate-white-24dp.svg" />
      </span>
    </div>
  </div>
</div>
