import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { InquiryRequest, MessageType, SpinnerService, ToasterService, Category } from 'projects/shared-lib/src/public-api';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CategoryService } from '../../shared/service/category.service';
import { ExportDataService } from '../../shared/service/export-data.service';

@Component({
  selector: 'app-export-data',
  templateUrl: './export-data.component.html',
  styleUrls: ['./export-data.component.css']
})
export class DownloadStudentsComponent implements OnInit, OnDestroy {
  public startDate: Date = null;
  public endDate: Date = null;
  public fileName: string = "download";
  public formatSelected: string = "json";
  public entityType: string = "usage";
  public daysOld: number = 0;
  public userTypes: Category[] = []; 
  public userTypeId: number = 0;

  private subscriptions: Subscription[] = [];

  constructor(
    private exportDataService: ExportDataService,
    private toasterService: ToasterService,
    private spinnerService: SpinnerService,
    private categoryService: CategoryService
  ) { }

  ngOnInit(): void {
    this.fetchUserTypes();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  customTB(index, userType) { return `${index}-${userType.id}`; }


  private onError(error: string) {
    this.toasterService.show(MessageType.SYSTEM_ERROR, error);
  }

  fetchUserTypes() {
    this.spinnerService.show();
    this.subscriptions.push(
      this.categoryService.getCategories(new InquiryRequest())
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(res => {
        const errorMsg = "Could not load User Type data.";
        if (res.operationSuccess) {
            this.userTypes = res.returnValues;
        } else {
            this.toasterService.show(MessageType.SYSTEM_ERROR, errorMsg);
        }
      }, this.onError.bind(this))
    );
  }

  getDate(cur: Date): string
  {
    if(cur === null)
      return null;
    return [this.pad(cur.getMonth()+1), this.pad(cur.getDate()), cur.getFullYear()].join('/')
    }

  pad(n: number){
    return n < 10 ? "0"+n : n;
  }

  download(form: NgForm)
  {
    if(!form.valid)
    {
      this.toasterService.show(MessageType.SYSTEM_ERROR,"Invalid date.");
      return;
    }
    if(this.entityType == 'survey' && this.userTypeId == 0){
      this.toasterService.show(MessageType.SYSTEM_ERROR,"Select a category to export.");
      return;
    }
    this.spinnerService.show();
    let fromDate: string = this.getDate(this.startDate);
    let toDate: string = this.getDate(this.endDate);
    let call = (this.entityType =='survey' && this.userTypeId != 4) ? this.exportDataService.downloadSurveyData(this.userTypeId, fromDate,toDate,this.formatSelected,this.daysOld)
    : this.exportDataService.downloadData(fromDate,toDate,this.formatSelected,this.entityType,this.daysOld)
    
    call.pipe(finalize(() => this.spinnerService.hide()))
    .subscribe(res => {
      if(res.returnValues.length > 0){
        this.SaveFile(res);
        this.toasterService.show(MessageType.INFORMATIONAL,"Download complete");
      }
      if(!res.operationSuccess){
        this.toasterService.show(MessageType.SYSTEM_ERROR, (res.messages.length) ? res.messages[0].text : "Error during download process.")
      }
    },
    err => this.toasterService.show(MessageType.SYSTEM_ERROR, err));
  }

  SaveFile(res)
  {
    var sJson = res.returnValues[0];
    var element = document.createElement('a');
    element.setAttribute('href', "data:text/json;charset=UTF-8," + encodeURIComponent(sJson));
    element.setAttribute('download', `${this.fileName}.` + this.formatSelected);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
  }
}
