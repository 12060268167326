import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'confimartion-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['confirmation-modal.component.css']
})
export class ConfirmationModalComponent {
    constructor(public dialogRef: MatDialogRef<ConfirmationModalComponent>,
        @Inject(MAT_DIALOG_DATA) public message: string){
            if(this.message == null) this.message = "Are you sure you want to perform this action?";
        }

    public onConfirm(){
        this.dialogRef.close(true);
    }

    public onCancel(){
        this.dialogRef.close(false);
    }
}