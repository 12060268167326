import { RelatedObject, QuestionGroup } from 'projects/shared-lib/src/public-api';
import { RelatedObjectUtility } from '../../../shared/util/related-object-utility';

export class TagHelper
{
    public optionMap: Map<number,RelatedObject[]> = new Map<number,RelatedObject[]>();
    public cloneOptionMap: Map<number,RelatedObject[]> = new Map<number,RelatedObject[]>();
    constructor(private relatedObjectUtility: RelatedObjectUtility)
    {

    }

    loadMaps(questionGroup: QuestionGroup,relatedObjects: RelatedObject[])
    {
        if(!questionGroup.isScoreBased)
        {
        questionGroup.questions.forEach(q => {
            q.options.forEach(o=>{
              let result = this.relatedObjectUtility.getRelatedObjects(o,relatedObjects);
              this.optionMap.set(o.id,result);
              this.cloneOptionMap.set(o.id,result.slice());
            });
          });
        }
        else
        {
            questionGroup.scoreToNextObjectList.forEach(s => {
                let result = this.relatedObjectUtility.getRelatedObjects(s,relatedObjects);
                this.optionMap.set(s.id,result);
                this.cloneOptionMap.set(s.id,result.slice());
            });
        }
    }

    onOpenClose(event:any,element:any)
    {
        if(event == false)
        {
            this.cloneOptionMap.set(+element,this.optionMap.get(+element).slice());
        }
    }
}