<fieldset >
    <div class="col-12">
        <div class="row">
            <div class="col-7">
                <label>Logo *</label>
            </div>
        </div>
        <div class="row">
        <div class="col-9">
            <input
                id="logo_input"
                class="form-control"
                name="logo"
                [readonly]="isNotAutomatedTestEnvironment()"
                placeholder="Logo"
                [(ngModel)]="instance.logo"
                [required]="isNotAutomatedTestEnvironment()"/>
        </div>
        <div class="col-2">
            <label class="fileContainer">
                Upload File
                <input type="file" #file accept=".png,.jpg" (change)="onFileChanged(file.files)"/>
            </label>
        </div>
        </div>
        <br/>
        <div class="row">
            <div class="col-12">
        <div *ngIf="showImage()">
            <img src="{{instance.logo}}" class="logoPanel"/>
        </div>
        </div>
        </div>
    </div>
</fieldset>
<br/>
