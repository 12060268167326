<div cdkDropListGroup>
  <div class="d-flex flex-row">
    <mat-sidenav-container
      class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <mat-sidenav
        position="start"
        [(opened)]="leftPanelVisible"
        mode="side"
        class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
        <mat-tab-group>
          <mat-tab label="Questions">
            <app-question-template-panel
              [groups]="groups"
              [canAssociateObjects]="relatedObjects"
              [filteredGroups]="filteredGroups"
              (afterQuestionGroupSearch)="populateAssociateObjectsArray()"
            >
            </app-question-template-panel>
          </mat-tab>
          <mat-tab label="Treatments">
            <app-treatment-template-panel
              [treatments]="treatments"
              [canAssociateObjects]="relatedObjects"
              [filteredTreatments]="filteredTreatments"
              (afterTreatmentSearch)="populateAssociateObjectsArray()"
            >
            </app-treatment-template-panel>
          </mat-tab>
        </mat-tab-group>
      </mat-sidenav>
      <mat-sidenav
        position="end"
        [(opened)]="rightPanelVisible"
        mode="side"
        class="col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5"
      >
        <div *ngIf="isSelectedObjectQuestionGroup()">
          <app-newquestion
            [isUsedByTree]="true"
            [canAssociateObjects]="relatedObjects"
            [questionGroup]="selectedObject"
            [surveyVariables]="getSurveyVariables()"
            [pathwaySelected]="pathwayTreeComponent.selectedPathway"
            (afterSaveComplete)="onPathwayItemEditComplete()"
            (afterCancelClicked)="onPathwayItemEditCancelled()"
          ></app-newquestion>
        </div>
        <div *ngIf="isSelectedObjectTreatment()">
          <app-treatment
            [isUsedByTree]="true"
            [treatment]="selectedObject"
            [pathwaySelected]="pathwayTreeComponent.selectedPathway"
            (afterSaveComplete)="onPathwayItemEditComplete()"
            (afterCancelClicked)="onPathwayItemEditCancelled()"
          ></app-treatment>
        </div>
      </mat-sidenav>
      <mat-sidenav-content
        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
      >
        <app-pathway-tree
          [groups]="groups"
          [treatments]="treatments"
          [canAssociateObjects]="relatedObjects"
          (pathwayItemSelectedForEdit)="updateSelectedObject($event)"
          (pathwayTreeSaved)="afterPathwayTreeSaved($event)"
        >
        </app-pathway-tree>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>