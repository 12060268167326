import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";

import { ServiceUtil } from "../../shared/util/service-util";

import {
  InquiryRequest,
  InquiryResponse,
  Category,
} from "projects/shared-lib/src/public-api";
import { CategoryRequest } from "../model/category-request.model";

@Injectable({ providedIn: "root" })
export class CategoryService {
  private categoryUrl = `${this.serviceUtil.rootUrl}/category`;

  constructor(private serviceUtil: ServiceUtil, private http: HttpClient) {
    this.serviceUtil = serviceUtil;
    this.http = http;
    this.handleGetCategoriesResponse = this.handleGetCategoriesResponse.bind(
      this
    );
    this.handleGetCategoriesWithoutQuestionsResponse = this.handleGetCategoriesWithoutQuestionsResponse.bind(this);
  }

  private handleGetCategoriesResponse(response: InquiryResponse<Category>) {}
  private handleGetCategoriesWithoutQuestionsResponse(response: InquiryResponse<Category>) {}

  getCategories(data: InquiryRequest) {
    const url = `${this.categoryUrl}/fetch`;
    return this.http
      .post<InquiryResponse<Category>>(
        url,
        data,
        this.serviceUtil.getCommonAdminHeadersWithAuth()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.handleGetCategoriesResponse)
      );
  }

  getCategoriesWithoutQuestions(data: InquiryRequest) {
    const url = `${this.categoryUrl}/fetchWithoutQuestions`;
    return this.http
      .post<InquiryResponse<Category>>(
        url,
        data,
        this.serviceUtil.getCommonAdminHeadersWithAuth()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.handleGetCategoriesWithoutQuestionsResponse)
      );
  }

  saveCategory(data: CategoryRequest) {
    const url = `${this.categoryUrl}/save`;
    return this.http
      .post<InquiryResponse<Category>>(
        url,
        data,
        this.serviceUtil.getCommonAdminHeadersWithAuth()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.handleGetCategoriesResponse)
      );
  }

  getVariablesForUserType(id: number) {
    const url = `${this.categoryUrl}/fetchVariablesAssociatedToQuestionsByUserTypeId`;
    return this.http
      .get<InquiryResponse<string>>(
        url + "?id=" + id.toString(),
        this.serviceUtil.getCommonAdminHeadersWithAuth()
      );
  }
}
