import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../core/dialog/dialog.component';
import { Dialog } from '../../core/dialog/dialog.model';

@Injectable({ providedIn: "root" })
export class NotificationsDeleteUtility
{
  constructor(
    public dialog: MatDialog
    ) {}
  public canDeleteNotification()
  {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: new Dialog(
      "Do you want to delete the notification?",
      "- Click No to cancel<br/> - Click Yes to delete the notification <br/>",
      "No",
      "Yes"
      ),
    });
    return dialogRef;
  }
}