import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { ServiceUtil } from "projects/AdminApp/src/app/shared/util/service-util";
import { InquiryRequest, InquiryResponse, Decision } from "projects/shared-lib/src/public-api";

@Injectable({ providedIn: "root" })
export class DecisionService {
  private decisionUrl = `${this.serviceUtil.rootUrl}/decision`;

  constructor(private serviceUtil: ServiceUtil, private http: HttpClient) {
    this.serviceUtil = serviceUtil;
    this.http = http;
    this.handleGetDecisionsResponse = this.handleGetDecisionsResponse.bind(
      this
    );
    this.handleSaveDecisionResponse = this.handleSaveDecisionResponse.bind(
      this
    );
  }

  private handleGetDecisionsResponse(response: InquiryResponse<Decision>) {}

  getDecisions(data: InquiryRequest, includeGroupsTreatments: boolean) {
    let url = `${this.decisionUrl}/fetch`;
    if(!includeGroupsTreatments)
      url = `${this.decisionUrl}/FetchWithoutGroupsAndTreatments`;
    return this.http
      .post<InquiryResponse<Decision>>(
        url,
        data,
        this.serviceUtil.getCommonAdminHeadersWithAuth()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.handleGetDecisionsResponse)
      );
  }

  private handleSaveDecisionResponse(response: InquiryResponse<any>) {}

  saveDecision(data: Decision) {
    const url = `${this.decisionUrl}/save`;
    return this.http
      .post<InquiryResponse<any>>(
        url,
        data,
        this.serviceUtil.getCommonAdminHeadersWithAuth()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.handleSaveDecisionResponse)
      );
  }
}
