<form class="bg-white" (ngSubmit)="download(downLoadForm)"
#downLoadForm="ngForm"
name="form">
<div class="row bg-white col-7"> 
  <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 p-1">  
      <mat-form-field appearance="fill">
          <mat-label>Choose from date</mat-label>
          <input matInput [matDatepicker]="picker" [(ngModel)]="startDate" name="startDate" id="startDate">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>      
  </div>
    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 p-1">
        <mat-form-field appearance="fill">
            <mat-label>Choose To date</mat-label>
            <input matInput [matDatepicker]="toDatepicker" [(ngModel)]="endDate" name="endDate" id="endDate">
            <mat-datepicker-toggle matSuffix [for]="toDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #toDatepicker></mat-datepicker>
          </mat-form-field>      
    </div>
  </div>
  <div class="row bg-white col-7"> 
    <label p-1>Or</label>
  </div>
  <div class="row bg-white col-7"> 
    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 p-1">  
        <mat-form-field appearance="fill">
            <mat-label>Choose last number of days</mat-label>
            <input matInput [(ngModel)]="daysOld" name="DaysBack" id="DaysBack">
          </mat-form-field>      
    </div>
  </div>
  <div class="row col-12 justify-content-start">   
    <div class="mr-2">
      <mat-form-field>
      <mat-label>Select an format option</mat-label>
            <mat-select [(value)]="formatSelected" id="Format" name="Format">
              <mat-option value="json">json</mat-option>
              <mat-option value="csv">csv</mat-option>
            </mat-select>
          </mat-form-field>      
    </div>
    <div class="mr-2">
      <mat-form-field >
      <mat-label>Select an entity type</mat-label>
            <mat-select [(value)]="entityType" id="entityType" name="entityType">
              <mat-option value="survey">Survey</mat-option>
              <mat-option value="usage">usage</mat-option>
            </mat-select>
          </mat-form-field>      
    </div>
    <div *ngIf="entityType =='survey'" class="mr-2">
      <mat-form-field>
        <mat-label>Select an User Type</mat-label>
          <mat-select [(value)]="userTypeId" id="userType" name="userType">
            <mat-option value="0">Not selected</mat-option>
            <mat-option *ngFor="let type of userTypes; let i = index; trackBy: customTB" [value]="type.id">{{type.description}}</mat-option>
          </mat-select>
      </mat-form-field>      
    </div>
  </div>
  <div class="row bg-white col-9">
    <div class="btn-toolbar p-1 col-1" role="group">
      <button class="btn btn-secondary">Download</button>
    </div>
  </div> 
</form>
