<div class="container">
  <div class="row login-form">
    <div
      class="col-xs-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 col-xl-4 offset-xl-4 "
    >
      <div class="text-center text-primary">
        <h5>Log in with email</h5>
      </div>
      <div class="alert alert-danger text-center" role="alert" *ngIf="error">
        {{ error }}
      </div>
      <div
        class="alert alert-warning text-center"
        role="alert"
        *ngFor="let warning of warnings"
      >
        {{ warning.text }}
      </div>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <input
            id="username"
            type="text"
            formControlName="username"
            placeholder="Email"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
          />
          <div *ngIf="submitted && f.username.invalid" class="invalid-feedback">
            <div *ngIf="f.username.errors.required">
              Email is required
            </div>
            <div *ngIf="f.username.errors.email">
              It must be a valid email address
            </div>
            <div *ngIf="f.username.errors.minlength">
              Email must be at least 4 characters
            </div>
          </div>
        </div>
        <div class="form-group">
          <input
            id="password"
            type="password"
            formControlName="password"
            class="form-control"
            placeholder="Password"
            [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
          />
          <div *ngIf="submitted && f.password.invalid" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">
              Password is required
            </div>
            <div *ngIf="f.password.errors.minlength">
              Password must be at least 6 characters
            </div>
          </div>
        </div>
        <div class="form-group">
          <button
            [disabled]="isLoading"
            class="btn btn-primary btn-lg btn-block"
            id = "click_btn"
          >
            <span
              *ngIf="isLoading"
              class="spinner-border spinner-border-sm mr-1"
            ></span>
            Login
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
