
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { LocalStorageUtil } from "projects/shared-lib/src/public-api";
import { Observable } from "rxjs"
import { environment } from "../../../environments/environment";

@Injectable()
export class ImageInterceptor implements HttpInterceptor {
  constructor(private localStorageUtil: LocalStorageUtil) {}
  imageUrls = [
    `${environment.baseUrl}/Image/Upload`,
    `${environment.baseUrl}/Image/SearchImageByName`
  ];
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const admin = this.localStorageUtil.getAdmin();
    const token = admin ? admin.getToken() : "";
    if (this.imageUrls.includes(req.url)) {
      const requestCopy = req.clone({
        setHeaders: {
          "Access-Control-Allow-Origin": "*",
          "Accept-Language": "en-US",
          "X-Auth-Token": token
        }
      });
      return next.handle(requestCopy);
    }
    return next.handle(req);
  }
}
