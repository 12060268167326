import { BehaviorSubject, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import {
  Language,
  InquiryResponse,
  ToasterService,
  MessageType,
  InquiryRequest,
  SpinnerService,
  ServiceConstant,
} from "projects/shared-lib/src/public-api";
import { ServiceUtil } from "../util/service-util";
import { HttpClient } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class LanguageService {
  private languageUrl = `${this.serviceUtil.rootUrl}/language`;
  public languages: Language[] = [];
  public languages$ = new BehaviorSubject<Language[]>(this.languages);

  constructor(
    private serviceUtil: ServiceUtil,
    private http: HttpClient,
    private toasterService: ToasterService,
    private spinnerService: SpinnerService
  ) {
    this.serviceUtil = serviceUtil;
    this.http = http;
   this.onError = this.onError.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onSavedSuccessful = this.onSavedSuccessful.bind(this);
  }

  private onSuccess(response: InquiryResponse<any>) {
    this.spinnerService.hide();
    if (response.operationSuccess) {
      this.languages = [...response.returnValues];
      this.languages$.next(this.languages);
    }
  }

  private onError(error) {
    this.spinnerService.hide();
    this.toasterService.show(MessageType.SYSTEM_ERROR, error);
  }

  private getLanguagesRequest(): Observable<InquiryResponse<Language>> {
    const request = new InquiryRequest();
    const url = `${this.languageUrl}/fetch`;
    return this.http
      .post<InquiryResponse<Language>>(
        url,
        request,
        this.serviceUtil.getCommonHeaders()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.serviceUtil.handleResponse)
      );
  }

  public getLanguages(): Language[] {
    return this.languages;
  }

  public initLanguages() {
    if (this.languages.length === 0) {
      this.spinnerService.show();
      this.getLanguagesRequest().subscribe(this.onSuccess, this.onError);
    }
  }

  saveLanguageItem(data: Language)
  {
    this.spinnerService.show();
    const url = `${this.languageUrl}/save`;
    return this.http
      .post<InquiryResponse<any>>(
        url,
        data,
        this.serviceUtil.getCommonAdminHeadersWithAuth()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.serviceUtil.handleResponse)
      ).subscribe(this.onSavedSuccessful,this.onError);
  }

  onSavedSuccessful(response: InquiryResponse<any>)
  {
    this.spinnerService.hide();
    const msg = "Saved successfully";
      if (response.operationSuccess) {
        this.toasterService.show(MessageType.INFORMATIONAL, msg);
        this.languages.length = 0;
        this.initLanguages();
      } else if (response.messages.length > 0) {
        this.toasterService.show(
          response.messages[0].type,
          response.messages[0].text
        );
      } else {
        this.toasterService.show(MessageType.SYSTEM_ERROR, ServiceConstant.somethingWentWrongMessage);
      }
  }
}
