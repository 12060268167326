<div class="title">
  <label>Notifications</label>
</div>
<form [formGroup]="notificationForm">
  <div class="notifications d-flex flex-row">
    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 scroll-panel">
      <div class="real-time-section">
        <div class="btn-toolbar justify-content-between" role="toolbar">
          <div class="btn-group color-dark" role="group">
            <label class="section-title">Real time notification</label>
          </div>
          <div class="btn-toolbar" role="group">
            <button
              id="add_new_notification_btn"
              class="btn btn-dark btn-sm"
              type="button"
              (click)="toRealTime()"
            >
              <img src="../../../assets/add-24px.svg" />
            </button>
          </div>
        </div>
      </div>
      <div class="scroll-panel">
        <br />
        <div class="btn-toolbar justify-content-between" role="toolbar">
          <div class="btn-group color-dark" role="group">
            <label class="section-title">Schedule notification</label>
          </div>
          <div class="btn-toolbar" role="group">
            <button
              id="add_new_schedule_notification_btn"
              class="btn btn-dark btn-sm"
              type="button"
              (click)="toNewScheduled()"
            >
              <img src="../../../assets/add-24px.svg" />
            </button>
          </div>
        </div>
        <br/>
        <div class="text-left text-secondary">
          <ng-container
            *ngIf="scheduledNot.length === 0;
              then emptyList;
              else notificationList"
          ></ng-container>
          <ng-template #emptyList>
            <div class="text-center text-secondary">
              <label id="detail_no_notifications">No items found</label>
            </div>
          </ng-template>
          <ng-template #notificationList>
            <div
              *ngFor="
                let l of scheduledNot;
                let di = index
              "
            >
              <div class="notification-box bg-dark">
                <div>
                  <div class="notification-content color-white">
                    <div class="btn-toolbar justify-content-between" role="toolbar">
                      <div class="btn-group color-white" role="group"></div>
                      <div class="btn-toolbar" role="group">
                        <button
                          class="btn btn-light btn-sm"
                          type="button"
                          (click)="editNotification(l)"
                        >
                          <img id="editBtn_{{ di }}" src="../../../assets/edit-24px.svg" />
                        </button>
                        <button
                          class="btn btn-light btn-sm ml-2"
                          type="button"
                          (click)="deleteNotification(l)"
                        >
                          <img id="deleteBtn_{{ di }}" class="delete-icon" src="../../../assets/delete.svg" />
                        </button>
                      </div>
                    </div>
                    <label
                      class="label font-weight-bold text-uppercase"
                      >{{ l.message }}</label
                    >
                  </div>
                  <br />
                </div>
              </div>
            </div>
            <br />
          </ng-template>
        </div>
      </div>
    </div>
    <div class="notification-content text-secondary col-8">
      <mat-tab-group [selectedIndex]="currentTabIndex" (selectedTabChange)="onTabChanged($event)">
        <mat-tab  label="English">
          
          <label class="col-3 pt-3 pb-1 font-weight-bold">Message</label>
          <textarea class="col-10" id="messageField" formControlName="message" rows="4" maxlength="300"></textarea>
          <div *ngIf="isSubmitted && f.message.errors" class="invalid-feedback">
            <div *ngIf="f.message.errors.required">This field is required</div>
            <div *ngIf="f.message.errors.maxlength">
              This field can be max 300 characters long
            </div>
            
          </div><br />
          <div class="col-6">
            <label class="pt-3 pb-1 font-weight-bold">Send to category:</label>
            <ng-select
              id="categories_notif_dropdown"
              name="categoryNotifIds"
              [multiple]="false"
              placeholder="Select a category"
              formControlName="categoryId"
            >
              <ng-option *ngFor="let categ of categories" [value]="categ.id" required>
                {{ categ.description }}
              </ng-option>
            </ng-select>
            <div *ngIf="isSubmitted && f.categoryId.errors" class="invalid-feedback">
              <div *ngIf="f.categoryId.errors.required">This field is required</div>
            </div>
            <hr />
          </div>
          
          <div class="col-6">
            <label class="pt-3 pb-1 font-weight-bold">Send to institution:</label>
            <ng-select
              id="institutions_notif_dropdown"
              name="institutionsNotifIds"
              [multiple]="false"
              placeholder="Select an institution"
              formControlName="institutionId"
            >
              <ng-option
                *ngFor="let inst of institutions"
                [value]="inst.id"
                required
              >
                {{ inst.description }}
              </ng-option>
            </ng-select>
            <div
              *ngIf="isSubmitted && f.institutionId.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.institutionId.errors.required">
                This field is required
              </div>
            </div>
            <br />
          </div>
          <ng-container *ngTemplateOutlet="dateSelectorTemplate"></ng-container>
          <div *ngIf="isScheduled  && 0 == currentTabIndex"  class="pb-3">
            <label class="col-3 pt-3 pb-1 font-weight-bold">Time</label>
            <ngx-timepicker-field [controlOnly]="true" [format]="24" #timepicker></ngx-timepicker-field>
          </div>
          <div btns-section>
            <button *ngIf="isScheduled"
              class="btn btn-dark section-item"
              id="saveScheduleBTN"
              [disabled]="isLoading"
              (click)="onSave(1)"
            >
              <span
                *ngIf="isLoading"
                class="spinner-border spinner-border-sm mr-1"
              ></span>
              Save
            </button>
            <button *ngIf="!isScheduled"
              class="btn btn-dark section-item"
              [disabled]="isLoading"
              (click)="onSubmit(1)"
            >
              <span
                *ngIf="isLoading"
                class="spinner-border spinner-border-sm mr-1"
              ></span>
              Send
            </button>
          </div>
        </mat-tab>
        <mat-tab 
        *ngFor="let l of languageService.languages; let i = index" label="{{l.languageDesc}}">
        
          <label class="col-3 pt-3 pb-1 font-weight-bold">Message</label>
          <textarea class="col-10" [formControl]="messageLangs.controls[i]" rows="4" maxlength="300"></textarea>
          <div *ngIf="isSubmitted && f.message.errors" class="invalid-feedback">
            <div *ngIf="f.message.errors.required">This field is required</div>
            <div *ngIf="f.message.errors.maxlength">
              This field can be max 300 characters long
            </div>
          </div><br />
          
          <div class="col-6">
            <label class="pt-3 pb-1 font-weight-bold">Send to category:</label>
            <ng-select
              id="categories_notif_dropdown"
              name="categoryNotifIds"
              [multiple]="false"
              placeholder="Select a category"
              formControlName="categoryId"
            >
              <ng-option *ngFor="let categ of categories" [value]="categ.id" required>
                {{ categ.description }}
              </ng-option>
            </ng-select>
            <div *ngIf="isSubmitted && f.categoryId.errors" class="invalid-feedback">
              <div *ngIf="f.categoryId.errors.required">This field is required</div>
            </div>
            <hr />
          </div>
          
          <div class="col-6">
            <label class="pt-3 pb-1 font-weight-bold">Send to institution:</label>
            <ng-select
              id="institutions_notif_dropdown"
              name="institutionsNotifIds"
              [multiple]="false"
              placeholder="Select an institution"
              formControlName="institutionId"
            >
              <ng-option
                *ngFor="let inst of institutions"
                [value]="inst.id"
                required
              >
                {{ inst.description }}
              </ng-option>
            </ng-select>
            <div
              *ngIf="isSubmitted && f.institutionId.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.institutionId.errors.required">
                This field is required
              </div>
            </div>
            <br />
          </div>
          <ng-container *ngTemplateOutlet="dateSelectorTemplate"></ng-container>
          
          <div *ngIf="isScheduled && (i+1) == currentTabIndex"  class="pb-3">
            <label class="col-3 pt-3 pb-1 font-weight-bold">Time</label>
            <ngx-timepicker-field [controlOnly]="true" [format]="24" #timepicker></ngx-timepicker-field>
          </div>
          <div btns-section>
            <button *ngIf="isScheduled"
              class="btn btn-dark section-item"
              
              [disabled]="isLoading"
              (click)="onSave(l.id)"
            >
              <span
                *ngIf="isLoading"
                class="spinner-border spinner-border-sm mr-1"
              ></span>
              Save
            </button>
            <button *ngIf="!isScheduled"
              class="btn btn-dark section-item"
              [disabled]="isLoading"
              (click)="onSubmit(l.id)"
            >
              <span
                *ngIf="isLoading"
                class="spinner-border spinner-border-sm mr-1"
              ></span>
              Send
            </button>
            
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</form>

<ng-template #dateSelectorTemplate >
          <!-- Schedule section -->
          <div *ngIf="isScheduled">
            <label class="col-3 pt-3 pb-1 font-weight-bold">Schedule type</label>
            <p class="ml-3">
              <input id="Weekly" type="radio" value="1" [formControl]="scheduledTypeControl"> Weekly
              <input id="Single" type="radio" value="2" [formControl]="scheduledTypeControl"> Single Date
              <input id="Registration" type="radio" value="3" [formControl]="scheduledTypeControl"> Registration Date Based
            </p>
            <!-- Single date pick -->
            <div *ngIf="scheduledTypeControl && scheduledTypeControl.value == 2">
              <mat-form-field appearance="fill">
                <mat-label>Pick notification date</mat-label>
                <input matInput [matDatepicker]="picker2"
                      [formControl]="dateControl">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
            </div>
  
            <!-- Days checkbox -->
            <div *ngIf="scheduledTypeControl && scheduledTypeControl.value == 1">
              <section class="example-section" [formGroup]="daysForm">
                <label class="col-3 pt-3 pb-1 font-weight-bold">Pick notification days</label>
                <p><mat-checkbox class="mr-3" [formControl]="monControl">Monday</mat-checkbox>
                <mat-checkbox class="mr-3" [formControl]="tueControl">Tuesday</mat-checkbox>
                <mat-checkbox class="mr-3" [formControl]="wedControl">Wednesday</mat-checkbox>
                <mat-checkbox [formControl]="thuControl">Thursday</mat-checkbox></p>
                <p><mat-checkbox class="mr-3" [formControl]="friControl">Friday</mat-checkbox>
                <mat-checkbox class="mr-3" [formControl]="satControl">Saturday</mat-checkbox>
                <mat-checkbox [formControl]="sunControl">Sunday</mat-checkbox></p>
              </section>
            </div>
            
            <!-- Registration day based -->
            <div *ngIf="scheduledTypeControl && scheduledTypeControl.value == 3">
              <mat-form-field appearance="fill">
                <mat-label>Days from registration date</mat-label>
                <input matInput type="number" placeholder="Ex. 7" [formControl]="daysControl" min="1">
                <span matSuffix>days</span>
              </mat-form-field>
            </div>
          </div>
</ng-template>